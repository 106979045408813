// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddHopeKitImageMutationVariables = Types.Exact<{
  input: Types.AddHopeKitImageInput;
}>;


export type AddHopeKitImageMutation = { readonly addHopeKitAsset: { readonly hopeKitImage: { readonly __typename: 'HopeKitImage', readonly hopeKitItemID: CustomScalars.GQLUUID, readonly reason?: string | null, readonly url?: string | null }, readonly uploadSession: { readonly url: string } } };

export type AddHopeKitVideoMutationVariables = Types.Exact<{
  input: Types.AddHopeKitVideoInput;
}>;


export type AddHopeKitVideoMutation = { readonly addHopeKitAsset: { readonly hopeKitVideo: { readonly __typename: 'HopeKitVideo', readonly hopeKitItemID: CustomScalars.GQLUUID, readonly reason?: string | null, readonly url?: string | null }, readonly uploadSession: { readonly url: string } } };

export type AddHopeKitQuoteMutationVariables = Types.Exact<{
  input: Types.AddHopeKitQuoteInput;
}>;


export type AddHopeKitQuoteMutation = { readonly addHopeKitQuote: { readonly hopeKitQuote: { readonly __typename: 'HopeKitQuote', readonly hopeKitItemID: CustomScalars.GQLUUID, readonly reason?: string | null, readonly text: string, readonly author?: string | null } } };


export const AddHopeKitImageDocument = gql`
    mutation AddHopeKitImage($input: AddHopeKitImageInput!) {
  addHopeKitAsset: addHopeKitImage(input: $input) {
    hopeKitImage {
      __typename
      hopeKitItemID
      reason
      url
    }
    uploadSession {
      url
    }
  }
}
    `;

/**
 * __useAddHopeKitImageMutation__
 *
 * To run a mutation, you first call `useAddHopeKitImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHopeKitImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHopeKitImageMutation, { data, loading, error }] = useAddHopeKitImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddHopeKitImageMutation(baseOptions?: Apollo.MutationHookOptions<AddHopeKitImageMutation, AddHopeKitImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddHopeKitImageMutation, AddHopeKitImageMutationVariables>(AddHopeKitImageDocument, options);
      }
export type AddHopeKitImageMutationHookResult = ReturnType<typeof useAddHopeKitImageMutation>;

export function performAddHopeKitImage(client: Apollo.ApolloClient<unknown>, variables: AddHopeKitImageMutationVariables, options?: Omit<Apollo.MutationOptions<AddHopeKitImageMutation, AddHopeKitImageMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<AddHopeKitImageMutation, AddHopeKitImageMutationVariables>({
    mutation: AddHopeKitImageDocument,
    variables,
    ...options,
  });
}

export const AddHopeKitVideoDocument = gql`
    mutation AddHopeKitVideo($input: AddHopeKitVideoInput!) {
  addHopeKitAsset: addHopeKitVideo(input: $input) {
    hopeKitVideo {
      __typename
      hopeKitItemID
      reason
      url
    }
    uploadSession {
      url
    }
  }
}
    `;

/**
 * __useAddHopeKitVideoMutation__
 *
 * To run a mutation, you first call `useAddHopeKitVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHopeKitVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHopeKitVideoMutation, { data, loading, error }] = useAddHopeKitVideoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddHopeKitVideoMutation(baseOptions?: Apollo.MutationHookOptions<AddHopeKitVideoMutation, AddHopeKitVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddHopeKitVideoMutation, AddHopeKitVideoMutationVariables>(AddHopeKitVideoDocument, options);
      }
export type AddHopeKitVideoMutationHookResult = ReturnType<typeof useAddHopeKitVideoMutation>;

export function performAddHopeKitVideo(client: Apollo.ApolloClient<unknown>, variables: AddHopeKitVideoMutationVariables, options?: Omit<Apollo.MutationOptions<AddHopeKitVideoMutation, AddHopeKitVideoMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<AddHopeKitVideoMutation, AddHopeKitVideoMutationVariables>({
    mutation: AddHopeKitVideoDocument,
    variables,
    ...options,
  });
}

export const AddHopeKitQuoteDocument = gql`
    mutation AddHopeKitQuote($input: AddHopeKitQuoteInput!) {
  addHopeKitQuote(input: $input) {
    hopeKitQuote {
      __typename
      hopeKitItemID
      reason
      text
      author
    }
  }
}
    `;

/**
 * __useAddHopeKitQuoteMutation__
 *
 * To run a mutation, you first call `useAddHopeKitQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHopeKitQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHopeKitQuoteMutation, { data, loading, error }] = useAddHopeKitQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddHopeKitQuoteMutation(baseOptions?: Apollo.MutationHookOptions<AddHopeKitQuoteMutation, AddHopeKitQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddHopeKitQuoteMutation, AddHopeKitQuoteMutationVariables>(AddHopeKitQuoteDocument, options);
      }
export type AddHopeKitQuoteMutationHookResult = ReturnType<typeof useAddHopeKitQuoteMutation>;

export function performAddHopeKitQuote(client: Apollo.ApolloClient<unknown>, variables: AddHopeKitQuoteMutationVariables, options?: Omit<Apollo.MutationOptions<AddHopeKitQuoteMutation, AddHopeKitQuoteMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<AddHopeKitQuoteMutation, AddHopeKitQuoteMutationVariables>({
    mutation: AddHopeKitQuoteDocument,
    variables,
    ...options,
  });
}
