// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AccountSettingsQuery = { readonly ouiUser?: { readonly __typename: 'CurrentOuiUser', readonly userID: string, readonly primaryOrganization?: { readonly __typename: 'Organization', readonly ID: CustomScalars.GQLUUID, readonly isTrialOrganization: boolean } | null } | null, readonly currentUser: { readonly __typename: 'User', readonly ID: CustomScalars.GQLUserID, readonly email: string, readonly phone: string, readonly name: { readonly first: string, readonly last: string, readonly preferred?: string | null, readonly pfx?: string | null, readonly sfx?: string | null }, readonly demo?: { readonly DOB?: CustomScalars.GQLDate | null } | null, readonly address?: { readonly city: string, readonly country: string, readonly line1: string, readonly line2: string, readonly zip?: string | null, readonly state?: string | null } | null } };

export type SaveAccountSettingsMutationVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.UserNameInput>;
  address?: Types.InputMaybe<Types.UserAddressInput>;
  demo?: Types.InputMaybe<Types.UserDemoInput>;
  phone?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SaveAccountSettingsMutation = { readonly updateInfo: boolean };


export const AccountSettingsDocument = gql`
    query AccountSettings {
  ouiUser {
    __typename
    userID
    primaryOrganization {
      __typename
      ID
      isTrialOrganization
    }
  }
  currentUser {
    __typename
    ID
    email
    phone
    name {
      first
      last
      preferred
      pfx
      sfx
    }
    demo {
      DOB
    }
    address {
      city
      country
      line1
      line2
      zip
      state
    }
  }
}
    `;

/**
 * __useAccountSettingsQuery__
 *
 * To run a query within a React component, call `useAccountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountSettingsQuery(baseOptions?: Apollo.QueryHookOptions<AccountSettingsQuery, AccountSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountSettingsQuery, AccountSettingsQueryVariables>(AccountSettingsDocument, options);
      }
export function useAccountSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountSettingsQuery, AccountSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountSettingsQuery, AccountSettingsQueryVariables>(AccountSettingsDocument, options);
        }
export type AccountSettingsQueryHookResult = ReturnType<typeof useAccountSettingsQuery>;
export type AccountSettingsLazyQueryHookResult = ReturnType<typeof useAccountSettingsLazyQuery>;

export function queryAccountSettings(client: Apollo.ApolloClient<unknown>, variables?: AccountSettingsQueryVariables | undefined, options?: Omit<Apollo.QueryOptions<AccountSettingsQuery, AccountSettingsQueryVariables>, "query" | 'variables'>) {
  return client.query<AccountSettingsQuery, AccountSettingsQueryVariables>({
    query: AccountSettingsDocument,
    variables,
    ...options,
  });
}

export const SaveAccountSettingsDocument = gql`
    mutation SaveAccountSettings($name: UserNameInput, $address: UserAddressInput, $demo: UserDemoInput, $phone: String) {
  updateInfo(name: $name, address: $address, demo: $demo, phone: $phone)
}
    `;

/**
 * __useSaveAccountSettingsMutation__
 *
 * To run a mutation, you first call `useSaveAccountSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAccountSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAccountSettingsMutation, { data, loading, error }] = useSaveAccountSettingsMutation({
 *   variables: {
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      demo: // value for 'demo'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useSaveAccountSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SaveAccountSettingsMutation, SaveAccountSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveAccountSettingsMutation, SaveAccountSettingsMutationVariables>(SaveAccountSettingsDocument, options);
      }
export type SaveAccountSettingsMutationHookResult = ReturnType<typeof useSaveAccountSettingsMutation>;

export function performSaveAccountSettings(client: Apollo.ApolloClient<unknown>, variables?: SaveAccountSettingsMutationVariables | undefined, options?: Omit<Apollo.MutationOptions<SaveAccountSettingsMutation, SaveAccountSettingsMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<SaveAccountSettingsMutation, SaveAccountSettingsMutationVariables>({
    mutation: SaveAccountSettingsDocument,
    variables,
    ...options,
  });
}
