import { ElementType, createElement, useMemo } from 'react';
import { TouchableOpacity } from 'react-native';

import { QuizSetPreview } from '@oui/app-core/src/screens/QuizSet';
import { MyPlanRounded } from '@oui/myplan/src/screens/MyPlanRounded';

import HopeKit from '@src/assets/hopeKitArtifactPreview.svg';
import { Button } from '@src/components/Button';
import { View } from '@src/components/View';
import { APP_SLUG } from '@src/constants';
import { useChatSideEffect } from '@src/hooks/useChatSideEffect';
import { ActivityDiary } from '@src/screens/ActivityDiary';
import { CopingCards } from '@src/screens/CopingCards';
import { MyPlanReview } from '@src/screens/MyPlanReview';
import { Relaxation } from '@src/screens/Relax';
import { SleepDiary } from '@src/screens/SleepDiary';
import { SoloMyPlan } from '@src/screens/SoloMyPlan';
import { SoloRiskCurve } from '@src/screens/SoloRiskCurve';
import { SoloSuicideMode } from '@src/screens/SoloSuicideMode';
import { ThoughtDiary } from '@src/screens/ThoughtDiary';
import { CHAT_PREVIEW_BORDER_RADIUS } from '@src/styles';
import { ChatArtifactPreviewProps, RootStackParamList } from '@src/types';

const HEIGHT = 125;
const WIDTH = 210;
const PADDING = 0;

const ROUTE_NAME_LOOKUP: { [key: string]: keyof RootStackParamList | undefined } = {};

export function ChatArtifactPreview({
  artifactName,
  linkText,
  testID,
  params,
  _height,
  _width,
  _onSideEffect,
}: ChatArtifactPreviewProps & {
  _width?: number;
  _height?: number;
  _onSideEffect?: (effect: {
    kind: 'navigate';
    routeName: string;
    params: { [key: string]: string | boolean };
  }) => void;
}) {
  const width = _width ?? WIDTH;
  const height = _height ?? HEIGHT;

  const PREVIEW_COMPONENTS: { [key: string]: ElementType | null } = useMemo(
    () => ({
      CopingCards,
      MyPlan: MyPlanRounded,
      MyPlanReview,
      RiskCurve: SoloRiskCurve,
      SoloSuicideMode,
      SuicideMode: SoloSuicideMode,
      SoloMyPlan,
      ActivityDiary,
      SleepDiary,
      ThoughtDiary,
      HopeKit: () => (
        <HopeKit
          height={height * 2}
          width={width * 2}
          viewBox="0 0 210 125"
          accessibilityLabel={undefined}
          preserveAspectRatio={height !== HEIGHT ? 'xMinYMin slice' : undefined}
        />
      ),
      Relaxation,
      QuizSet: ({ slug }: { slug: string }) => (
        <QuizSetPreview width={width} height={height} slug={slug} />
      ),
    }),
    [width, height],
  );

  const onSideEffect = useChatSideEffect();

  const routeName = ROUTE_NAME_LOOKUP[artifactName] ?? artifactName;
  let previewComponent = PREVIEW_COMPONENTS[routeName] ?? null;

  if (APP_SLUG === 'oui-aviva-staff') {
    /*
    We cannot render a component preview from within the CMS because we have a dependency 
    on a Modal wrapper. This wrapper is only provided in the app.
  */
    previewComponent = null;
  }

  const shouldTransformPreview = routeName !== 'QuizSet';

  const onPress = () =>
    (_onSideEffect ?? onSideEffect)({
      kind: 'navigate',
      routeName,
      params: { ...params },
    });

  return (
    <TouchableOpacity onPress={onPress}>
      <View spacing={10}>
        <View
          style={{
            width,
            height,
            borderRadius: CHAT_PREVIEW_BORDER_RADIUS,
            borderWidth: artifactName === 'HopeKit' ? undefined : 1,
            padding: PADDING,
          }}
          accessibilityElementsHidden
          importantForAccessibility="no-hide-descendants"
        >
          <View
            style={[
              {
                flexGrow: 1,
                overflow: 'hidden',
              },
              shouldTransformPreview
                ? {
                    borderRadius: CHAT_PREVIEW_BORDER_RADIUS * 2,
                    height: height * 2 - PADDING * 2 - 2,
                    transform: [{ scale: 0.5 }, { translateY: -height }, { translateX: -width }],
                    width: width * 2 - PADDING * 2 - 2,
                  }
                : {
                    borderRadius: CHAT_PREVIEW_BORDER_RADIUS,
                    height: height - PADDING - 2,
                    width: width - PADDING - 2,
                  },
            ]}
            pointerEvents="none"
          >
            {previewComponent
              ? createElement(previewComponent, { preview: true, ...params })
              : null}
          </View>
        </View>
        <Button
          testID={testID}
          onPress={onPress}
          text={linkText}
          variant="text"
          alignSelf="center"
          style={{ maxWidth: WIDTH }}
        />
      </View>
    </TouchableOpacity>
  );
}

export default ChatArtifactPreview;
