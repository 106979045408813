import { useEffect, useRef } from 'react';
import { Animated, StyleSheet, useWindowDimensions } from 'react-native';
import { Circle, CircleProps, Svg } from 'react-native-svg';

import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useTheme } from '@src/styles';

const OFFWHITE = 'rgba(255,255,255,0.6)';
const STEP_SIZE = 34;
const RADIUS = (STEP_SIZE - 3) / 2;
const CIRCUM = RADIUS * 2 * Math.PI;

const AnimatedCircle = Animated.createAnimatedComponent<React.ComponentType<CircleProps>>(Circle);

export function AnimatedProgressCircle({
  testID,
  accessibilityLabel,
  text,
  progress,
}: {
  testID?: string;
  accessibilityLabel?: string;
  text: string;
  progress: number;
}) {
  const { fontScale } = useWindowDimensions();
  const complete = progress === 1;
  const strokeDashoffsetRef = useRef(new Animated.Value(progress ?? 1));
  const { Color } = useTheme();

  const svgProgress = 100 - (progress ?? 1) * 100;
  const percent = Math.floor(progress * 100);

  useEffect(() => {
    const strokeDashoffset = RADIUS * Math.PI * 2 * (svgProgress / 100);
    Animated.timing(strokeDashoffsetRef.current, {
      toValue: strokeDashoffset,
      duration: 200,
      useNativeDriver: true,
    }).start();
  }, [svgProgress]);

  return (
    <View
      testID={testID}
      accessibilityLabel={
        accessibilityLabel ? `${accessibilityLabel}. ${percent} percent` : undefined
      }
      accessible={!!accessibilityLabel}
    >
      <View testID={`AnimatedProgressCircle_progress_${percent}`}>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: STEP_SIZE * fontScale,
            height: STEP_SIZE * fontScale,
            borderRadius: 18,
          }}
        >
          <View style={StyleSheet.absoluteFillObject}>
            <Svg viewBox={`0 0 ${STEP_SIZE} ${STEP_SIZE}`}>
              <AnimatedCircle
                stroke={'#c5c5d3'}
                fill="none"
                cx={STEP_SIZE / 2}
                cy={STEP_SIZE / 2}
                r={RADIUS}
                strokeDasharray={`${CIRCUM} ${CIRCUM}`}
                strokeDashoffset={0}
                strokeLinecap="round"
                strokeWidth="2"
              />
            </Svg>
          </View>
          <View style={StyleSheet.absoluteFillObject}>
            <Svg viewBox={`0 0 ${STEP_SIZE} ${STEP_SIZE}`}>
              <AnimatedCircle
                stroke={complete || progress ? Color.primary : OFFWHITE}
                fill={complete ? Color.primary : 'none'}
                cx={STEP_SIZE / 2}
                cy={STEP_SIZE / 2}
                r={RADIUS}
                strokeDasharray={`${CIRCUM} ${CIRCUM}`}
                strokeDashoffset={strokeDashoffsetRef.current}
                strokeLinecap="round"
                transform={`rotate(-90, ${STEP_SIZE / 2}, ${STEP_SIZE / 2})`}
                strokeWidth="3"
              />
            </Svg>
          </View>
          {text ? (
            <Text
              text={text}
              color={complete ? 'white' : Color.styleGuide.Gray3}
              weight={complete ? 'bold' : undefined}
              style={{ lineHeight: STEP_SIZE }}
            />
          ) : null}
        </View>
      </View>
    </View>
  );
}
