import { PixelRatio } from 'react-native';
import Url from 'url-parse';

import { CLOUD_FUNCTION_URL } from '@src/constants';

const BASE_URL = `${CLOUD_FUNCTION_URL}/imageService`;

export function getResizedImageUrl(src: string, options: { width?: number; height?: number }) {
  const url = new Url(BASE_URL, {}, { parser: true });
  url.query.src = src;
  if (options.width) {
    url.query.w = PixelRatio.getPixelSizeForLayoutSize(options.width);
  }
  if (options.height) {
    url.query.h = PixelRatio.getPixelSizeForLayoutSize(options.height);
  }
  return url.toString();
}
