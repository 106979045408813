import { AssetInfo } from 'expo-media-library';
import * as MediaLibrary from 'expo-media-library';
import { useCallback, useEffect, useState } from 'react';
import { Alert, BackHandler } from 'react-native';

import { MediaPickerResult } from '@oui/app-core/src/types/navigation';

import GalleryIcon from '@src/assets/hopeKitEmpty.svg';
import { ConfirmationModal } from '@src/components/ConfirmationModal';
import { MultipleMediaLibrary } from '@src/components/MultipleMediaLibrary';
import { MultipleMediaLibraryOld } from '@src/components/MultipleMediaLibraryOld';
import { View } from '@src/components/View';
import { IOS_14_OR_GREATER } from '@src/constants';
import { useAppContext } from '@src/hooks/useAppContext';
import { useI18n } from '@src/lib/i18n';
import Sentry from '@src/sentry';
import { Color } from '@src/styles';
import { StackScreenProps } from '@src/types';

export default function MediaPicker(props: StackScreenProps<'MediaPicker'>) {
  const [granted, setGranted] = useState<'all' | 'limited' | false>(false);
  const [showPermissionsExplanationModal, setShowPermissionsExplanationModal] = useState(false);
  const navigate = props.navigation.navigate;
  const { flags } = useAppContext();
  const { $t } = useI18n();

  const cancelMediaPicker = useCallback(() => {
    const _mediaPickerResult: MediaPickerResult = { permission: 'denied' };
    navigate({
      name: props.route.params?.returnRoute,
      params: {
        _mediaPickerResult,
      } as any,
      merge: true,
    });
  }, [navigate, props.route.params?.returnRoute]);

  const requestPermission = useCallback(() => {
    MediaLibrary.requestPermissionsAsync().then((result) => {
      Sentry.addBreadcrumb({
        type: 'media-library',
        message: 'requestPermissionsAsync result',
        data: result,
      });

      if (result.granted && result.accessPrivileges !== 'none') {
        setGranted(result.accessPrivileges || 'all');
      } else if (result.canAskAgain) {
        Alert.alert(
          'Permissions requested',
          'Please accept permissions in order to select photos or videos.',
          [
            { text: 'Ask again', onPress: () => requestPermission() },
            {
              text: 'Not now',
              onPress: () => {
                cancelMediaPicker();
              },
              style: 'cancel',
            },
          ],
          { cancelable: false },
        );
      } else {
        cancelMediaPicker();
      }
    });
  }, [cancelMediaPicker]);

  useEffect(() => {
    MediaLibrary.getPermissionsAsync().then((result) => {
      if (IOS_14_OR_GREATER && result.canAskAgain && result.accessPrivileges !== 'all') {
        setShowPermissionsExplanationModal(true);
      } else {
        requestPermission();
      }
    });
  }, [requestPermission]);

  const callback = useCallback(
    (assets: AssetInfo[]) => {
      const _mediaPickerResult: MediaPickerResult = { permission: 'granted', assets };
      navigate(props.route.params.returnRoute, {
        _mediaPickerResult,
      } as any);
    },
    [navigate, props.route.params?.returnRoute],
  );

  useEffect(() => {
    const handler = () => {
      callback([]);
      return true;
    };
    const listener = BackHandler.addEventListener('hardwareBackPress', handler);
    return () => listener.remove();
  }, [callback]);

  const Component =
    flags.useMediaLibraryAlbums && granted === 'all'
      ? MultipleMediaLibrary
      : MultipleMediaLibraryOld;
  return granted ? (
    <Component
      $t={$t}
      max={50}
      callback={callback}
      headerButtonColor={Color.accent}
      badgeColor={Color.accent}
    />
  ) : showPermissionsExplanationModal ? (
    <ConfirmationModal
      visible={true}
      title="Allow access to All Photos"
      description="Aviva works best when you allow access to All Photos. Only ones you select are securely stored by Aviva."
      onCancel={cancelMediaPicker}
      onConfirm={requestPermission}
      preTitle={
        <View style={{ alignItems: 'center', marginBottom: 18 }}>
          <GalleryIcon height={60} accessibilityLabel={undefined} />
        </View>
      }
    />
  ) : null;
}
