import type { ApolloClient } from '@apollo/client';
import { v4 as uuid } from 'uuid';

import { getConfigString } from '@oui/app-core/src/lib/remoteConfig';
import type { transitionBot as TransitionBotType } from '@oui/lib/src/bot';

import { APP_SLUG, BOT_TRANSITION_URL, IS_PRODUCTION, manifest } from '@src/constants';
import { getAuthToken } from '@src/lib/auth';
import Sentry from '@src/sentry';

export const transitionBot = async (
  _: ApolloClient<unknown>, // for API compatibility with transitionBotLocal
  configID: Parameters<typeof TransitionBotType>[0],
  currentStateHash: Parameters<typeof TransitionBotType>[1],
  action: Parameters<typeof TransitionBotType>[2],
): ReturnType<typeof TransitionBotType> => {
  const data = {
    configID,
    currentStateHash,
    action,
    apiUrl: getConfigString('apiUrl'),
  };

  const requestID = uuid();
  Sentry.addBreadcrumb({
    category: 'http',
    message: 'transitionBot request',
    data: { requestID, currentStateHash },
    level: 'debug',
  });

  let timeout = 0 as unknown as NodeJS.Timeout;
  let controller: AbortController | undefined = undefined;
  if (window.AbortController) {
    controller = new AbortController();
    timeout = setTimeout(() => {
      Sentry.addBreadcrumb({ category: 'http', message: 'abort request due to timeout' });
      controller?.abort();
    }, 5000);
  }

  let body;
  try {
    // https://sentry.io/organizations/oui/issues/2133473595/?environment=staging&project=1512711&query=is%3Aunresolved
    body = JSON.stringify(data);
  } catch (e) {
    if (!IS_PRODUCTION) {
      Object.entries(data).forEach(([key, val]) => {
        console.log(key);
        console.log(val);
      });
      console.log(data);
      Sentry.captureException(e);
    }
    throw e;
  }

  const result = await (
    await fetch(BOT_TRANSITION_URL, {
      signal: controller?.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Oui-Client': APP_SLUG!,
        'X-RequestID': requestID,
        'X-Oui-Client-Version': manifest.version ?? '0.0.0',
      },
      body,
    })
  ).json();

  clearTimeout(timeout);

  return result;
};
