import { Header } from '@react-navigation/elements';
import { useFocusEffect } from '@react-navigation/native';
import { createElement, useCallback, useRef, useState } from 'react';
import { Modal, Platform, SafeAreaView, StatusBar } from 'react-native';

import LoginFlower from '@oui/app-core/src/assets/Login_flower.svg';
import LoginPlant from '@oui/app-core/src/assets/Login_plant.svg';
import MediaPlayer from '@oui/app-core/src/components/MediaPlayer';
import { Pill } from '@oui/app-core/src/components/Pill';
import { useSnackbarContext } from '@oui/app-core/src/components/SnackbarProvider';
import { getRatingsLabels } from '@oui/app-core/src/hooks/practices';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { Shadow } from '@oui/app-core/src/styles';
import { RatingType } from '@oui/lib/src/types';

import { ArtifactButton } from '@src/components/ArtifactButton';
import BreathingCircle, { Handles } from '@src/components/BreathingCircle';
import { Button } from '@src/components/Button';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import {
  AddRelaxDiaryEntryMutation,
  useAddRelaxDiaryEntryMutation,
  useRelaxDiaryEntryCountsQuery,
  useUpdateRelaxDiaryEntryMutation,
} from '@src/hooks/practices.graphql.generated';
import { useCurrentPatientID } from '@src/hooks/useCurrentUser';
import { useWindowDimensions } from '@src/hooks/useWindowDimensions';
import { logEvent } from '@src/lib/log';
import { Exercise, getMindfulnessExercises } from '@src/screens/Relax';
import { namedAvivaOperations } from '@src/types/namedOperations.generated';

import { ConfirmationModal } from './ConfirmationModal';
import { HeaderButtons, HeaderItem } from './HeaderButtons';
import { PracticeRatingsInput } from './PracticeRatingInput';
import { Label } from './Text';

const DEFAULT_RATING = 3;
function MoodRatingModal({
  completion,
  entry,
  onRequestClose,
  updatePractice,
}: {
  completion: number;
  entry: AddRelaxDiaryEntryMutation['saveRelaxDiaryEntry']['relaxDiaryEntryPractice'];
  onRequestClose: () => void;
  updatePractice: ReturnType<typeof useUpdateRelaxDiaryEntryMutation>[0];
}) {
  const [step, setStep] = useState<'before' | 'after'>('before');
  const { data, bind } = useForm(entry.practiceValues);
  completion = Math.floor(completion * 100) / 100;
  const { addSnackbarItem } = useSnackbarContext();
  const { $t } = useI18n();

  return (
    <ConfirmationModal
      onCancel={() => {
        onRequestClose();
      }}
      onConfirm={async () => {
        if (step === 'before') {
          setStep('after');
        } else {
          await updatePractice({
            variables: {
              input: {
                practiceID: entry.practiceID,
                practiceValues: {
                  ratings: [
                    {
                      type: RatingType.RATING_BEFORE,
                      value:
                        data.ratings.find((r) => r.type === RatingType.RATING_BEFORE)?.value ??
                        DEFAULT_RATING,
                    },
                    {
                      type: RatingType.RATING_AFTER,
                      value:
                        data.ratings.find((r) => r.type === RatingType.RATING_AFTER)?.value ??
                        DEFAULT_RATING,
                    },
                  ],
                  date: entry.practiceValues.date,
                  patientID: entry.practiceValues.patient.ID,
                },
                relaxDiaryEntry: { completion, slug: entry.relaxDiaryEntry.slug },
              },
            },
          });
          onRequestClose();
          addSnackbarItem({
            text: $t({
              id: 'Relax_ratingCompletedSnackbar',
              defaultMessage: 'Mood rating recorded',
            }),
          });
        }
      }}
      title={
        step === 'before'
          ? $t({ id: 'Relax_moodRatingModal_beforeTitle', defaultMessage: 'Mood rating before' })
          : $t({ id: 'Relax_moodRatingModal_afterTitle', defaultMessage: 'Mood rating after' })
      }
      visible
      confirmText={
        step === 'before'
          ? $t({ id: 'Relax_moodRatingModal_nextButton', defaultMessage: 'Next' })
          : $t({ id: 'Relax_moodRatingModal_doneButton', defaultMessage: 'Done' })
      }
      confirmTestID={
        step === 'before' ? 'MoodRatingModal_nextButton' : 'MoodRatingModal_doneButton'
      }
      key={step}
    >
      <View style={{ paddingVertical: 30 }} spacing={30}>
        <Label
          text={
            step === 'before'
              ? $t({
                  id: 'Relax_moodRatingModal_before',
                  defaultMessage: 'What was your mood before doing this?',
                })
              : $t({
                  id: 'Relax_moodRatingModal_after',
                  defaultMessage: 'What was your mood after doing this?',
                })
          }
          textAlign="center"
          accessibilityRole="none"
        />
        <PracticeRatingsInput
          ratingType={step === 'before' ? RatingType.RATING_BEFORE : RatingType.RATING_AFTER}
          defaultRating={DEFAULT_RATING}
          labels={getRatingsLabels($t)}
          {...bind(['ratings'], {
            accessibilityLabel: $t({
              id: 'Relax_moodRatingModal_ratingAccessibilityLabel',
              defaultMessage: 'How helpful is this thought to you?',
            }),
          })}
          testID={
            step === 'before' ? 'MoodRatingModal_ratingBefore' : 'MoodRatingModal_ratingAfter'
          }
        />
      </View>
    </ConfirmationModal>
  );
}

function MindfulnessModal({
  onRequestClose,
  exercise,
}: {
  exercise: Exercise;
  onRequestClose: (completion: number) => void;
}) {
  const mindfulnessPlayer = useRef<MediaPlayer>(null);
  const completionRef = useRef(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const { $t } = useI18n();

  useFocusEffect(
    useCallback(() => {
      return () => {
        if (mindfulnessPlayer.current) {
          mindfulnessPlayer.current.setIsPlayingAsync(false);
        }
      };
    }, []),
  );

  function onRequestCloseWithCompletion() {
    onRequestClose(completionRef.current);
  }

  return (
    <Modal visible onRequestClose={onRequestCloseWithCompletion}>
      <StatusBar barStyle="light-content" />
      <SafeAreaView style={{ flex: 1, backgroundColor: '#28352c' }}>
        <Header
          title={$t({
            id: 'Relax_mindfulnessModal_exerciseTitle',
            defaultMessage: 'Mindfulness exercise',
          })}
          headerTintColor="white"
          headerLeft={() => {
            return (
              <HeaderButtons>
                <HeaderItem
                  testID="Relax_closeMindfulnessExerciseButton"
                  accessibilityLabel={$t({
                    id: 'Relax_mindfulnessModal_closeButton',
                    defaultMessage: 'Stop mindfulness exercise',
                  })}
                  iconName="close"
                  color="white"
                  iconSize={24}
                  title=""
                  onPress={onRequestCloseWithCompletion}
                />
              </HeaderButtons>
            );
          }}
          headerStyle={{ backgroundColor: '#28352c' }}
        />
        <View
          flex={1}
          style={{
            justifyContent: 'space-around',
            paddingHorizontal: 20,
          }}
        >
          <View
            style={[
              {
                borderRadius: 20,
              },
              Shadow.high,
            ]}
          >
            <View
              style={{
                backgroundColor: '#405446',
                borderRadius: 20,
                overflow: 'hidden',
              }}
            >
              {/* SvgUri does not exist on web */}
              {Platform.OS === 'web' ? (
                <View />
              ) : (
                <View
                  style={{
                    width: '100%',
                    height: 200,
                    backgroundColor: exercise.largeAssetBackgroundColor ?? '#ebf1ed',
                    borderRadius: 20,
                    overflow: 'hidden',
                  }}
                >
                  {createElement(exercise.assetName, {
                    height: '101%',
                    width: '101%',
                    preserveAspectRatio: exercise.largeAssetName ? 'none meet' : 'xMinYMax meet',
                  })}
                </View>
              )}
              <Text
                text={exercise.title}
                textAlign="center"
                color="white"
                style={{ marginTop: 20 }}
              />
              <View>
                <MediaPlayer
                  _hidePlayPauseButton
                  onProgress={(progress, isPlaying) => {
                    completionRef.current = progress.positionMillis / progress.durationMillis;
                    setIsPlaying(isPlaying);
                    if (!isLoaded) setIsLoaded(true);
                  }}
                  onEnd={() => (completionRef.current = 1)}
                  backgroundColor="#405446"
                  miniPlayer={false}
                  miniPlayerControlsColor="white"
                  ref={mindfulnessPlayer}
                  uri={exercise.audio.localUri ?? exercise.audio.uri}
                  subtitleUri={exercise.subtitleUri}
                  controlsContainerStyle={{ paddingHorizontal: 5 }}
                />
                {isLoaded ? (
                  <View
                    style={{
                      position: 'absolute',
                      alignItems: 'center',
                      left: 0,
                      right: 0,
                      top: 100,
                    }}
                    pointerEvents="box-none"
                  >
                    <Button
                      testID={
                        isPlaying ? 'Relax_mindfulnessPauseButton' : 'Relax_mindfulnessPlayButton'
                      }
                      size="large"
                      icon={isPlaying ? 'pause' : 'play'}
                      alignSelf="center"
                      onPress={() => {
                        mindfulnessPlayer.current?.setIsPlayingAsync(!isPlaying);
                      }}
                      text=""
                      style={[{ borderWidth: 0 }, Shadow.high]}
                    />
                  </View>
                ) : null}
              </View>
            </View>
          </View>
        </View>
      </SafeAreaView>
    </Modal>
  );
}

export function RelaxInner(props: { onStartExercise?: () => void }) {
  const { height } = useWindowDimensions();
  const breathingCircle = useRef<Handles>(null);
  const [showMindfulnessModal, setShowMindfulnessModal] = useState<Exercise>();
  const [showMoodRatingModal, setShowMoodRatingModal] = useState<{ completion: number }>();
  const { data: counts } = useRelaxDiaryEntryCountsQuery();
  const [addPractice, { data: addData, reset: resetAdd }] = useAddRelaxDiaryEntryMutation({
    refetchQueries: [namedAvivaOperations.Query.RelaxDiaryEntryCounts],
  });
  const [updatePractice, { data: updateData, reset: resetUpdate }] =
    useUpdateRelaxDiaryEntryMutation();
  const patientID = useCurrentPatientID();
  const data = updateData ?? addData;
  const { $t } = useI18n();

  function getCount(slug: string) {
    return counts?.relaxDiaryEntryCounts[slug] ?? 0;
  }

  useFocusEffect(
    useCallback(() => {
      return () => {
        if (breathingCircle.current) {
          breathingCircle.current?.pauseAsync();
        }
      };
    }, []),
  );

  function addNewPractice(slug: string) {
    resetAdd();
    resetUpdate();
    props.onStartExercise?.();
    return addPractice({
      variables: {
        input: {
          practiceValues: { patientID: patientID!, ratings: [] },
          relaxDiaryEntry: { slug, completion: 0 },
        },
      },
    });
  }

  return (
    <>
      <View style={{ zIndex: 1 }}>
        <BreathingCircle
          ref={breathingCircle}
          onStart={() => addNewPractice('breathe')}
          onPause={(completion) => {
            setShowMoodRatingModal({ completion });
          }}
          onEnd={() => {
            setShowMoodRatingModal({ completion: 1 });
          }}
        />
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignSelf: 'stretch',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          paddingRight: 40,
          borderBottomWidth: 1,
        }}
      >
        <LoginPlant height={height / 6} accessibilityLabel={undefined} />
        <View style={{ position: 'absolute', left: 0, right: 0, alignItems: 'center', bottom: 40 }}>
          <Pill
            text={$t(
              { id: 'Relax_breatheDoneCount', defaultMessage: '{count} done' },
              { count: getCount('breathe') },
            )}
            color="#104f51"
            backgroundColor="#ebf1ed"
          />
        </View>
        <LoginFlower height={height / 6} accessibilityLabel={undefined} />
      </View>
      <View style={{ paddingTop: 50, paddingHorizontal: 20 }} spacing={35}>
        {getMindfulnessExercises($t).map((exercise) => (
          <ArtifactButton
            key={exercise.slug}
            testID={`Relax_exerciseButton__${exercise.slug}`}
            assetName={exercise.assetName}
            title={exercise.title}
            numCompleted={1}
            badgeText={$t(
              { id: 'Relax_exerciseDoneCount', defaultMessage: '{count} done' },
              { count: getCount(exercise.slug) },
            )}
            onPress={() => {
              breathingCircle.current?.pauseAsync();
              addNewPractice(exercise.slug);
              setShowMindfulnessModal(exercise);
              logEvent('mindfulness_exercise', { slug: exercise.slug });
            }}
          />
        ))}
      </View>
      {showMindfulnessModal ? (
        <MindfulnessModal
          onRequestClose={(completion) => {
            setShowMindfulnessModal(undefined);
            setShowMoodRatingModal({ completion });
          }}
          exercise={showMindfulnessModal}
        />
      ) : null}
      {showMoodRatingModal && data?.saveRelaxDiaryEntry ? (
        <MoodRatingModal
          entry={data.saveRelaxDiaryEntry.relaxDiaryEntryPractice}
          completion={showMoodRatingModal.completion}
          onRequestClose={() => setShowMoodRatingModal(undefined)}
          updatePractice={updatePractice}
        />
      ) : null}
    </>
  );
}
