import * as shape from 'd3-shape';
import { ComponentProps, Fragment } from 'react';
import { Circle } from 'react-native-svg';

import { Grid, LineChart, XAxis, YAxis } from '@oui/app-core/src/svgCharts';
import { RatingType } from '@oui/lib/src/types/graphql.generated';

import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useTheme } from '@src/styles';

// For calculation of "dashed" line placeholder for no data points
// {
//   data: getDataInverse(data1),
//   svg: { stroke: Color.styleGuide.LogoCyan, strokeDasharray: [4, 4] },
// },
// function getDataInverse(data: (number | undefined)[]) {
// const inverse = [];

// for (let i = 0; i < data.length; i = i + 1) {
// if (typeof data[i] === 'number') {
//   if (
//     (i < data.length - 1 && data[i + 1] === undefined) ||
//     (i > 0 && data[i - 1] === undefined)
//   ) {
//     inverse[i] = data[i];
//   } else {
//     inverse[i] = undefined;
//   }
//   continue;
// }

// inverse[i] = (data[i - 1] + data[i + 1]) / 2;
// }

// console.log(data, inverse);

// return inverse;
// }

function Legend(props: { data: Array<{ color: string; text: string }> }) {
  return (
    <View spacing={6}>
      {props.data?.map((d) => {
        return (
          <View row spacing={8} key={d.color}>
            <View style={{ width: 15, height: 15, borderRadius: 3, backgroundColor: d.color }} />
            <Text size={13} text={d.text} />
          </View>
        );
      })}
    </View>
  );
}

export function RatingGraph(props: {
  accessibilityLabel: string;
  emptyText?: string;
  legend: ComponentProps<typeof Legend>['data'];
  ratings: Array<{ type: RatingType; data: (number | undefined)[] }>;
  showDots: boolean;
  testID?: string;
  xAxisLabel: string;
  xLabels: string[];
  yAxisLabel: string;
}) {
  const { Color } = useTheme();
  const labels = props.xLabels;

  let hasData = false;
  const data = props.ratings.map((r, i) => {
    hasData = hasData || r.data.some((d) => typeof d === 'number');
    return {
      data: r.data,
      svg: { stroke: props.legend[i].color },
    };
  });
  const dataTestID = props.testID ? JSON.stringify(data.map((d) => d.data)) : undefined;

  const Decorator = ({ x, y }: any) => {
    return (
      <>
        {data.map((dataSet, i) => {
          return (
            <Fragment key={i}>
              {dataSet.data.map((value, index) => {
                // We should render a dot if we have a value and the graph wants dots or
                // the graph doesn't explicitly want dots but we have a standalone value that will
                // be invisible otherwise
                if (
                  typeof value === 'number' &&
                  (props.showDots ||
                    (typeof dataSet.data[index - 1] !== 'number' &&
                      typeof dataSet.data[index + 1] !== 'number'))
                ) {
                  return (
                    <Circle
                      key={index}
                      cx={x(index)}
                      cy={y(value)}
                      r={4}
                      fill={data[i].svg.stroke}
                    />
                  );
                }
                return null;
              }) ?? null}
            </Fragment>
          );
        })}
      </>
    );
  };

  const axesSvg = { fontSize: 13, fill: Color.text, fontFamily: 'OpenSansRegular' };

  // adjusting the margin / content inset allows us to allow the plotline to show outside
  // of the standard chart/grid area (i.e. it allows for overflowing above/below the graph
  // over adjacent elements) without clipping
  // https://github.com/ouihealth/oui/issues/1256
  const marginTop = -40;
  const marginBottom = -40;
  const verticalContentInset = {
    top: -marginTop,
    bottom: -marginBottom + 20,
  };
  const xAxisHeight = 30;

  return (
    <View
      accessibilityRole="image"
      accessibilityLabel={props.accessibilityLabel}
      testID={props.testID}
    >
      <View
        row
        style={{ justifyContent: 'space-between', alignItems: 'flex-start', minHeight: 43 }}
      >
        <Text text={props.yAxisLabel} weight="semibold" />
        {props.legend.length > 1 ? <Legend data={props.legend} /> : null}
      </View>
      <View
        style={{ paddingHorizontal: 5, paddingTop: 10, height: 240, flexDirection: 'row' }}
        testID={dataTestID}
      >
        <YAxis
          data={[1, 2, 3, 4, 5]}
          style={{
            paddingBottom: 10,
            marginBottom: xAxisHeight,
            marginTop: -15,
            paddingTop: 10,
            borderRightWidth: 1,
            borderRightColor: Color.styleGuide.Gray6,
            paddingRight: 7,
            marginRight: -7,
            borderBottomColor: 'transparent',
          }}
          contentInset={{ top: 10, bottom: 10 }}
          formatLabel={(v) => v}
          numberOfTicks={5}
          svg={axesSvg}
        />
        <View style={{ flex: 1, marginLeft: 10 }}>
          {!props.emptyText || hasData ? null : (
            <View
              style={{
                position: 'absolute',
                top: '30%',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <Text text={props.emptyText} textAlign="center" size={15} style={{ maxWidth: 140 }} />
            </View>
          )}
          <LineChart
            style={{ flex: 1, marginTop, marginBottom }}
            data={data}
            contentInset={{ ...verticalContentInset, left: 5, right: 5 }}
            svg={{ strokeWidth: 2 }}
            numberOfTicks={5}
            curve={shape.curveNatural}
            yMin={1}
            yMax={5}
          >
            <Grid svg={{ strokeOpacity: 0.3 }} />
            <Decorator />
          </LineChart>
          <XAxis
            style={{
              marginHorizontal: -5,
              height: xAxisHeight,
              paddingTop: 10,
              borderTopWidth: 1,
              borderColor: Color.styleGuide.Gray6,
            }}
            data={data[0].data}
            formatLabel={(_, index) => labels[index]}
            contentInset={{ left: 15, right: 15 }}
            svg={axesSvg}
          />
        </View>
      </View>
      <Text text={props.xAxisLabel} weight="semibold" textAlign="center" />
    </View>
  );
}
