// Since we can't get the return type of a generic function without calling it, we have a helper
// getFormType that let's us do so safely without erroring. However, we also have to be careful
// that this file gets executed before react is rendering (so this must be imported in the initial
// bundle load) otherwise the hooks may coincidentally execute during rendering and then cause
// subsequent renders to fail because we conditionally rendered a hook.

import { AddThoughtDiaryEntryMutationVariables } from '@src/hooks/practices.graphql.generated';
import { useForm } from '@src/hooks/useForm';

function getFormType<T extends object>() {
  let form;
  if (Math.random() < 0) {
    try {
      // eslint-disable-next-line
      form = useForm<T>({});
    } catch (e) {}
  }
  return form;
}

// function getFormBindType<T extends object>() {
//   let bind;
//   try {
//     // eslint-disable-next-line
//     bind = useForm<T>({}).bind;
//   } catch (e) {}
//   return bind;
// }

const EditThoughtDiaryEntryForm = getFormType<AddThoughtDiaryEntryMutationVariables['input']>();
export type EditThoughtDiaryEntryFormType = NonNullable<typeof EditThoughtDiaryEntryForm>;
