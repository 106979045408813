import { useFocusEffect } from '@react-navigation/native';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Image, Modal, SafeAreaView, ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import Url from 'url-parse';

import { AspectRatio } from '@oui/app-core/src/components/AspectRatio';
import MediaPlayer, { FullscreenMediaPlayer } from '@oui/app-core/src/components/MediaPlayer';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import BreathingCircle, { Handles } from '@src/components/BreathingCircle';
import { Button } from '@src/components/Button';
import { Icon } from '@src/components/Icon';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { getResizedImageUrl } from '@src/lib/getResizedImageUrl';
import Sentry from '@src/sentry';
import { ChatAssetProps } from '@src/types';

const MAX_WIDTH = 170;

function ChatVideo({
  caption,
  initialPositionMillis,
  isAudio,
  uri,
  linkText,
}: ChatAssetProps & {
  initialPositionMillis?: number;
  isAudio?: boolean;
  caption?: string | ReactNode;
}) {
  const player = useRef<MediaPlayer>(null);

  const play = useCallback(() => {
    if (player.current) {
      player.current.presentFullscreenPlayer();
    }
  }, []);

  const pause = useCallback(() => {
    if (player.current) {
      player.current.dismissFullscreenPlayer();
    }
  }, []);

  useFocusEffect(
    useCallback(() => {
      return () => pause();
    }, [pause]),
  );

  return (
    <View
      accessible
      accessibilityLabel={
        isAudio ? `Listen to ${linkText ?? 'audio'}` : `Watch ${linkText ?? 'video'}`
      }
      accessibilityRole="imagebutton"
    >
      <View importantForAccessibility="no-hide-descendants" accessibilityElementsHidden>
        <FullscreenMediaPlayer
          previewAspectRatio={1}
          mediaAspectRatio={null}
          uri={uri}
          ref={player}
          initialPositionMillis={initialPositionMillis ?? 5000}
          caption={caption}
          borderRadius={10}
        />
        <Button
          text={linkText ?? (isAudio ? 'Play audio' : 'Play video')}
          variant="text"
          alignSelf="center"
          onPress={play}
        />
      </View>
    </View>
  );
}

function ChatImage({ uri, linkText, caption }: ChatAssetProps & { caption?: ReactNode }) {
  const [fullscreen, setFullscreen] = useState(false);
  const [loading, setLoading] = useState(true);

  return (
    <>
      <TouchableOpacity
        onPress={() => setFullscreen(true)}
        accessibilityLabel={linkText ?? 'Show image'}
        accessibilityRole="imagebutton"
      >
        <AspectRatio
          aspectRatio={1}
          style={{
            width: '100%',
          }}
        >
          <Image
            source={{
              uri: uri.startsWith('https://storage.googleapis.com')
                ? getResizedImageUrl(uri, { width: MAX_WIDTH })
                : uri,
            }}
            onError={(e) => {
              Sentry.withScope((scope) => {
                scope.setExtras({ error: e.nativeEvent });
                Sentry.captureException('ChatAsset image load error');
              });
            }}
            onLoadEnd={() => setLoading(false)}
            resizeMode="cover"
            style={{
              height: '100%',
              width: '100%',
              borderRadius: 10,
            }}
          />
          {loading ? <ActivityIndicator style={StyleSheet.absoluteFillObject} /> : null}
        </AspectRatio>
        {linkText === '' ? null : (
          <Button
            text={linkText ?? 'Show image'}
            variant="text"
            alignSelf="center"
            onPress={() => setFullscreen(true)}
          />
        )}
      </TouchableOpacity>
      {fullscreen ? (
        <Modal visible={fullscreen} transparent={true}>
          <ImageViewer
            useNativeDriver
            backgroundColor="rgba(0,0,0,0.95)"
            enableSwipeDown
            renderIndicator={() => <View />}
            footerContainerStyle={{ width: '100%' }}
            renderHeader={() => (
              <SafeAreaView
                style={{
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  zIndex: 1,
                }}
              >
                <View style={{ padding: 10, alignItems: 'flex-start' }}>
                  <Icon
                    name="close"
                    onPress={() => setFullscreen(false)}
                    color="white"
                    accessibilityLabel="Exit fullscreen"
                  />
                </View>
              </SafeAreaView>
            )}
            renderFooter={
              caption
                ? () => (
                    <SafeAreaView style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}>
                      <ScrollView
                        style={{ maxHeight: 150 }}
                        contentContainerStyle={{ padding: 10 }}
                      >
                        {typeof caption === 'string' ? (
                          <Text
                            text={caption!}
                            color="white"
                            textAlign="center"
                            weight="semibold"
                          />
                        ) : (
                          caption
                        )}
                      </ScrollView>
                    </SafeAreaView>
                  )
                : undefined
            }
            imageUrls={[{ url: uri }]}
            loadingRender={() => <ActivityIndicator />}
            onCancel={() => setFullscreen(false)}
            onClick={() => setFullscreen(false)}
          />
        </Modal>
      ) : null}
    </>
  );
}

function VisualRelaxPreview() {
  const [fullscreen, setFullscreen] = useState(false);
  const breathingRef = useRef<Handles>(null);
  useEffect(() => {
    const current = breathingRef.current;
    return () => {
      current?.pauseAsync();
    };
  }, []);

  return (
    <View>
      <TouchableOpacity onPress={() => setFullscreen(true)}>
        <View pointerEvents="box-only">
          <BreathingCircle />
        </View>
      </TouchableOpacity>
      <Modal visible={fullscreen} transparent={true}>
        <View
          style={{ flex: 1, backgroundColor: 'rgba(255,255,255,0.9)', justifyContent: 'center' }}
        >
          <BreathingCircle ref={breathingRef} />
          <Icon
            accessibilityLabel="Exit fullscreen"
            style={{ position: 'absolute', top: 80, right: 20 }}
            name="close"
            onPress={() => {
              breathingRef.current?.pauseAsync();
              setFullscreen(false);
            }}
          />
        </View>
      </Modal>
    </View>
  );
}

export function classifyAsset(uri: string) {
  const isVisualRelax = uri === 'local://VisualRelax';
  const { pathname: _pathname, query } = new Url(uri, {}, { parser: true });
  const pathname = _pathname.toLowerCase();
  const isVideo =
    pathname.endsWith('.mov') ||
    pathname.endsWith('.mp4') ||
    pathname.endsWith('.mov') ||
    query.contentType?.includes('video');
  const isAudio = pathname.endsWith('.mp3') || query.contentType?.includes('audio');
  const isImage =
    uri.startsWith('data:image') ||
    pathname.startsWith('data:image') ||
    pathname.endsWith('.jpeg') ||
    pathname.endsWith('.jpg') ||
    pathname.endsWith('.heic') ||
    pathname.endsWith('.png') ||
    query.contentType?.includes('image');

  return {
    isVisualRelax,
    isVideo,
    isAudio,
    isImage,
  };
}

export function getAccessibilityTextForChatAsset(props: ChatAssetProps) {
  const { isVisualRelax, isVideo, isAudio, isImage } = classifyAsset(props.uri);
  if (isVideo) {
    return `A video: ${props.linkText ?? ''}`;
  } else if (isAudio) {
    return `An audio recording: ${props.linkText ?? ''}`;
  } else if (isImage) {
    return `An image`;
  } else if (isVisualRelax) {
    return 'A relaxation exercise';
  }

  return 'An asset';
}

export function ChatAsset({
  caption,
  initialPositionMillis,
  linkText,
  padding = true,
  uri,
}: ChatAssetProps & {
  padding?: boolean;
  caption?: string | ReactNode;
  initialPositionMillis?: number;
}) {
  const { isVisualRelax, isVideo, isAudio, isImage } = classifyAsset(uri);

  return (
    <View style={{ padding: padding ? 10 : 0 }}>
      <View style={{ maxWidth: MAX_WIDTH }}>
        {isVideo ? (
          <ChatVideo
            uri={uri}
            linkText={linkText}
            initialPositionMillis={initialPositionMillis}
            caption={caption}
          />
        ) : null}
        {isAudio ? <ChatVideo uri={uri} linkText={linkText} isAudio caption={caption} /> : null}
        {isImage ? <ChatImage uri={uri} linkText={linkText} caption={caption} /> : null}
        {isVisualRelax ? <VisualRelaxPreview /> : null}
      </View>
    </View>
  );
}

export default ChatAsset;
