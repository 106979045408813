import color from 'color';
import { ReactNode, memo, useEffect, useRef, useState } from 'react';
import { Animated, Easing, StyleProp, View, ViewProps, ViewStyle } from 'react-native';

import { ChatTypingIndicator } from '@src/components/ChatTypingIndicator';
import { Markdown } from '@src/components/Markdown';
import { Text } from '@src/components/Text';
import { useTheme } from '@src/styles';
import { Actor, ChatSide } from '@src/types';

type Props = {
  backgroundColor?: string;
  bubbleStyle?: StyleProp<ViewStyle>;
  extraMargin?: boolean;
  sameSideAsPrevious?: boolean;
  side?: ChatSide;
  value: ReactNode;
} & ViewProps;

const BORDER_RADIUS = 16;
const OUTER_MIN_HEIGHT = 68;

const MARKDOWN_REGEX = /[\*\_\-\|]/;

function ChatBubble({
  backgroundColor,
  borderColor,
  isAlignStart,
  sameSideAsPrevious,
  extraMargin,
  style,
  text,
  ...rest
}: {
  backgroundColor?: string;
  borderColor?: string;
  isAlignStart: boolean;
  sameSideAsPrevious?: boolean;
  extraMargin?: boolean;
  style?: StyleProp<ViewStyle>;
  text: ReactNode;
} & ViewProps) {
  const { Color } = useTheme();
  const textColor = color(backgroundColor).isDark() ? 'white' : Color.text;

  return (
    <View
      {...rest}
      style={[
        {
          alignSelf: isAlignStart ? 'flex-start' : 'flex-end',
          marginBottom: 5,
          marginHorizontal: 12,
          marginTop: extraMargin ? 15 : 5,
        },
        {
          backgroundColor,
          borderColor,
          borderRadius: BORDER_RADIUS,
          borderWidth: 2,
          display: 'flex',
          flexGrow: 0,
          flexShrink: 1,
          justifyContent: 'center',
          overflow: 'hidden',
          maxWidth: '80%',
          padding: 16,
          ...(sameSideAsPrevious
            ? {}
            : isAlignStart
            ? {
                alignItems: 'flex-start',
                borderTopLeftRadius: 0,
              }
            : {
                alignItems: 'flex-end',
                borderTopRightRadius: 0,
              }),
        },
        style,
      ]}
    >
      {typeof text === 'string' && MARKDOWN_REGEX.exec(text) ? (
        <Markdown fontFamily="OpenSansSemiBold" textColor={textColor}>
          {text}
        </Markdown>
      ) : typeof text === 'string' ? (
        <Text text={text} weight="semibold" color={textColor} />
      ) : (
        text
      )}
    </View>
  );
}

export const ChatText = memo(
  ({
    accessible = true,
    backgroundColor: _backgroundColor,
    bubbleStyle,
    sameSideAsPrevious,
    extraMargin,
    side = ChatSide.START,
    value,
  }: Props) => {
    const { Color } = useTheme();
    const isAlignStart = side === ChatSide.START;
    const backgroundColor = _backgroundColor ?? isAlignStart ? Color.surfaceColor : '#008689';
    const borderColor = isAlignStart ? Color.secondary : '#008689';

    return (
      <ChatBubble
        accessible={accessible}
        accessibilityLabel={
          accessible
            ? (sameSideAsPrevious ? '' : isAlignStart ? 'Aviva said: ' : 'You said: ') +
              (typeof value === 'string' ? value.replace(/\*/g, '') : 'Unknown')
            : undefined
        }
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        extraMargin={extraMargin}
        isAlignStart={isAlignStart}
        sameSideAsPrevious={sameSideAsPrevious}
        style={bubbleStyle}
        text={value}
      />
    );
  },
);

function InnerAnimating({ children }: { children: ReactNode }) {
  const [preTransition, setPreTransition] = useState(true);
  const animationRef = useRef(new Animated.Value(1));

  useEffect(() => {
    setPreTransition(false);
    Animated.timing(animationRef.current, {
      toValue: 0,
      useNativeDriver: true,
      easing: Easing.linear,
      duration: 300,
    }).start();
  }, []);

  return (
    <Animated.View
      style={{
        transform: [
          {
            translateY: animationRef.current.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 100],
            }),
          },
        ],
      }}
    >
      {preTransition ? null : children}
    </Animated.View>
  );
}

export function AnimatingChatText({
  actor,
  children,
  sameSideAsPrevious,
}: {
  actor: Actor;
  sameSideAsPrevious: boolean;
  children: ReactNode;
}) {
  const [showIndicator, setShowIndicator] = useState(actor === Actor.Bot);
  const [showContent, setShowContent] = useState(!showIndicator);
  const indicatorOpacity = useRef(new Animated.Value(1));

  useEffect(() => {
    let timeout = 0 as any as NodeJS.Timeout;
    if (!showContent) {
      timeout = setTimeout(() => {
        setShowContent(true);
        Animated.timing(indicatorOpacity.current, {
          useNativeDriver: true,
          toValue: 0,
          duration: 400,
        }).start(() => {
          setShowIndicator(false);
        });
      }, 1000);
      return () => clearTimeout(timeout);
    }
    return;
  }, [showContent]);

  return (
    <View
      // OUTER_MIN_HEIGHT was calculated measureing the height of 1 line of text
      // onLayout={({ nativeEvent }) => console.log(nativeEvent.layout)}
      style={{ minHeight: OUTER_MIN_HEIGHT, overflow: 'hidden' }}
    >
      {showIndicator ? (
        <Animated.View
          style={{
            position: showContent ? 'absolute' : 'relative',
            bottom: 0,
            width: '100%',
            opacity: indicatorOpacity.current,
            height: OUTER_MIN_HEIGHT,
            zIndex: 0, // necessary to stack properly on android
          }}
        >
          <ChatText
            value={<ChatTypingIndicator />}
            side={actor === Actor.User ? ChatSide.END : ChatSide.START}
            sameSideAsPrevious={sameSideAsPrevious}
          />
        </Animated.View>
      ) : null}
      {showContent ? <InnerAnimating>{children}</InnerAnimating> : null}
    </View>
  );
}
