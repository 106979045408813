import { useRoute } from '@react-navigation/native';
import { Asset } from 'expo-asset';
import { FunctionComponent, useEffect, useState } from 'react';
import { SvgProps } from 'react-native-svg';

import {
  RoundedSection,
  RoundedSectionTopChild,
} from '@oui/app-core/src/components/RoundedSection';
import { PracticeType, RatingType } from '@oui/lib/src/types/graphql.generated';

import { DiaryTabs } from '@src/components/DiaryTabs';
import { PillGroup } from '@src/components/PillGroup';
import { RatingGraph } from '@src/components/RatingGraph';
import { RelaxInner } from '@src/components/RelaxInner';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { usePracticeRatings } from '@src/hooks/practices';
import { useArtifactRequest } from '@src/hooks/useArtifactResult';
import { checkIfBareAssetExists } from '@src/lib/checkIfBareAssetExists';
import { IntlShape, useI18n } from '@src/lib/i18n';
import { StackScreenProps } from '@src/types';

export type Exercise = {
  slug: string;
  assetName: FunctionComponent<SvgProps>;
  audio: Asset;
  title: string;
  subtitleUri: string;
  largeAssetName?: FunctionComponent<SvgProps>;
  largeAssetBackgroundColor?: string;
};

export function getMindfulnessExercises($t: IntlShape['$t']) {
  const mindfulnessExercises: Array<Exercise> = [
    {
      slug: 'mindfulness-beginning',
      assetName: require('@src/assets/relax/MindfulnessExercise_Beginning.svg').default,
      audio: Asset.fromURI(
        'https://storage.googleapis.com/asset.oui.dev/mindfulness_audio/Mindfulness.mp3',
      ),
      title: $t({ id: 'Relax_exercise_mindfulnessTitle', defaultMessage: 'Mindful beginning' }),
      subtitleUri: 'https://storage.googleapis.com/asset.oui.dev/mindfulness_audio/Mindfulness.vtt',
    },
    {
      slug: 'sitting-and-breathing',
      assetName: require('@src/assets/relax/SittingAndBreathing.svg').default,
      audio: Asset.fromURI(
        'https://storage.googleapis.com/asset.oui.dev/mindfulness_audio/Oui_Relaxation_VO_Sitting_And_Breathing_RC02.mp3',
      ),
      title: $t({
        id: 'Relax_exercise_sittingAndBreathingTitle',
        defaultMessage: 'Sitting and breathing',
      }),
      subtitleUri:
        'https://storage.googleapis.com/asset.oui.dev/mindfulness_audio/Oui_Relaxation_VO_Sitting_And_Breathing_RC02.vtt',
    },
    {
      slug: 'focus-on-sound',
      assetName: require('@src/assets/relax/FocusOnSound.svg').default,
      audio: Asset.fromURI(
        'https://storage.googleapis.com/asset.oui.dev/mindfulness_audio/Oui_Relaxation_VO_Focus_On_Sounds_RC01.mp3',
      ),
      title: $t({ id: 'Relax_exercise_soundTitle', defaultMessage: 'Mindfulness of sounds' }),
      subtitleUri:
        'https://storage.googleapis.com/asset.oui.dev/mindfulness_audio/Oui_Relaxation_VO_Focus_On_Sounds_RC01.vtt',
    },
    {
      slug: 'focus-on-body',
      assetName: require('@src/assets/relax/FocusOnBody.svg').default,
      audio: Asset.fromURI(
        'https://storage.googleapis.com/asset.oui.dev/mindfulness_audio/Oui_Relaxation_VO_Focus_On_Your_Body_RC01.mp3',
      ),
      title: $t({ id: 'Relax_exercise_bodyTitle', defaultMessage: 'Breathing space' }),
      subtitleUri:
        'https://storage.googleapis.com/asset.oui.dev/mindfulness_audio/Oui_Relaxation_VO_Focus_On_Your_Body_RC01.vtt',
    },
    {
      slug: 'focus-on-breath',
      assetName: require('@src/assets/relax/FocusOnBreath.svg').default,
      audio: Asset.fromURI(
        'https://storage.googleapis.com/asset.oui.dev/mindfulness_audio/Oui_Relaxation_VO_Focus_On_Breath_RC01.mp3',
      ),
      title: $t({ id: 'Relax_exercise_breathTitle', defaultMessage: 'Notice your breathing' }),
      subtitleUri:
        'https://storage.googleapis.com/asset.oui.dev/mindfulness_audio/Oui_Relaxation_VO_Focus_On_Breath_RC01.vtt',
    },
  ];
  return mindfulnessExercises;
}
const preloadExercises = async ($t: IntlShape['$t']) => {
  for (let exercise of getMindfulnessExercises($t)) {
    const exists = await checkIfBareAssetExists(exercise.audio);
    if (!exists) {
      await exercise.audio.downloadAsync();
    }
  }
};

export function Relaxation() {
  const route = useRoute<StackScreenProps<'Relaxation'>['route']>();
  const [hasStartedExercise, setHasStartedExercise] = useState(false);
  const [activeTab, setActiveTab] = useState<'exercise' | 'rating'>('exercise');
  const { $t } = useI18n();

  const [timeScale, setTimeScale] = useState<'WEEK' | 'MONTH' | 'YEAR'>('WEEK');
  const payload = usePracticeRatings({
    practiceType: PracticeType.RELAX_DIARY_ENTRY,
    ratingType: RatingType.RATING_BEFORE,
    timeScale,
  });
  const afterPayload = usePracticeRatings({
    practiceType: PracticeType.RELAX_DIARY_ENTRY,
    ratingType: RatingType.RATING_AFTER,
    timeScale,
  });

  useEffect(() => {
    preloadExercises($t);
  }, [$t]);

  const beforeRefetch = payload.refetch;
  const afterRefetch = afterPayload.refetch;
  useEffect(() => {
    if (activeTab === 'rating') {
      afterRefetch();
      beforeRefetch();
    }
  }, [activeTab, afterRefetch, beforeRefetch]);

  useArtifactRequest(route.name, hasStartedExercise);

  return (
    <RoundedSection
      color="#ebf1ed"
      secondaryColor="white"
      title={$t({ id: 'Relax_title', defaultMessage: 'Relax' })}
      testID="Relax_scrollView"
    >
      <DiaryTabs
        testID="Relax_tabs"
        value={activeTab}
        onChangeValue={setActiveTab}
        items={[
          {
            value: 'exercise',
            text: $t({ id: 'Relax_exercisesTab', defaultMessage: 'Exercises' }),
            icon: 'relax',
          },
          {
            value: 'rating',
            text: $t({ id: 'Relax_ratingsTab', defaultMessage: 'Mood Ratings' }),
            icon: 'chart',
          },
        ]}
        topOfRoundedSection
      />
      {activeTab === 'exercise' ? (
        <View style={{ marginHorizontal: -20 }}>
          <Text
            text={$t({ id: 'Relax_exercisesHeader', defaultMessage: 'Breathe' })}
            size={17}
            weight="semibold"
            textAlign="center"
            accessibilityRole="header"
            style={{ marginTop: 10, marginBottom: 40 }}
          />
          <RelaxInner onStartExercise={() => setHasStartedExercise(true)} />
        </View>
      ) : (
        <RoundedSectionTopChild backgroundColor="transparent">
          <PillGroup
            accessibilityLabel={$t({
              id: 'Relax_ratingTimescaleAccessibilityLabel',
              defaultMessage: 'Chart time scale',
            })}
            value={timeScale}
            onChangeValue={setTimeScale}
            items={[
              {
                label: $t({ id: 'Relax_ratingWeekLabel', defaultMessage: 'Week' }),
                value: 'WEEK',
              },
              {
                label: $t({ id: 'Relax_ratingMonthLabel', defaultMessage: 'Month' }),
                value: 'MONTH',
              },
              {
                label: $t({ id: 'Relax_ratingYearLabel', defaultMessage: 'Year' }),
                value: 'YEAR',
              },
            ]}
          />
          <View style={{ height: 300, marginTop: 20 }}>
            <RatingGraph
              accessibilityLabel={$t({
                id: 'Relax_ratingGraphAccessibilityLabel',
                defaultMessage:
                  'A graph of mood ratings before and after relaxation and mindfulness exercises.',
              })}
              emptyText={$t({
                id: 'Relax_ratingGraphNoData',
                defaultMessage: 'Finish an exercise to see ratings here',
              })}
              showDots={timeScale === 'WEEK'}
              xLabels={payload.xLabels}
              ratings={[payload.data, afterPayload.data]}
              legend={[
                {
                  color: '#adc8b3',
                  text: $t({ id: 'Relax_ratingGraph_legendBefore', defaultMessage: 'Before' }),
                },
                {
                  color: '#516956',
                  text: $t({ id: 'Relax_ratingGraph_legendAfter', defaultMessage: 'After' }),
                },
              ]}
              yAxisLabel={$t({
                id: 'Relax_ratingGraph_yAxisLabel',
                defaultMessage: 'Mood ratings',
              })}
              xAxisLabel={payload.xAxisLabel}
            />
          </View>
        </RoundedSectionTopChild>
      )}
    </RoundedSection>
  );
}
