// This file is automatically generated. See gqlcodegen.yml for details
export const namedAvivaOperations = {
  Query: {
    DevMenu: 'DevMenu',
    MinimumAppVersion: 'MinimumAppVersion',
    PlacesTypeaheadAutocomplete: 'PlacesTypeaheadAutocomplete',
    PlacesTypeaheadDetails: 'PlacesTypeaheadDetails',
    DetoxAdminOrganization: 'DetoxAdminOrganization',
    PracticeRatings: 'PracticeRatings',
    ActionCounts: 'ActionCounts',
    ActivityDiaryEntry: 'ActivityDiaryEntry',
    ActivityDiaryEntries: 'ActivityDiaryEntries',
    SleepDiaryEntryByDate: 'SleepDiaryEntryByDate',
    SleepDiaryEntry: 'SleepDiaryEntry',
    SleepDiaryEntries: 'SleepDiaryEntries',
    ThoughtDiaryEntry: 'ThoughtDiaryEntry',
    ThoughtDiaryEntries: 'ThoughtDiaryEntries',
    RelaxDiaryEntryCounts: 'RelaxDiaryEntryCounts',
    CompositionByID: 'CompositionByID',
    Compositions: 'Compositions',
    CurrentUser: 'CurrentUser',
    SelfPatient: 'SelfPatient',
    Organizations: 'Organizations',
    TreatmentList: 'TreatmentList',
    GetResponse: 'GetResponse',
    KVResponse: 'KVResponse',
    Learn: 'Learn',
    CompletedQuizSets: 'CompletedQuizSets',
    QuizSetCollection: 'QuizSetCollection',
    CMSCopySessionData: 'CMSCopySessionData',
    PatientDevices: 'PatientDevices',
    PatientNotifications: 'PatientNotifications',
    PatientEmails: 'PatientEmails',
    PatientCaringContacts: 'PatientCaringContacts',
    PatientCareTeam: 'PatientCareTeam',
    PatientSupporters: 'PatientSupporters',
    SessionVideos: 'SessionVideos',
    Apps: 'Apps',
    AppByID: 'AppByID',
    CMSIllustrations: 'CMSIllustrations',
    CMSLogos: 'CMSLogos',
    AccountSettings: 'AccountSettings',
    VariantSessionGroups: 'VariantSessionGroups',
    SessionGroupByID: 'SessionGroupByID',
    currentUserId: 'currentUserId',
    HomeFeedV2: 'HomeFeedV2',
    HopeKit: 'HopeKit',
    PatientForMyStoryMyPlan: 'PatientForMyStoryMyPlan',
    AdminOrganization: 'AdminOrganization',
    OrganizationsList: 'OrganizationsList',
    Patient: 'Patient',
    PatientsList: 'PatientsList',
    SuicidalMode: 'SuicidalMode',
    TrialAssignments: 'TrialAssignments',
    UserProfile: 'UserProfile',
    ExchangeActivationToken: 'ExchangeActivationToken',
    CMSSessions: 'CMSSessions',
    CMSVariantByID: 'CMSVariantByID',
    CMSSessionByID: 'CMSSessionByID',
    CMSFileUploadByID: 'CMSFileUploadByID',
    CMSQuizSetCollections: 'CMSQuizSetCollections',
    CMSQuizSetCollectionByID: 'CMSQuizSetCollectionByID'
  },
  Mutation: {
    DetoxAddPatient: 'DetoxAddPatient',
    DetoxIssueActivationToken: 'DetoxIssueActivationToken',
    DetoxIssuePractitionerToken: 'DetoxIssuePractitionerToken',
    DetoxAddPractitioner: 'DetoxAddPractitioner',
    AddAction: 'AddAction',
    AddActivityPractice: 'AddActivityPractice',
    UpdateActivityPractice: 'UpdateActivityPractice',
    DeleteActivityPractice: 'DeleteActivityPractice',
    AddSleepDiaryEntry: 'AddSleepDiaryEntry',
    UpdateSleepDiaryEntry: 'UpdateSleepDiaryEntry',
    AddThoughtDiaryEntry: 'AddThoughtDiaryEntry',
    UpdateThoughtDiaryEntry: 'UpdateThoughtDiaryEntry',
    DeleteThoughtDiaryEntry: 'DeleteThoughtDiaryEntry',
    AddRelaxDiaryEntry: 'AddRelaxDiaryEntry',
    UpdateRelaxDiaryEntry: 'UpdateRelaxDiaryEntry',
    NewCompositionWithTemplate: 'NewCompositionWithTemplate',
    UpdateCompositionSection: 'UpdateCompositionSection',
    SetMyStoryMyPlanComplete: 'SetMyStoryMyPlanComplete',
    Logout: 'Logout',
    Login: 'Login',
    Reauthenticate: 'Reauthenticate',
    FabricateStateV2End: 'FabricateStateV2End',
    SetPushToken: 'SetPushToken',
    SaveResponse: 'SaveResponse',
    KVRespond: 'KVRespond',
    CreateTestUser: 'CreateTestUser',
    CompleteQuizSet: 'CompleteQuizSet',
    RequestResetPassword: 'RequestResetPassword',
    ResetPassword: 'ResetPassword',
    ClaimSignupTokenFromActivationCode: 'ClaimSignupTokenFromActivationCode',
    CMSCopySession: 'CMSCopySession',
    ChatInputMediaPickerUpload: 'ChatInputMediaPickerUpload',
    AddPractitionerToCareTeam: 'AddPractitionerToCareTeam',
    RemovePractitionerFromCareTeam: 'RemovePractitionerFromCareTeam',
    SetCareTeamPrimaryPractitioner: 'SetCareTeamPrimaryPractitioner',
    UpdatePatient: 'UpdatePatient',
    AddContactPointForPatient: 'AddContactPointForPatient',
    AddAddressForPatient: 'AddAddressForPatient',
    AddPatientSupporter: 'AddPatientSupporter',
    IssueTokenLinkForSupporter: 'IssueTokenLinkForSupporter',
    ReviewPatientSupporter: 'ReviewPatientSupporter',
    MarkAsComplete: 'MarkAsComplete',
    CreateCMSApp: 'CreateCMSApp',
    CreateCMSVariant: 'CreateCMSVariant',
    UpdateCMSApp: 'UpdateCMSApp',
    UpdateCMSVariant: 'UpdateCMSVariant',
    CreateFileUpload: 'CreateFileUpload',
    CompleteFileUploadAction: 'CompleteFileUploadAction',
    SaveAccountSettings: 'SaveAccountSettings',
    AddHopeKitImage: 'AddHopeKitImage',
    AddHopeKitVideo: 'AddHopeKitVideo',
    AddHopeKitQuote: 'AddHopeKitQuote',
    CreateCMSSessionGroup: 'CreateCMSSessionGroup',
    UpdateSessionGroup: 'UpdateSessionGroup',
    DeleteSessionGroup: 'DeleteSessionGroup',
    UpdateOrganization: 'UpdateOrganization',
    FinishPatientRegistration: 'FinishPatientRegistration',
    SetLocaleAndTimezone: 'SetLocaleAndTimezone',
    UpdateHopeKitImage: 'UpdateHopeKitImage',
    UpdateHopeKitVideo: 'UpdateHopeKitVideo',
    UpdateHopeKitQuote: 'UpdateHopeKitQuote',
    RemoveHopeKitItem: 'RemoveHopeKitItem',
    AddOrganization: 'AddOrganization',
    AddOrganizationMember: 'AddOrganizationMember',
    UpdateOrganizationMember: 'UpdateOrganizationMember',
    ReplacePrimaryPractitioner: 'ReplacePrimaryPractitioner',
    IssueActivationToken: 'IssueActivationToken',
    AddPatient: 'AddPatient',
    UploadAvatar: 'UploadAvatar',
    AddCMSSession: 'AddCMSSession',
    UpdateCMSSession: 'UpdateCMSSession',
    PatchExchange: 'PatchExchange',
    DeleteSession: 'DeleteSession',
    CreateQuizSetCollection: 'CreateQuizSetCollection',
    UpdateQuizSetCollection: 'UpdateQuizSetCollection',
    CreateQuizSet: 'CreateQuizSet',
    UpdateQuizSet: 'UpdateQuizSet',
    QuizSetItemVideoCreate: 'QuizSetItemVideoCreate',
    QuizSetItemChoiceCreate: 'QuizSetItemChoiceCreate',
    QuizSetItemChoiceUpdate: 'QuizSetItemChoiceUpdate',
    QuizSetItemUpdateOrder: 'QuizSetItemUpdateOrder',
    QuizSetItemDelete: 'QuizSetItemDelete',
    SnapshotSession: 'SnapshotSession',
    CopyQuizSetToCollection: 'CopyQuizSetToCollection',
    DeleteQuizSet: 'DeleteQuizSet'
  },
  Fragment: {
    SleepDiaryEntry: 'SleepDiaryEntry',
    ThoughtDiaryEntryFragment: 'ThoughtDiaryEntryFragment',
    ActivityFragment: 'ActivityFragment',
    RelaxDiaryEntryFragment: 'RelaxDiaryEntryFragment',
    PracticeFragment: 'PracticeFragment',
    CompositionSectionFields: 'CompositionSectionFields',
    CompositionFields: 'CompositionFields',
    CommonUser: 'CommonUser',
    CurrentOuiUser: 'CurrentOuiUser',
    PatientProfileAdmin: 'PatientProfileAdmin',
    CareTeamFragment: 'CareTeamFragment',
    QuizSet: 'QuizSet'
  }
}