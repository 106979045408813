import { ComponentProps, useCallback, useState } from 'react';
import { Image } from 'react-native';
import { v4 } from 'uuid';

import { HealthOui } from '@oui/lib/src/types/avro/socialDistractions';

import {
  usePlacesTypeaheadAutocompleteLazyQuery,
  usePlacesTypeaheadDetailsLazyQuery,
} from '@src/components/PlacesTypeahead.graphql.generated';
import { Text } from '@src/components/Text';
import { TypeaheadInput } from '@src/components/TypeaheadInput';
import { View } from '@src/components/View';
import { useTheme } from '@src/styles';

type Place = HealthOui.SocialDistractionPlace;

export function PlacesTypeahead({
  attribution = true,
  onSelect,
  location,
  ...props
}: {
  attribution?: boolean;
  onSelect: (place: Place) => void;
  testID?: string;
  location?: { lat: number; lng: number };
} & Omit<ComponentProps<typeof TypeaheadInput>, 'onSelect' | 'onSearch' | 'renderItem'>) {
  const [sessionToken, setSessionToken] = useState(() => v4());
  const [fetchAutocomplete] = usePlacesTypeaheadAutocompleteLazyQuery();
  const [fetchDetails] = usePlacesTypeaheadDetailsLazyQuery();

  const { Color } = useTheme();
  const onSearch = useCallback(
    async (q: string) => {
      if (!q) return [];
      const results = await fetchAutocomplete({
        variables: { query: q, sessionToken, latlng: location },
      });
      return (
        results.data?.placeAutocomplete.map((r) => ({
          place: r,
          value: r.placeId,
        })) ?? []
      );
    },
    [fetchAutocomplete, sessionToken, location],
  );

  return (
    <TypeaheadInput
      attribution={
        attribution ? (
          <Image
            source={require('../assets/powered_by_google_on_white.png')}
            style={{ width: '100%', height: 20 }}
            resizeMode="center"
          />
        ) : null
      }
      onSelect={(o) => {
        let token = sessionToken;
        // reset sessionToken since it is only valid through autocompletion and a single details request
        // https://developers.google.com/maps/documentation/places/web-service/details#sessiontoken
        setSessionToken(v4());
        fetchDetails({ variables: { placeId: o.value, sessionToken: token } }).then(({ data }) => {
          if (data?.placeDetails) {
            const details = data.placeDetails;
            onSelect({
              name: o.place.structuredFormatting.mainText ?? details.name,
              ID: details.placeId,
              latitude: details.geometry.location.lat,
              longitude: details.geometry.location.lng,
            });
          }
        });
      }}
      onSearch={onSearch}
      renderItem={(option) => {
        return (
          <View key={option.value} style={{ padding: 10 }} row spacing={8}>
            <Text text={option.place.structuredFormatting.mainText} />
            <Text
              text={option.place.structuredFormatting.secondaryText ?? ''}
              numberOfLines={1}
              size={11}
              color={Color.styleGuide.Gray4}
              style={{ flex: 1 }}
            />
          </View>
        );
      }}
      {...props}
    />
  );
}
