import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import omit from 'lodash/omit';
import { ComponentProps, memo, useCallback, useContext, useEffect, useState } from 'react';
import { Image, Platform, StyleSheet } from 'react-native';

import { FullscreenMediaPlayer } from '@oui/app-core/src/components/MediaPlayer';
import { RoundedSection } from '@oui/app-core/src/components/RoundedSection';

import HopeKitCreate from '@src/assets/hopeKitCreate.svg';
import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { Button } from '@src/components/Button';
import { ConfirmationModal } from '@src/components/ConfirmationModal';
import { OverflowMenu, OverflowMenuOption } from '@src/components/OverflowMenu';
import { ScrollView } from '@src/components/ScrollView';
import { Swiper } from '@src/components/Swiper';
import { Text } from '@src/components/Text';
import { TextInput } from '@src/components/TextInput';
import { View } from '@src/components/View';
import { Environment, environment } from '@src/constants';
import { useAddAction } from '@src/hooks/practices';
import { useArtifactRequest } from '@src/hooks/useArtifactResult';
import { useForm } from '@src/hooks/useForm';
import { useHopeKitName } from '@src/hooks/useHopeKitName';
import { useWindowDimensions } from '@src/hooks/useWindowDimensions';
import { getAuthorizationHeader } from '@src/lib/auth';
import { SessionTimeoutNavigatorKeyboardAvoidingViewContext } from '@src/lib/createSessionTimeoutNavigator';
import { useI18n } from '@src/lib/i18n';
import { resumableUploadManager } from '@src/lib/resumableUploadManager';
import { QuoteBox, SwiperItemType } from '@src/screens/AddHopeKit';
import {
  HopeKitQuery,
  useHopeKitQuery,
  useRemoveHopeKitItemMutation,
  useUpdateHopeKitImageMutation,
  useUpdateHopeKitQuoteMutation,
  useUpdateHopeKitVideoMutation,
} from '@src/screens/HopeKit.graphql.generated';
import Sentry from '@src/sentry';
import { useTheme } from '@src/styles';
import { ActionType, StackScreenProps } from '@src/types';
import { namedAvivaOperations } from '@src/types/namedOperations.generated';

function WebImageWithHeaders(props: ComponentProps<typeof Image>) {
  const [source, setSource] = useState<typeof props.source>({ uri: undefined });

  useEffect(() => {
    async function followRedirect() {
      if (typeof props.source === 'object' && 'uri' in props.source) {
        if (props.source.uri?.includes('/hopeKit/')) {
          const result = await fetch(props.source.uri, {
            headers: props.source.headers,
          });
          const uri = URL.createObjectURL(await result.blob());
          return { uri };
        }
      }
      return props.source;
    }
    followRedirect().then(setSource);
  }, [props.source]);

  return <Image {...props} source={source} />;
}

const HopeKitItem = memo(
  ({
    testID,
    item,
    onDelete,
  }: {
    testID: string;
    item: HopeKitQuery['hopeKitItems'][number];
    onDelete: (id: HopeKitQuery['hopeKitItems'][number]) => void;
  }) => {
    const [fallbackUri, setFallbackUri] = useState<string>();
    const { width } = useWindowDimensions();
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [updateHopeKitImage] = useUpdateHopeKitImageMutation();
    const [updateHopeKitVideo] = useUpdateHopeKitVideoMutation();
    const [updateHopeKitQuote] = useUpdateHopeKitQuoteMutation();
    const { bind, data } = useForm<typeof item>(item);
    const { $t } = useI18n();

    useEffect(() => {
      const pendingItem = resumableUploadManager.getPendingUploadByCacheKey<SwiperItemType>(
        item.hopeKitItemID,
      );
      if (pendingItem?.metaData) {
        switch (pendingItem.metaData.type) {
          case 'asset': {
            let uri = pendingItem.metaData.value.uri;
            if (uri.startsWith('ph://')) {
              if (pendingItem.metaData.value.localUri) {
                uri = pendingItem.metaData.value.localUri;
              } else {
                Sentry.captureMessage('unable to fallback for HopeKitItem due to ph:// url', {
                  extra: {
                    hopeKitItemID: item.hopeKitItemID,
                    uri,
                    value: pendingItem.metaData.value,
                  },
                });
                break;
              }
            }
            setFallbackUri(uri);
            break;
          }
          case 'imageSearch': {
            setFallbackUri(
              pendingItem.metaData.value.contentUrl ?? pendingItem.metaData.value.thumbnailUrl,
            );
            break;
          }
        }
      }
    }, [item.hopeKitItemID]);

    function save() {
      switch (data.__typename) {
        case 'HopeKitQuote': {
          return updateHopeKitQuote({
            variables: {
              input: omit(data, ['__typename']),
            },
          });
        }
        case 'HopeKitImage': {
          return updateHopeKitImage({
            variables: {
              input: omit(data, ['__typename', 'staticUrl']),
            },
          });
        }
        case 'HopeKitVideo': {
          return updateHopeKitVideo({
            variables: {
              input: omit(data, ['__typename', 'staticUrl']),
            },
          });
        }
      }
    }

    const menu = (
      <OverflowMenu triggerTestID={`${testID}_moreButton`}>
        <OverflowMenuOption
          icon="edit"
          text={$t({ id: 'HopeKitItem_editButton', defaultMessage: 'Edit' })}
          onPress={() => setIsEditing(true)}
          testID={`${testID}_editButton`}
        />
        <OverflowMenuOption
          icon="bin"
          text={$t({ id: 'HopeKitItem_deleteButton', defaultMessage: 'Delete' })}
          onPress={() => onDelete(item)}
          testID={`${testID}_deleteButton`}
        />
      </OverflowMenu>
    );

    const ImageComponent = Platform.OS === 'web' ? WebImageWithHeaders : Image;
    return (
      <View spacing={15} testID={testID}>
        {data.__typename === 'HopeKitQuote' ? (
          <QuoteBox
            quote={{ text: data.text, author: data.author ?? '', ID: item.hopeKitItemID }}
            style={{ width: width, height: width }}
          />
        ) : item.__typename === 'HopeKitImage' && (item.staticUrl || fallbackUri) ? (
          <View>
            <ImageComponent
              testID={isLoading ? undefined : 'HopeKitItem_image'}
              source={{
                uri: item.staticUrl ?? fallbackUri,
                headers: {
                  Authorization: `${getAuthorizationHeader()}`,
                },
              }}
              style={{ width: width, height: width }}
              resizeMode="cover"
              onLoad={() => setIsLoading(false)}
              onError={(e) => {
                Sentry.addBreadcrumb({
                  message: 'hope-kit-image-error',
                  data: { item, fallbackUri },
                });
                Sentry.captureException(new Error('HopeKitImage failed to load'), {
                  extra: {
                    originalError: e,
                    item,
                    fallbackUri,
                  },
                });
              }}
            />
            {isLoading ? (
              <View
                style={[
                  StyleSheet.absoluteFillObject,
                  { alignItems: 'center', justifyContent: 'center' },
                ]}
              >
                <ActivityIndicator />
              </View>
            ) : null}
          </View>
        ) : item.__typename === 'HopeKitVideo' && (item.staticUrl || fallbackUri) ? (
          <View style={{ width: width, height: width }} testID="HopeKitItem_video">
            <FullscreenMediaPlayer
              uri={item.staticUrl || fallbackUri!}
              previewAspectRatio={1}
              mediaAspectRatio={null}
              headers={{
                Authorization: `${getAuthorizationHeader()}`,
              }}
            />
          </View>
        ) : (
          <View style={{ width: width, height: width }}></View>
        )}
        {isEditing ? (
          <View style={{ paddingHorizontal: 20 }} spacing={12}>
            {item.__typename === 'HopeKitQuote' ? (
              <TextInput
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                {...bind('text' as any, {
                  label: $t({
                    id: 'HopeKitItem_quote_textLabel',
                    defaultMessage: 'Quote',
                  }),
                })}
                multiline
                inputStyle={{ minHeight: 140 }}
                placeholder={$t({
                  id: 'HopeKitItem_quote_textPlaceholder',
                  defaultMessage: 'What is the quote?',
                })}
              />
            ) : null}
            {item.__typename === 'HopeKitQuote' ? (
              <TextInput
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                {...bind('author' as any, {
                  label: $t({
                    id: 'HopeKitItem_quote_authorLabel',
                    defaultMessage: 'Author',
                  }),
                })}
                placeholder={$t({
                  id: 'HopeKitItem_quote_authorPlaceholder',
                  defaultMessage: 'Who said this quote?',
                })}
              />
            ) : null}
            <TextInput
              {...bind('reason', {
                label: $t({
                  id: 'HopeKitItem_quote_reasonLabel',
                  defaultMessage: 'Why does this give me hope?',
                }),
              })}
              multiline
              inputStyle={{ minHeight: 140 }}
              placeholder={$t({
                id: 'HopeKitItem_quote_reasonPlaceholder',
                defaultMessage:
                  'Does this inspire you? Motivate you? Give you a reason to keep living?',
              })}
            />
            <Button
              alignSelf="center"
              text={$t({ id: 'HopeKitItem_saveButton', defaultMessage: 'Save' })}
              onPress={async () => {
                await save();
                setIsEditing(false);
              }}
              testID="HopeKitItem_saveButton"
            />
          </View>
        ) : (
          <View
            style={{
              paddingHorizontal: 20,
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
            row
            spacing={12}
          >
            {data.reason ? (
              <View spacing={10} style={{ flex: 1 }}>
                <View
                  row
                  style={{
                    alignSelf: 'stretch',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text
                    text={$t({
                      id: 'HopeKitItem_reason',
                      defaultMessage: 'Why this gives me hope?',
                    })}
                    size={15}
                    weight="semibold"
                    style={{ flex: 1 }}
                  />
                  {menu}
                </View>
                <Text text={data.reason} size={17} />
              </View>
            ) : (
              <View />
            )}
            {data.reason ? null : menu}
          </View>
        )}
      </View>
    );
  },
);

export function HopeKit() {
  const { navigate } = useNavigation<StackScreenProps<'HopeKit'>['navigation']>();
  const route = useRoute<StackScreenProps<'HopeKit'>['route']>();
  const { Color } = useTheme();
  const { width } = useWindowDimensions();
  const [deleteConfirmationItem, setDeleteConifrmationItem] = useState<
    (typeof swiperItems)[number] | null
  >(null);
  const { data, loading, refetch } = useHopeKitQuery({
    // Dont cache because asset urls are signed for 15 minutes and will quickly become stale
    // NB If this is not specified, the query flickers b/t loading and not loading for some reason...
    // The root cause appears to be the subscription infinitely running onNewData
    // https://github.com/apollographql/apollo-client/blob/df05ff3895afaee074702c739c84a316a40447c8/src/react/data/QueryData.ts#L279-L294
    fetchPolicy: 'no-cache',
  });
  const [removeHopeKitItem] = useRemoveHopeKitItemMutation();
  const [addAction] = useAddAction();
  const { $t } = useI18n();
  const hopeKitName = useHopeKitName();

  const swiperItems: Array<HopeKitQuery['hopeKitItems'][number]> = [
    ...(data?.hopeKitItems ?? []),
  ].sort((a, b) => {
    return a.hopeKitItemID < b.hopeKitItemID ? -1 : 1;
  });
  const isEmpty = swiperItems.length === 0;
  const { setEnabled } = useContext(SessionTimeoutNavigatorKeyboardAvoidingViewContext);

  useFocusEffect(
    useCallback(() => {
      try {
        if (!loading) {
          refetch();
        }
      } catch (e) {
        // HMR error in dev
        if (environment !== Environment.DEVELOPMENT) {
          Sentry.captureException(e);
        }
      }
      setEnabled(false);
      return () => setEnabled(true);
    }, [loading, setEnabled, refetch]),
  );

  useEffect(() => {
    if (!isEmpty) {
      addAction({
        actionType: ActionType.HOPE_KIT_REVIEW,
      });
    }
  }, [isEmpty, addAction]);

  useArtifactRequest(route.name, !isEmpty);

  return (
    <RoundedSection
      testID="HopeKit"
      color={'white'}
      secondaryColor={Color.grayBackground}
      title={hopeKitName}
      preview={false}
      noScrollView
    >
      {isEmpty ? (
        <View style={{ paddingTop: 20, marginHorizontal: -20 }}>
          <View style={{ backgroundColor: 'white', aspectRatio: 1 }}>
            <HopeKitCreate
              preserveAspectRatio="xMinYMin"
              width="100%"
              height="100%"
              accessibilityRole="none"
              accessibilityLabel={undefined}
            />
          </View>
          {loading ? (
            <View
              style={[
                StyleSheet.absoluteFillObject,
                { alignItems: 'center', justifyContent: 'center' },
              ]}
            >
              <ActivityIndicator />
            </View>
          ) : (
            <View
              style={{ position: 'absolute', top: '45%', left: 0, right: 0, alignItems: 'center' }}
              spacing={12}
            >
              <Text
                text={$t(
                  { id: 'HopeKit_emptyHeader', defaultMessage: 'Create your {hopeKitName}' },
                  { hopeKitName },
                )}
                size={21}
                weight="semibold"
              />
              <Button
                text={$t({ id: 'HopeKit_addEmptyButton', defaultMessage: 'Add' })}
                icon="plus"
                onPress={() => navigate('AddHopeKit', {})}
                testID="HopeKit_addEmptyButton"
              />
            </View>
          )}
        </View>
      ) : (
        <View style={{ flex: 1, marginTop: -10, marginHorizontal: -20 }}>
          <View style={{ paddingHorizontal: 20 }}>
            <Button
              variant="text"
              text={$t({ id: 'HopeKit_addMoreButton', defaultMessage: 'Add' })}
              icon="plus"
              onPress={() => navigate('AddHopeKit', {})}
              testID="HopeKit_addMoreButton"
            />
          </View>
          <Swiper
            indicator="overlay"
            width={width}
            data={swiperItems}
            renderItem={({ item, index: i }) => {
              return (
                <ScrollView
                  style={{ paddingBottom: 20 }}
                  extraHeight={280}
                  testID={`HopeKit_item_${i}_scrollView`}
                >
                  <HopeKitItem
                    item={item}
                    onDelete={setDeleteConifrmationItem}
                    testID={`HopeKit_item_${i}`}
                  />
                </ScrollView>
              );
            }}
          />
          {deleteConfirmationItem ? (
            <ConfirmationModal
              visible={!!deleteConfirmationItem}
              onCancel={() => setDeleteConifrmationItem(null)}
              onConfirm={() => {
                return removeHopeKitItem({
                  variables: { input: { hopeKitItemID: deleteConfirmationItem.hopeKitItemID } },
                  refetchQueries: [namedAvivaOperations.Query.HopeKit],
                }).then(() => {
                  setDeleteConifrmationItem(null);
                });
              }}
              cancelText={$t({
                id: 'HopeKit_deleteConfirmation_cancelButton',
                defaultMessage: "No, don't",
              })}
              confirmText={$t({
                id: 'HopeKit_deleteConfirmation_confirmButton',
                defaultMessage: 'Yes, delete',
              })}
              confirmTestID="HopeKit_confirmDeleteButton"
              title={$t({ id: 'HopeKit_deleteConfirmation_title', defaultMessage: 'Delete?' })}
              description={$t(
                {
                  id: 'HopeKit_deleteConfirmation_description',
                  defaultMessage: "Please confirm you'd like to delete this {hopeKitName} item.",
                },
                { hopeKitName },
              )}
            />
          ) : null}
        </View>
      )}
    </RoundedSection>
  );
}
