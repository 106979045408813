// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlacesTypeaheadAutocompleteQueryVariables = Types.Exact<{
  query: Types.Scalars['String']['input'];
  sessionToken: Types.Scalars['String']['input'];
  latlng?: Types.InputMaybe<Types.LatLngInput>;
}>;


export type PlacesTypeaheadAutocompleteQuery = { readonly placeAutocomplete: ReadonlyArray<{ readonly description: string, readonly placeId: string, readonly structuredFormatting: { readonly mainText: string, readonly secondaryText?: string | null } }> };

export type PlacesTypeaheadDetailsQueryVariables = Types.Exact<{
  placeId: Types.Scalars['String']['input'];
  sessionToken: Types.Scalars['String']['input'];
}>;


export type PlacesTypeaheadDetailsQuery = { readonly placeDetails: { readonly formattedAddress: string, readonly name: string, readonly placeId: string, readonly geometry: { readonly location: { readonly lat: number, readonly lng: number } } } };


export const PlacesTypeaheadAutocompleteDocument = gql`
    query PlacesTypeaheadAutocomplete($query: String!, $sessionToken: String!, $latlng: LatLngInput) {
  placeAutocomplete(latlng: $latlng, query: $query, sessionToken: $sessionToken) {
    description
    structuredFormatting {
      mainText
      secondaryText
    }
    placeId
  }
}
    `;

/**
 * __usePlacesTypeaheadAutocompleteQuery__
 *
 * To run a query within a React component, call `usePlacesTypeaheadAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlacesTypeaheadAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlacesTypeaheadAutocompleteQuery({
 *   variables: {
 *      query: // value for 'query'
 *      sessionToken: // value for 'sessionToken'
 *      latlng: // value for 'latlng'
 *   },
 * });
 */
export function usePlacesTypeaheadAutocompleteQuery(baseOptions: Apollo.QueryHookOptions<PlacesTypeaheadAutocompleteQuery, PlacesTypeaheadAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlacesTypeaheadAutocompleteQuery, PlacesTypeaheadAutocompleteQueryVariables>(PlacesTypeaheadAutocompleteDocument, options);
      }
export function usePlacesTypeaheadAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlacesTypeaheadAutocompleteQuery, PlacesTypeaheadAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlacesTypeaheadAutocompleteQuery, PlacesTypeaheadAutocompleteQueryVariables>(PlacesTypeaheadAutocompleteDocument, options);
        }
export type PlacesTypeaheadAutocompleteQueryHookResult = ReturnType<typeof usePlacesTypeaheadAutocompleteQuery>;
export type PlacesTypeaheadAutocompleteLazyQueryHookResult = ReturnType<typeof usePlacesTypeaheadAutocompleteLazyQuery>;

export function queryPlacesTypeaheadAutocomplete(client: Apollo.ApolloClient<unknown>, variables: PlacesTypeaheadAutocompleteQueryVariables, options?: Omit<Apollo.QueryOptions<PlacesTypeaheadAutocompleteQuery, PlacesTypeaheadAutocompleteQueryVariables>, "query" | 'variables'>) {
  return client.query<PlacesTypeaheadAutocompleteQuery, PlacesTypeaheadAutocompleteQueryVariables>({
    query: PlacesTypeaheadAutocompleteDocument,
    variables,
    ...options,
  });
}

export const PlacesTypeaheadDetailsDocument = gql`
    query PlacesTypeaheadDetails($placeId: String!, $sessionToken: String!) {
  placeDetails(placeId: $placeId, sessionToken: $sessionToken) {
    formattedAddress
    geometry {
      location {
        lat
        lng
      }
    }
    name
    placeId
  }
}
    `;

/**
 * __usePlacesTypeaheadDetailsQuery__
 *
 * To run a query within a React component, call `usePlacesTypeaheadDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlacesTypeaheadDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlacesTypeaheadDetailsQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function usePlacesTypeaheadDetailsQuery(baseOptions: Apollo.QueryHookOptions<PlacesTypeaheadDetailsQuery, PlacesTypeaheadDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlacesTypeaheadDetailsQuery, PlacesTypeaheadDetailsQueryVariables>(PlacesTypeaheadDetailsDocument, options);
      }
export function usePlacesTypeaheadDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlacesTypeaheadDetailsQuery, PlacesTypeaheadDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlacesTypeaheadDetailsQuery, PlacesTypeaheadDetailsQueryVariables>(PlacesTypeaheadDetailsDocument, options);
        }
export type PlacesTypeaheadDetailsQueryHookResult = ReturnType<typeof usePlacesTypeaheadDetailsQuery>;
export type PlacesTypeaheadDetailsLazyQueryHookResult = ReturnType<typeof usePlacesTypeaheadDetailsLazyQuery>;

export function queryPlacesTypeaheadDetails(client: Apollo.ApolloClient<unknown>, variables: PlacesTypeaheadDetailsQueryVariables, options?: Omit<Apollo.QueryOptions<PlacesTypeaheadDetailsQuery, PlacesTypeaheadDetailsQueryVariables>, "query" | 'variables'>) {
  return client.query<PlacesTypeaheadDetailsQuery, PlacesTypeaheadDetailsQueryVariables>({
    query: PlacesTypeaheadDetailsDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







