import { useEffect, useRef } from 'react';
import { Animated, Pressable, StyleSheet } from 'react-native';
import { Circle, CircleProps, Svg } from 'react-native-svg';

import { Icon } from '@src/components/Icon';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useTheme } from '@src/styles';

const OFFWHITE = 'rgba(255,255,255,0.6)';
const BACKGROUND_OFFWHITE = 'rgba(255,255,255,0.15)';
const STEP_SIZE = 34;
const RADIUS = (STEP_SIZE - 2) / 2;
const CIRCUM = RADIUS * 2 * Math.PI;

const AnimatedCircle = Animated.createAnimatedComponent<React.ComponentType<CircleProps>>(Circle);

export function MobileHeaderStep({
  active,
  complete,
  label,
  num,
  numText,
  onPress,
  progress,
}: {
  active: boolean;
  complete: boolean;
  label: string;
  num: number;
  numText?: string;
  onPress?: () => void;
  progress?: number;
}) {
  const strokeDashoffsetRef = useRef(new Animated.Value(progress ?? 1));
  const { Color } = useTheme();
  const textColor = active
    ? progress
      ? 'white'
      : Color.styleGuide.LogoLilac
    : complete
    ? 'white'
    : OFFWHITE;

  const svgProgress = 100 - (progress ?? 1) * 100;

  useEffect(() => {
    const strokeDashoffset = RADIUS * Math.PI * 2 * (svgProgress / 100);
    Animated.timing(strokeDashoffsetRef.current, {
      toValue: strokeDashoffset,
      duration: 200,
      useNativeDriver: true,
    }).start();
  }, [svgProgress]);

  return (
    <Pressable
      onPress={onPress}
      style={{ flex: 1, alignItems: 'center' }}
      accessible
      accessibilityState={{ checked: complete ? true : undefined, selected: active }}
    >
      <View
        style={{
          height: 2,
          position: 'absolute',
          top: 34 / 2,
          backgroundColor: complete ? 'white' : OFFWHITE,
          left: '50%',
          right: num === 3 ? '50%' : 0,
        }}
      />
      <View
        style={{
          height: 2,
          position: 'absolute',
          top: 34 / 2,
          backgroundColor: complete || active ? 'white' : OFFWHITE,
          left: num === 1 ? '50%' : 0,
          right: '50%',
        }}
      />
      <View
        style={{
          backgroundColor: Color.styleGuide.LogoLilac,
        }}
      >
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: STEP_SIZE,
            height: STEP_SIZE,
            borderRadius: 18,
            backgroundColor: active
              ? progress
                ? BACKGROUND_OFFWHITE
                : 'white'
              : Color.styleGuide.LogoLilac,
          }}
        >
          {!active && complete ? (
            <Icon name="check" color={textColor} />
          ) : (
            <Text
              text={numText ?? num.toString()}
              color={textColor}
              weight="bold"
              lineHeight={STEP_SIZE}
            />
          )}
          <View style={StyleSheet.absoluteFillObject}>
            <Svg viewBox="0 0 34 34">
              <AnimatedCircle
                stroke={complete || active || progress ? 'white' : OFFWHITE}
                fill="none"
                cx={STEP_SIZE / 2}
                cy={STEP_SIZE / 2}
                r={RADIUS}
                strokeDasharray={`${CIRCUM} ${CIRCUM}`}
                strokeDashoffset={strokeDashoffsetRef.current}
                strokeLinecap="round"
                transform={`rotate(-90, ${STEP_SIZE / 2}, ${STEP_SIZE / 2})`}
                strokeWidth="2"
              />
            </Svg>
          </View>
        </View>
      </View>
      <Text
        text={label}
        color={active || complete ? 'white' : OFFWHITE}
        size={15}
        weight="semibold"
      />
    </Pressable>
  );
}
