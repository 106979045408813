import ReactDatePicker from 'react-datepicker';

import { Button } from '@src/components/Button';
import { View } from '@src/components/View';

export function WebDatePickerInner(props: {
  date: Date;
  minuteInterval: number;
  mode: 'date' | 'datetime' | 'time';
  onCancel: () => void;
  onConfirm?: () => void;
  onDateChange: (date: Date) => void;
}) {
  return (
    <View style={{ alignItems: 'center' }} testID="DateTimePicker">
      <ReactDatePicker
        fixedHeight
        inline
        onChange={props.onDateChange}
        selected={props.date}
        showTimeSelect={props.mode === 'datetime' || props.mode === 'time'}
        showTimeSelectOnly={props.mode === 'time'}
        timeIntervals={props.minuteInterval}
        showMonthDropdown={true}
        showYearDropdown={true}
        dropdownMode="select"
      />
      {global.e2e ? (
        <Button
          // Not used in V2 e2e tests
          text="Confirm"
          onPress={() => props.onDateChange(new Date())}
        />
      ) : null}
    </View>
  );
}
