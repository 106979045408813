import { useNavigation, useRoute } from '@react-navigation/native';
import parseISO from 'date-fns/parseISO';
import { LinearGradient } from 'expo-linear-gradient';
import { memo, useCallback, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import {
  RoundedSection,
  RoundedSectionTopChild,
} from '@oui/app-core/src/components/RoundedSection';
import { PracticeType, RatingType } from '@oui/lib/src/types/graphql.generated';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { Button } from '@src/components/Button';
import ChatTable from '@src/components/ChatTable';
import { Icon } from '@src/components/Icon';
import { PillGroup } from '@src/components/PillGroup';
import { RatingGraph } from '@src/components/RatingGraph';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { usePracticeRatings } from '@src/hooks/practices';
import {
  ActivityDiaryEntriesQuery,
  ActivityFragmentFragment,
  PracticeFragment_ActivityPractice_Fragment,
  useActivityDiaryEntriesQuery,
} from '@src/hooks/practices.graphql.generated';
import { useArtifactRequest } from '@src/hooks/useArtifactResult';
import { useI18n } from '@src/lib/i18n';
import { useTheme } from '@src/styles';
import { StackScreenProps } from '@src/types';

const LinearGradientMemo = memo(() => (
  <LinearGradient
    colors={['rgba(241, 248, 249, 0)', '#BEF1F2']}
    style={StyleSheet.absoluteFillObject}
    start={[0, 0]}
    end={[0, 1]}
  />
));

function ActivityList({ loading, data }: { loading: boolean; data?: ActivityDiaryEntriesQuery }) {
  const { navigate } = useNavigation<StackScreenProps<'ActivityDiary'>['navigation']>();
  const route = useRoute<StackScreenProps<'ActivityDiary'>['route']>();
  const { Color } = useTheme();
  const { $t, formatDate } = useI18n();

  const getActivityListRow = useCallback(
    ({
      practiceID,
      practiceValues,
      activity,
    }: {
      readonly activity: ActivityFragmentFragment;
    } & PracticeFragment_ActivityPractice_Fragment) => {
      const showRatings = () => {
        navigate('EditActivityPractice', {
          practiceID,
        });
      };

      const showDetails = () => {
        navigate('ActivityPractice', {
          practiceID,
        });
      };

      const isInPast = parseISO(activity.endTime) < new Date();
      return [
        <TouchableOpacity
          key="col0"
          style={{ alignSelf: 'stretch' }}
          accessible={false}
          onPress={showDetails}
          testID="ActivityDiary_row_title"
        >
          <View row>
            <Text
              text={activity.title}
              weight="semibold"
              size={17}
              color={Color.styleGuide.Gray1}
              style={{ flex: 1 }}
            />
            <Icon
              name="caret-right"
              color={Color.accent}
              onPress={showDetails}
              accessibilityLabel="View details"
            />
          </View>
          <Text
            size={15}
            text={formatDate(activity.startTime, { month: 'short', day: 'numeric' })}
            weight="semibold"
            color={Color.styleGuide.Gray3}
          />
        </TouchableOpacity>,
        ...(practiceValues.ratings
          ? [
              <Text
                key="before"
                text={
                  practiceValues.ratings
                    .find((t) => t.type === RatingType.RATING_BEFORE)
                    ?.value.toString()!
                }
                size={17}
                weight="semibold"
              />,
              <Text
                key="after"
                text={
                  practiceValues.ratings
                    .find((t) => t.type === RatingType.RATING_AFTER)
                    ?.value.toString()!
                }
                size={17}
                weight="semibold"
              />,
            ]
          : isInPast
          ? [
              {
                config: {
                  style: { flexBasis: '50%' as const },
                },
                node: (
                  <Button
                    text="Rate"
                    variant="solid"
                    onPress={() => {
                      showRatings();
                    }}
                  />
                ),
              },
            ]
          : [<View key="1" />, <View key="2" />]),
      ];
    },
    [Color, navigate, formatDate],
  );

  const listRowData = (data?.practices ?? []).map((p) => {
    return p.__typename === 'ActivityPractice' ? getActivityListRow(p) : undefined!;
  });

  const isEmpty = listRowData.length === 0;
  useArtifactRequest(route.name, !isEmpty);

  const header = [
    <Text
      key="activity"
      text={$t({ id: 'ActivityDiary_activityLabel', defaultMessage: 'Activity' })}
      weight="semibold"
      color="white"
    />,
    <Text
      key="before"
      text={$t({ id: 'ActivityDiary_moodBeforeLabel', defaultMessage: 'Mood Before' })}
      weight="semibold"
      color="white"
      textAlign="center"
    />,
    <Text
      key="after"
      text={$t({ id: 'ActivityDiary_moodAfterLabel', defaultMessage: 'Mood After' })}
      weight="semibold"
      color="white"
      textAlign="center"
    />,
  ];

  return (
    <View>
      <View
        row
        style={{
          padding: 20,
          justifyContent: 'center',
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        }}
      >
        <Button
          text={$t({ id: 'ActivityDiary_addActivityButton', defaultMessage: 'Add activity' })}
          icon="plus"
          onPress={() => navigate('EditActivityEvent', {})}
          testID="ActivityDiary_addActivityButton"
        />
      </View>
      <View style={{ padding: 20 }}>
        <View style={{ borderWidth: 1, borderRadius: 14, borderColor: Color.styleGuide.Gray6 }}>
          <ChatTable
            rowTestIDPrefix="ActivityDiary_row"
            alignItems={['flex-start', 'center', 'center']}
            flexBasis={['50%', '25%', '25%']}
            data={
              listRowData.length === 0 && loading
                ? [
                    header,
                    [
                      {
                        node: (
                          <View
                            style={{
                              alignSelf: 'stretch',
                              padding: 10,
                              justifyContent: 'center',
                            }}
                          >
                            <ActivityIndicator />
                          </View>
                        ),
                        config: { style: { flexBasis: '100%' } },
                      },
                    ],
                  ]
                : [
                    header,
                    ...(listRowData.length
                      ? listRowData
                      : [
                          [
                            <Text
                              text={$t({
                                id: 'ActivityDiary_tableAddActivityLabel',
                                defaultMessage: 'Add an activity',
                              })}
                              key="Add"
                              color={Color.styleGuide.Gray5}
                              weight="semibold"
                            />,
                            '',
                            '',
                          ],
                        ]),
                  ]
            }
            borderWidth={1}
            borderColor={Color.styleGuide.Gray6}
            headerStyle={{
              backgroundColor: '#1b9fa0',
              paddingHorizontal: 12,
              paddingVertical: 6,
            }}
          />
        </View>
      </View>
    </View>
  );
}

export function ActivityDiary() {
  const { Color } = useTheme();
  const { loading, data } = useActivityDiaryEntriesQuery({
    variables: {},
  });
  const { $t } = useI18n();

  const [timeScale, setTimeScale] = useState<'WEEK' | 'MONTH' | 'YEAR'>('WEEK');
  const payload = usePracticeRatings({
    practiceType: PracticeType.ACTIVITY,
    ratingType: RatingType.RATING_BEFORE,
    timeScale,
  });
  const afterPayload = usePracticeRatings({
    practiceType: PracticeType.ACTIVITY,
    ratingType: RatingType.RATING_AFTER,
    timeScale,
  });

  return (
    <RoundedSection
      color={Color.styleGuide.LogoCyan}
      secondaryColor={'white'}
      title={$t({
        id: 'ActivityDiary_header',
        defaultMessage: 'Activity Diary',
      })}
      preview={false}
      testID="ActivityDiary_scrollView"
    >
      <RoundedSectionTopChild backgroundColor="transparent">
        <LinearGradientMemo />
        <PillGroup
          testID="ActivityDiary_timeScale"
          accessibilityLabel="Chart time scale"
          value={timeScale}
          onChangeValue={setTimeScale}
          items={[
            {
              label: $t({ id: 'ActivityDiary_graphWeekScaleLabel', defaultMessage: 'Week' }),
              value: 'WEEK',
            },
            {
              label: $t({ id: 'ActivityDiary_graphMonthScaleLabel', defaultMessage: 'Month' }),
              value: 'MONTH',
            },
            {
              label: $t({ id: 'ActivityDiary_graphYearScaleLabel', defaultMessage: 'Year' }),
              value: 'YEAR',
            },
          ]}
        />
        <View style={{ height: 300, marginTop: 20 }}>
          <RatingGraph
            testID="ActivityDiary_graph"
            accessibilityLabel={$t({
              id: 'ActivityDiary_graphAccessibilityLabel',
              defaultMessage: 'A graph comparing mood ratings before and after activities.',
            })}
            showDots={timeScale === 'WEEK'}
            xLabels={payload.xLabels}
            ratings={[payload.data, afterPayload.data]}
            legend={[
              {
                color: '#64c1c2',
                text: $t({ id: 'ActivityDiary_graphBeforeLabel', defaultMessage: 'Before' }),
              },
              {
                color: Color.styleGuide.LogoCyan,
                text: $t({ id: 'ActivityDiary_graphAfterLabel', defaultMessage: 'After' }),
              },
            ]}
            yAxisLabel={$t({ id: 'ActivityDiary_graphYAxisLabel', defaultMessage: 'Mood Ratings' })}
            xAxisLabel={payload.xAxisLabel}
          />
        </View>
      </RoundedSectionTopChild>
      <View
        style={{
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          marginTop: -14,
          marginHorizontal: -20,
          backgroundColor: 'white',
        }}
      >
        <ActivityList loading={loading} data={data} />
        <View style={{ width: '100%', height: 50 }} />
      </View>
    </RoundedSection>
  );
}
