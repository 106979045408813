import addMinutes from 'date-fns/addMinutes';
import parseISO from 'date-fns/parseISO';
import startOfHour from 'date-fns/startOfHour';
import omit from 'lodash/omit';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TextInput as RNTextInput, SafeAreaView } from 'react-native';

import { getGQLDate } from '@oui/lib/src/getGQLDate';
import { parseHoursAndMinutes } from '@oui/lib/src/parseHoursAndMinutes';
import { AddActivityPracticeInput } from '@oui/lib/src/types/graphql.generated';
import { GQLDateTime, GQLTime } from '@oui/lib/src/types/scalars';

import { Button } from '@src/components/Button';
import { ConfirmationModal } from '@src/components/ConfirmationModal';
import { DateTimeInput } from '@src/components/DateTimeInput';
import { Icon } from '@src/components/Icon';
import { ScrollView } from '@src/components/ScrollView';
import { Text } from '@src/components/Text';
import { TextInput } from '@src/components/TextInput';
import { View } from '@src/components/View';
import { useActivityPractice } from '@src/hooks/practices';
import {
  useAddActivityPracticeMutation,
  useUpdateActivityPracticeMutation,
} from '@src/hooks/practices.graphql.generated';
import { useCurrentPatientID } from '@src/hooks/useCurrentUser';
import { useForm } from '@src/hooks/useForm';
import { useI18n } from '@src/lib/i18n';
import { Shadow, card, useTheme } from '@src/styles';
import { StackScreenProps } from '@src/types';
import { namedAvivaOperations } from '@src/types/namedOperations.generated';

export function EditActivityEvent(props: StackScreenProps<'EditActivityEvent'>) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [now] = useState(() => new Date());
  const defaultStartTime = startOfHour(addMinutes(now, 60));
  const defaultStartTimeStr = defaultStartTime.toISOString();
  const defaultEndTimeStr = addMinutes(defaultStartTime, 30).toISOString();
  const locationInputRef = useRef<RNTextInput>(null);
  const notesInputRef = useRef<RNTextInput>(null);
  const { practiceID, activityPractice } = useActivityPractice();
  const patientID = useCurrentPatientID();
  const [updateActivityPractice] = useUpdateActivityPracticeMutation();
  const [addActivityPractice] = useAddActivityPracticeMutation();
  const { $t, formatDate } = useI18n();

  const { Color } = useTheme();
  const { dirty, validate, data, bind, errors } = useForm<AddActivityPracticeInput['activity']>({
    endTime: defaultEndTimeStr as GQLDateTime,
    startTime: defaultStartTimeStr as GQLDateTime,
    attendee: '',
    location: '',
    notes: '',
    title: '',
    ...(activityPractice ? omit(activityPractice.activity, ['__typename']) : undefined),
  });

  const setOptions = props.navigation.setOptions;
  const goBack = props.navigation.goBack;
  const navigate = props.navigation.navigate;
  const replace = props.navigation.replace;

  const save = useCallback(
    async function () {
      if (validate()) {
        if (practiceID && activityPractice) {
          return updateActivityPractice({
            variables: {
              input: {
                practiceID,
                practiceValues: {
                  patientID: activityPractice.practiceValues.patient.ID,
                  date: getGQLDate(parseISO(data.startTime)),
                  ratings: activityPractice.practiceValues.ratings,
                },
                activity: data,
              },
            },
          }).then((r) => {
            navigate('ActivityPractice', { practiceID });
          });
        } else {
          return addActivityPractice({
            variables: {
              input: {
                practiceValues: {
                  date: getGQLDate(parseISO(data.startTime)),
                  patientID: patientID!,
                  ratings: [],
                },
                activity: data,
              },
            },
            refetchQueries: [namedAvivaOperations.Query.ActivityDiaryEntries],
          }).then((r) => {
            const entry = r.data?.saveActivityDiaryEntry.activityPractice;
            if (entry) {
              const isInPast = parseISO(entry.activity.endTime) < new Date();
              replace('ActivityPractice', { practiceID: entry.practiceID });
              if (isInPast) {
                navigate('EditActivityPractice', {
                  practiceID: entry.practiceID,
                  fromActivityPractice: true,
                });
              }
            }
          });
        }
      }
      return;
    },
    [
      validate,
      addActivityPractice,
      updateActivityPractice,
      data,
      replace,
      navigate,
      patientID,
      practiceID,
      activityPractice,
    ],
  );

  useEffect(() => {
    setOptions({
      headerLeft: ({ tintColor }) => (
        <Icon
          accessibilityLabel={$t({
            id: 'EditActivityEvent_cancelButton',
            defaultMessage: 'Cancel',
          })}
          style={{ marginLeft: 10 }}
          onPress={() => {
            if (dirty) {
              setShowConfirmationModal(true);
            } else {
              goBack();
            }
          }}
          name="close"
          color={tintColor}
        />
      ),
      headerRight: () => (
        <Button
          onPress={save}
          text={$t({ id: 'EditActivityEvent_saveButton', defaultMessage: 'Save' })}
          style={{ marginRight: 8, paddingVertical: 6, paddingHorizontal: 14 }}
          testID="EditActivityEvent_saveButton"
        />
      ),
      title: practiceID
        ? $t({ id: 'EditActivityEvent_editHeader', defaultMessage: 'Edit Activity' })
        : $t({ id: 'EditActivityEvent_addHeader', defaultMessage: 'Add Activity' }),
    });
  }, [dirty, data, setOptions, goBack, save, practiceID, $t]);

  const { value: startDateTime, onChangeValue: changeStartDateTime } = bind(['startTime'], {
    accessibilityLabel: 'Start on',
  });
  const startDate = getGQLDate(parseISO(startDateTime));
  const startHHMM = formatDate(parseISO(startDateTime), {
    hour: 'numeric',
    minute: '2-digit',
    hourCycle: 'h23',
  }) as GQLTime;
  const { value: endDateTime, onChangeValue: changeEndDateTime } = bind(['endTime'], {
    accessibilityLabel: $t({
      id: 'EditActivityEvent_endTimeAccessibilityLabel',
      defaultMessage: 'End time',
    }),
    validator: () => {
      if (parseISO(data.endTime) < parseISO(data.startTime)) {
        return $t({
          id: 'EditActivityEvent_endTimeValidationError',
          defaultMessage: 'End time cannot be before start time',
        });
      }
      return undefined;
    },
  });

  const endHHMM = formatDate(parseISO(endDateTime), {
    hour: 'numeric',
    minute: '2-digit',
    hourCycle: 'h23',
  }) as GQLTime;

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Color.grayBackground }}>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ paddingHorizontal: 20, paddingVertical: 25 }}
      >
        <View style={[card, { paddingVertical: 20, padding: 14, marginBottom: 20 }, Shadow.high]}>
          <TextInput
            placeholder={$t({
              id: 'EditActivityEvent_titlePlaceholder',
              defaultMessage: 'Name of activity',
            })}
            {...bind(['title'], {
              label: $t({ id: 'EditActivityEvent_titleLabel', defaultMessage: 'Activity' }),
              validator: { type: 'present' },
            })}
          />
          <View style={{ marginTop: 40 }}>
            <DateTimeInput
              mode="date"
              value={startDate}
              onChangeValue={(newDate) => {
                changeStartDateTime(
                  parseHoursAndMinutes(startHHMM, parseISO(newDate)).toISOString() as GQLDateTime,
                );
                changeEndDateTime(
                  parseHoursAndMinutes(endHHMM, parseISO(newDate)).toISOString() as GQLDateTime,
                );
              }}
              label={$t({ id: 'EditActivityEvent_startDate', defaultMessage: 'Date' })}
              testID="EditActivityEvent_startDate"
            />
            <View row spacing={12}>
              <DateTimeInput
                mode="time"
                style={{ flex: 1 }}
                value={startHHMM}
                onChangeValue={(newStartHHMM) => {
                  changeStartDateTime(
                    parseHoursAndMinutes(
                      newStartHHMM,
                      parseISO(startDate),
                    ).toISOString() as GQLDateTime,
                  );
                }}
                accessibilityLabel={$t({
                  id: 'EditActivityEvent_startTimeAccessibilityLabel',
                  defaultMessage: 'Start time',
                })}
                testID="EditActivityEvent_startTime"
              />
              <Text
                text={$t({
                  id: 'EditActivityEvent_durationPrepositionLabel',
                  defaultMessage: 'to',
                })}
                weight="semibold"
              />
              <DateTimeInput
                accessibilityLabel={$t({
                  id: 'EditActivityEvent_endTimeAccessibilityLabel',
                  defaultMessage: 'End time',
                })}
                mode="time"
                style={{ flex: 1 }}
                value={endHHMM}
                onChangeValue={(newEndHHMM) => {
                  changeEndDateTime(
                    parseHoursAndMinutes(
                      newEndHHMM,
                      parseISO(startDate),
                    ).toISOString() as GQLDateTime,
                  );
                }}
                testID="EditActivityEvent_endTime"
              />
            </View>
            {errors?.endTime ? <Text text={errors.endTime} color={Color.error} /> : null}
          </View>
        </View>
        <View spacing={20}>
          <Text
            text={$t({ id: 'EditActivityEvent_optional', defaultMessage: 'Optional' })}
            weight="semibold"
            color={Color.styleGuide.Gray3}
          />
          <View row spacing={16}>
            <Icon name="people" color={Color.styleGuide.Gray5} size={24} />
            <TextInput
              {...bind(['attendee'], {
                accessibilityLabel: $t({
                  id: 'EditActivityEvent_attendeeAccessibilityLabel',
                  defaultMessage: 'Attendees',
                }),
              })}
              style={{ flex: 1 }}
              placeholder={$t({
                id: 'EditActivityEvent_attendeePlaceholder',
                defaultMessage: 'Do activity with',
              })}
              returnKeyType="next"
              onSubmitEditing={() => locationInputRef.current?.focus()}
            />
          </View>
          <View row spacing={16}>
            <Icon name="location" color={Color.styleGuide.Gray5} size={24} />
            <TextInput
              {...bind(['location'], {
                accessibilityLabel: $t({
                  id: 'EditActivityEvent_locationAccessibilityLabel',
                  defaultMessage: 'Location',
                }),
              })}
              style={{ flex: 1 }}
              placeholder={$t({
                id: 'EditActivityEvent_locationPlaceholder',
                defaultMessage: 'Activity location',
              })}
              ref={locationInputRef}
              returnKeyType="next"
              onSubmitEditing={() => notesInputRef.current?.focus()}
            />
          </View>
          <View row spacing={16}>
            <Icon name="sessions" color={Color.styleGuide.Gray5} size={24} />
            <TextInput
              {...bind(['notes'], {
                accessibilityLabel: $t({
                  id: 'EditActivityEvent_notesAccessibilityLabel',
                  defaultMessage: 'Notes',
                }),
              })}
              style={{ flex: 1 }}
              placeholder={$t({
                id: 'EditActivityEvent_notesPlaceholder',
                defaultMessage: 'Things to prepare for activity',
              })}
              multiline
              ref={notesInputRef}
            />
          </View>
        </View>
      </ScrollView>
      <ConfirmationModal
        onCancel={() => {
          goBack();
          setShowConfirmationModal(false);
        }}
        onConfirm={() => {
          return save().then(() => {
            setShowConfirmationModal(false);
          });
        }}
        visible={showConfirmationModal}
        confirmText={$t({
          id: 'EditActivityEvent_confirmationConfirmButton',
          defaultMessage: 'Save',
        })}
        cancelText={$t({
          id: 'EditActivityEvent_confirmationCancelButton',
          defaultMessage: 'Discard',
        })}
        title={$t({ id: 'EditActivityEvent_confirmationTitle', defaultMessage: 'Save changes?' })}
        description={$t({
          id: 'EditActivityEvent_confirmationDescription',
          defaultMessage:
            "You've added info to this activity. Would you like to save it before leaving?",
        })}
      />
    </SafeAreaView>
  );
}
