import { ApolloError, useApolloClient } from '@apollo/client';
import { StrictMode, useState } from 'react';
import { SafeAreaView, StatusBar } from 'react-native';

import FullLogo from '@src/assets/Full_logo.svg';
import WaitingForActivation from '@src/assets/Waiting_for_Activation.svg';
import { Button } from '@src/components/Button';
import { ErrorPresenter } from '@src/components/ErrorPresenter';
import { ScrollView } from '@src/components/ScrollView';
import { Text } from '@src/components/Text';
import { TextInput } from '@src/components/TextInput';
import { View } from '@src/components/View';
import { useForm } from '@src/hooks/useForm';
import { useWindowDimensions } from '@src/hooks/useWindowDimensions';
import { useI18n } from '@src/lib/i18n';
import { logEvent } from '@src/lib/log';
import { StackScreenProps } from '@src/types';

import { queryExchangeActivationToken } from './Welcome.graphql.generated';

function ClaimActivationTokenForm({
  navigation,
}: {
  navigation: StackScreenProps<'Welcome'>['navigation'];
}) {
  const [error, setError] = useState('');
  const { validate, bind, data, humanErrors } = useForm<{
    activationTokenValue: string;
  }>({
    activationTokenValue: '',
  });
  const apollo = useApolloClient();
  const { $t } = useI18n();

  const request = () => {
    setError('');
    if (validate() && data.activationTokenValue) {
      logEvent('submit_claim_code');
      const token = data.activationTokenValue.toString();
      return queryExchangeActivationToken(apollo, { activationTokenValue: token })
        .then((result) => {
          if (result.data?.ouiUserEmailWithToken) {
            logEvent('submit_claim_code_success');
            const email = result.data.ouiUserEmailWithToken;
            navigation.navigate('SignUp', {
              signupTokenFromActivationCode: token,
              email: email.endsWith('@signup.oui.dev') ? undefined : email,
            });
          } else {
            logEvent('submit_claim_code_error');
            setError('Claim code not found');
          }
        })
        .catch((e: ApolloError) => {
          logEvent('submit_claim_code_error');
          if (
            e.message.includes('no rows') ||
            e.graphQLErrors?.find((gqlE) => gqlE.extensions?.message === 'UNAUTHORIZED')
          ) {
            setError('Claim code not found');
          }
        });
    }
    return;
  };

  const { error: formError, ...props } = bind('activationTokenValue', {
    label: $t({
      id: 'Welcome_activationTokenLabel',
      defaultMessage: 'Enter claim code',
    }),
    validator: { type: 'present' },
  });

  return (
    <View spacing={12} flex={1}>
      <ErrorPresenter errorString={error} formErrors={humanErrors} />
      <TextInput
        placeholder="********"
        error={formError ?? error}
        {...props}
        testID="Welcome_enterClaimCode"
        onSubmitEditing={request}
        keyboardType="numeric"
      />
      <Button
        text={$t({ id: 'Welcome_submitClaimCodeButton', defaultMessage: 'Next' })}
        testID="Welcome_submitClaimCodeButton"
        onPress={request}
        alignSelf="center"
      />
    </View>
  );
}

export default function Welcome(props: StackScreenProps<'Welcome'>) {
  const { height } = useWindowDimensions();
  const { $t } = useI18n();

  return (
    <SafeAreaView
      style={{
        marginTop: height < 600 ? 20 : 40,
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      <ScrollView style={{ flexGrow: 1 }} contentContainerStyle={{ flexGrow: 1 }}>
        <StatusBar barStyle="dark-content" translucent />
        <View style={{ alignItems: 'center' }}>
          <FullLogo
            height={height / 5}
            accessibilityLabel={$t({
              id: 'Welcome_logoAccessibilityLabel',
              defaultMessage: 'Aviva Logo',
            })}
            accessibilityRole="image"
          />
        </View>
        <View flex={1} style={{}} spacing={12}>
          <View
            style={{ alignItems: 'center', position: 'absolute', bottom: 0, left: 0, right: 0 }}
          >
            <WaitingForActivation height={height / 4} accessibilityLabel={undefined} />
          </View>
          <Text
            text={$t({ id: 'Welcome_title', defaultMessage: 'Welcome to Aviva' })}
            testID="Welcome_title"
            size={height < 600 ? 24 : 32}
            weight="bold"
            style={{ textAlign: 'center' }}
          />
          <View style={{ marginHorizontal: 20 }}>
            <StrictMode>
              <ClaimActivationTokenForm navigation={props.navigation} />
            </StrictMode>
          </View>
        </View>
      </ScrollView>
      <View>
        <View
          style={{
            padding: 0,
            margin: 0,
            borderTopWidth: 1,
            alignSelf: 'stretch',
          }}
        >
          <View style={{ padding: 16, alignItems: 'center' }} spacing={8}>
            <Button
              onPress={() => props.navigation.navigate('Login', {})}
              text={$t({
                id: 'Welcome_navigateToLoginButton',
                defaultMessage: 'I already have an account',
              })}
              testID="Welcome_navigateToLoginButton"
              variant="text"
              alignSelf="center"
            />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}
