import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useCallback, useEffect, useRef } from 'react';

import ChatInputButton from '@src/components/ChatInputButton';
import { View } from '@src/components/View';
import { startArtifactRequest, useArtifactResult } from '@src/hooks/useArtifactResult';
import { ChatInputArtifactProps, ChatInputBaseProps, Kind, StackScreenProps } from '@src/types';

export function ChatInputArtifact(props: ChatInputArtifactProps & ChatInputBaseProps) {
  const { navigate } = useNavigation<StackScreenProps<'Conversation'>['navigation']>();
  const artifactStartedRef = useRef(false);
  const onInputRef = useRef(props.onInput);
  onInputRef.current = props.onInput;

  useArtifactResult((result) => {
    artifactStartedRef.current = false;
    props.onInput({ kind: Kind.InputArtifact, props: result });
  });

  const onStartArtifact = useCallback(() => {
    artifactStartedRef.current = true;
    startArtifactRequest(props.artifactName);

    // @ts-expect-error we don't have precise enough types for routeName/params so we just have to trust
    // the artifact props
    navigate(props.artifactName === 'SleepDiary' ? 'SetupSleepDiary' : props.artifactName, {
      ...props.params,
    });
  }, [props.artifactName, navigate, props.params]);

  useFocusEffect(
    useCallback(() => {
      if (artifactStartedRef.current) {
        onInputRef.current({ kind: Kind.InputArtifact, props: { complete: false } });
      }
    }, []),
  );

  useEffect(() => {
    if (props.retry) {
      onStartArtifact();
    }
  }, [props.retry, onStartArtifact]);

  return props.retry ? null : (
    <View>
      <ChatInputButton
        onInput={(action) => {
          if (action.kind === Kind.InputButton) {
            onStartArtifact();
          }
        }}
        label={props.buttonText}
      />
    </View>
  );
}

ChatInputArtifact.defaultProps = {
  onInput: () => {},
  disabled: false,
};
