import { useNavigation } from '@react-navigation/native';

import { SessionWithState, useFeed } from '@oui/app-core/src/hooks/useFeed';
import { Learn as LearnScreen } from '@oui/app-core/src/screens/Learn';
import { ContentType } from '@oui/lib/src/types';

import { useLearnQuery } from '@src/screens/Learn.graphql.generated';
import { StackScreenProps } from '@src/types/navigation';

export function Learn(_props: {}) {
  const navigation = useNavigation<StackScreenProps<'MyPlan'>['navigation']>();
  const { data } = useLearnQuery();
  const { isCollaborativeOnboarding } = useFeed(data?.treatmentV2);

  const onSessionCardPress = ({ session, slug, state }: SessionWithState) => {
    if (isCollaborativeOnboarding && slug === ContentType.MYPLAN) {
      if (state === 'session-complete') {
        navigation.navigate('MyStoryMyPlan', {
          screen: 'MyStoryMyPlanOverview',
        });
      } else {
        navigation.navigate('ControlledMyStoryMyPlan');
      }
    } else {
      navigation.navigate('Conversation', {
        num: session.num.toString(),
        title: session.title,
        ID: slug || 'TEST::convo',
        completed: state === 'session-complete' ? ('true' as const) : ('false' as const),
      });
    }
  };

  return <LearnScreen onSessionCardPress={onSessionCardPress} />;
}
