import { getFocusedRouteNameFromRoute, useNavigation, useRoute } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useEffect } from 'react';
import { SafeAreaView } from 'react-native';

import { Button } from '@src/components/Button';
import { DEFAULT_HEADER_MODE } from '@src/constants';
import { logEvent } from '@src/lib/log';
import { PatientMyPlanIntroduction } from '@src/screens/PatientMyPlanIntroduction';
import { PatientMyPlanReviewStatic } from '@src/screens/PatientMyPlanReview';
import { PatientMyStoryIntroduction } from '@src/screens/PatientMyStoryIntroduction';
import { PatientMyStoryMyPlanOverview } from '@src/screens/PatientMyStoryMyPlanOverview';
import { PatientMyStoryTimelineStatic } from '@src/screens/PatientMyStoryTimeline';
import { PatientRiskCurveIntroduction } from '@src/screens/PatientRiskCurveIntroduction';
import { PatientRiskCurveReviewStatic } from '@src/screens/PatientRiskCurveReview';
import { MyStoryMyPlanScreenProps, MyStoryMyPlanStackParamList } from '@src/types';

const SCREEN_ORDER = [
  'MyStoryMyPlanOverview',
  'MyStoryIntroduction',
  'MyStoryTimelineFinal',
  'MyStoryRiskCurveIntroduction',
  'MyStoryRiskCurveReview',
  'MyStoryMyPlanIntroduction',
  'MyStoryMyPlanReview',
  'MyStoryMyPlanComplete',
] as const;
const MyStoryMyPlanStack = createStackNavigator<MyStoryMyPlanStackParamList>();
const PatientMyStoryMyPlanV2 = () => {
  const { navigate } =
    useNavigation<MyStoryMyPlanScreenProps<'MyStoryMyPlanOverview'>['navigation']>();
  const _route = useRoute();
  const routeName = getFocusedRouteNameFromRoute(_route) ?? 'MyStoryMyPlanOverview';
  const currentIndex = SCREEN_ORDER.findIndex((v) => v === routeName);

  return (
    <>
      <MyStoryMyPlanStack.Navigator
        initialRouteName="MyStoryMyPlanOverview"
        screenOptions={() => ({
          headerMode: DEFAULT_HEADER_MODE,
          headerShown: false,
        })}
      >
        <MyStoryMyPlanStack.Screen
          name="MyStoryMyPlanOverview"
          component={PatientMyStoryMyPlanOverview}
        />
        <MyStoryMyPlanStack.Screen
          name="MyStoryIntroduction"
          component={PatientMyStoryIntroduction}
        />
        <MyStoryMyPlanStack.Screen
          name="MyStoryTimelineFinal"
          component={PatientMyStoryTimelineStatic}
        />
        <MyStoryMyPlanStack.Screen
          name="MyStoryRiskCurveIntroduction"
          component={PatientRiskCurveIntroduction}
        />
        <MyStoryMyPlanStack.Screen
          name="MyStoryRiskCurveReview"
          component={PatientRiskCurveReviewStatic}
        />
        <MyStoryMyPlanStack.Screen
          name="MyStoryMyPlanIntroduction"
          component={PatientMyPlanIntroduction}
        />
        <MyStoryMyPlanStack.Screen
          name="MyStoryMyPlanReview"
          component={PatientMyPlanReviewStatic}
        />
        <MyStoryMyPlanStack.Screen name="MyStoryMyPlanComplete">
          {() => <PatientMyStoryMyPlanOverview isComplete />}
        </MyStoryMyPlanStack.Screen>
      </MyStoryMyPlanStack.Navigator>
      {routeName !== 'MyStoryMyPlanComplete' ? (
        <SafeAreaView>
          <Button
            text="Next"
            alignSelf="center"
            onPress={() => navigate(SCREEN_ORDER[currentIndex + 1])}
          />
        </SafeAreaView>
      ) : null}
    </>
  );
};

export const PatientMyStoryMyPlan = () => {
  useEffect(() => {
    logEvent('view_mystorymyplan');
  }, []);

  return <PatientMyStoryMyPlanV2 />;
};
