import { produce } from 'immer';
import { ReactNode, useRef, useState } from 'react';
import { AccessibilityInfo, Platform, TouchableOpacity, findNodeHandle } from 'react-native';
import DraggableFlatList from 'react-native-draggable-flatlist';
import type { FlatList } from 'react-native-gesture-handler';
import { v4 as uuid } from 'uuid';

import { reorderArrayItem } from '@oui/lib/src/reorderArrayItem';
import { HealthOui } from '@oui/lib/src/types/avro/crisisTimeline';
import {
  CrisisTimelineList,
  CrisisTimelineSectionHeading,
  getCrisisTimelineSections,
} from '@oui/myplan/src/components/CrisisTimelineList';

import { Button } from '@src/components/Button';
import { Icon } from '@src/components/Icon';
import { OldHeading, Text } from '@src/components/Text';
import { TextInput } from '@src/components/TextInput';
import { View } from '@src/components/View';
import { card, useTheme } from '@src/styles';

function TimelineConnector(props: { top?: number; bottom?: number }) {
  return (
    <View
      style={{
        position: 'absolute',
        width: 1,
        top: props.top ?? 0,
        left: 30 + 4.5,
        bottom: props.bottom ?? 0,
        backgroundColor: '#c5c5d3',
      }}
    />
  );
}

export function SortableCrisisTimelineList({
  activeSection,
  ...props
}: {
  value: HealthOui.CrisisTimeline;
  activeSection: 'all' | 'before' | 'after';
  renderFooter?: () => ReactNode;
  onChangeValue?: (v: HealthOui.CrisisTimeline) => void;
  testID?: string;
}) {
  const data = props.value;
  function setData(newValue: HealthOui.CrisisTimeline) {
    props.onChangeValue?.(newValue);
  }
  const adjustedIDRef = useRef<string | null>(null);
  const shouldAutoFocusRef = useRef<boolean>(false);
  const [autoFocusID, setAutoFocusID] = useState('');
  const listRef = useRef<FlatList<(typeof data)['timeline'][number]>>(null);
  const { Color } = useTheme();
  const { before, peak, after } = getCrisisTimelineSections(data);
  const baseIndex =
    activeSection === 'before' || activeSection === 'all' ? 0 : before.length + peak.length;
  const requiresAndroidAutoFocusWorkaround =
    (activeSection === 'after' || activeSection === 'all') && Platform.OS === 'android';
  function updateTimeline(index: number, newText: string) {
    const result = produce(data, (draft) => {
      const event = draft.timeline[baseIndex + index]!;
      event.text = newText;
    });
    setData(result);
  }
  function removeTimelineEvent(index: number) {
    setData({
      crisisPeakID: data.crisisPeakID,
      timeline: [
        ...data.timeline.slice(0, baseIndex + index),
        ...data.timeline.slice(baseIndex + index + 1),
      ],
    });
  }
  function reorderTimelineEvent(index: number, delta: 1 | -1) {
    const finalIndex = baseIndex + index + delta;
    if (finalIndex >= 0 && finalIndex < data.timeline.length) {
      setData({
        crisisPeakID: data.crisisPeakID,
        timeline: reorderArrayItem(data.timeline, baseIndex + index, baseIndex + index + delta),
      });
    }
  }

  return (
    <View
      style={{
        backgroundColor: Color.grayBackground,
        flex: 1,
        marginHorizontal: -20,
        marginTop: -20,
        borderTopStartRadius: 30,
        borderTopEndRadius: 30,
        marginBottom: -20,
        overflow: 'hidden', // prevent overflow on android
      }}
    >
      <TimelineConnector />
      <DraggableFlatList
        testID={props.testID}
        ref={listRef}
        data={activeSection === 'all' ? data.timeline : activeSection === 'before' ? before : after}
        ListHeaderComponent={() => (
          <View>
            <View
              style={{
                padding: 20,
                backgroundColor: 'white',
                borderTopStartRadius: 30,
                borderTopEndRadius: 30,
              }}
            >
              <OldHeading
                text={
                  activeSection === 'all'
                    ? 'Edit & order the timeline before, during, and after your crisis'
                    : activeSection === 'before'
                    ? 'Edit & order the timeline before the peak of your crisis'
                    : 'Edit & order the timeline after the peak of your crisis'
                }
              />
              <Text text="Make your best guess to order what happened. It’s okay if it’s not 100% exact. Just give it a try." />
            </View>
            {activeSection === 'all' ? (
              <View style={{ padding: 10 }} />
            ) : activeSection === 'before' ? (
              <View
                style={{
                  paddingHorizontal: 30,
                  paddingVertical: 20,
                  backgroundColor: Color.grayBackground,
                }}
              >
                <TimelineConnector top={20} />
                <CrisisTimelineSectionHeading
                  icon="risk-curve-rising"
                  text="Rising"
                  backgroundColor={Color.grayBackground}
                />
              </View>
            ) : (
              <View style={{ paddingHorizontal: 30, paddingVertical: 20 }} spacing={15}>
                <View
                  style={{
                    // cover up TimelineConnector
                    backgroundColor: Color.grayBackground,
                    height: 40,
                    width: 50,
                    position: 'absolute',
                    top: 0,
                  }}
                />
                <CrisisTimelineList
                  {...data}
                  showRiskColor
                  splitSections
                  skipAfterPeak
                  backgroundColor={Color.grayBackground}
                />
                <CrisisTimelineSectionHeading
                  icon="risk-curve-falling"
                  text="Falling"
                  backgroundColor={Color.grayBackground}
                />
              </View>
            )}
          </View>
        )}
        ListFooterComponent={() => (
          <View>
            <View
              style={{
                paddingHorizontal: 30,
                marginTop: 20,
                marginBottom: 10,
                backgroundColor: Color.grayBackground,
              }}
            >
              <Button
                variant="text"
                text="Add item"
                icon="plus"
                onPress={() => {
                  shouldAutoFocusRef.current = true;
                  const newItem = { ID: uuid(), text: '', isWarningSign: false };
                  setData({
                    crisisPeakID: props.value.crisisPeakID,
                    timeline:
                      activeSection === 'before'
                        ? [...before, newItem, ...peak, ...after]
                        : [...before, ...peak, ...after, newItem],
                  });
                  if (requiresAndroidAutoFocusWorkaround) {
                    listRef.current?.scrollToEnd();
                    setTimeout(() => {
                      setAutoFocusID(newItem.ID);
                    }, 200);
                  }
                }}
              />
            </View>
            {activeSection === 'before' ? (
              <View style={{ paddingHorizontal: 30, paddingVertical: 0 }}>
                <CrisisTimelineList
                  {...data}
                  showRiskColor
                  splitSections
                  skipBeforePeak
                  backgroundColor={Color.grayBackground}
                />
              </View>
            ) : null}
            <View style={{ backgroundColor: Color.grayBackground, height: 20 }} />
            {props.renderFooter?.()}
          </View>
        )}
        style={{ borderTopStartRadius: 30, borderTopEndRadius: 30 }}
        contentContainerStyle={{
          borderTopStartRadius: 30,
          borderTopEndRadius: 30,
        }}
        bounces={false}
        renderItem={({ item, getIndex, drag, isActive }) => {
          const index = getIndex();
          const shouldAutoFocus =
            // Android has issues autoFocusing at the bottom of the list while opening
            // they keyboard
            (requiresAndroidAutoFocusWorkaround ? autoFocusID === item.ID : true) &&
            shouldAutoFocusRef.current &&
            item.text === '' &&
            !isActive &&
            index === (activeSection === 'before' ? before.length - 1 : data.timeline.length - 1);
          if (shouldAutoFocus) {
            shouldAutoFocusRef.current = false;
          }
          const isCrisisPeak = item.ID === data.crisisPeakID;

          return (
            <View
              style={[
                card,
                {
                  padding: 12,
                  marginVertical: 8,
                  marginHorizontal: 20,
                  alignItems: 'flex-start',
                },
              ]}
              row
              spacing={12}
            >
              {isCrisisPeak ? (
                <Icon
                  name="risk-curve-peak"
                  color={Color.styleGuide.Gray5}
                  size={14}
                  style={{ marginTop: 12 }}
                />
              ) : (
                <Icon
                  name="close"
                  color={Color.styleGuide.Gray5}
                  size={14}
                  style={{ marginTop: 12 }}
                  onPress={() => {
                    removeTimelineEvent(index!);
                  }}
                  accessibilityLabel="Remove item"
                />
              )}
              <TextInput
                autoFocus={shouldAutoFocus}
                multiline
                value={item.text}
                style={{ flex: 1 }}
                inputStyle={isCrisisPeak ? { fontFamily: 'OpenSansSemiBold' } : null}
                onChangeValue={(newText) => {
                  updateTimeline(index!, newText);
                }}
                onFocus={() => {
                  if (index) {
                    listRef.current?.scrollToIndex({ index, viewPosition: 0.3 });
                  }
                }}
              />
              <TouchableOpacity
                // Need to update key so drag handle ref callback runs after re-ordering
                key={item.ID + index}
                ref={(r) => {
                  // android can lose a11y focus on the draghandle after decrementing/incrementing
                  // so we manually refocus
                  if (r && item.ID === adjustedIDRef.current) {
                    const handle = findNodeHandle(r)!;
                    AccessibilityInfo.setAccessibilityFocus(handle);
                    adjustedIDRef.current = null;
                  }
                }}
                delayPressIn={0}
                delayPressOut={0}
                onPressIn={drag}
                accessibilityRole="adjustable"
                accessibilityLabel="Move up or down in list"
                style={{
                  paddingTop: 8,
                  opacity: isActive ? 0.6 : 1,
                  height: '100%',
                }}
                hitSlop={{
                  left: 30,
                  right: 30,
                }}
                accessibilityActions={[
                  { name: 'increment', label: 'Move up in list' },
                  { name: 'decrement', label: 'Move down in list' },
                ]}
                onAccessibilityAction={(e) => {
                  switch (e.nativeEvent.actionName) {
                    case 'increment': {
                      reorderTimelineEvent(index!, -1);
                      adjustedIDRef.current = item.ID;
                      break;
                    }
                    case 'decrement': {
                      reorderTimelineEvent(index!, 1);
                      adjustedIDRef.current = item.ID;
                      break;
                    }
                  }
                }}
              >
                <Icon name="reorder" color={Color.accent} size={24} />
              </TouchableOpacity>
            </View>
          );
        }}
        keyExtractor={(item) => `draggable-item-${item.ID}-${autoFocusID === item.ID}`}
        onDragEnd={({ data: timelineData }) =>
          setData({
            crisisPeakID: data.crisisPeakID,
            timeline:
              activeSection === 'all'
                ? timelineData
                : activeSection === 'before'
                ? [...timelineData, ...peak, ...after]
                : [...before, ...peak, ...timelineData],
          })
        }
      />
    </View>
  );
}
