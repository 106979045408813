import type { ApolloClient } from '@apollo/client';

import { queryOrganizations } from '@oui/app-core/src/hooks/useCurrentUser.graphql.generated';

import { setUserProperties } from './log';

export async function setOrganizationUserProperties(client: ApolloClient<unknown>) {
  const { data } = await queryOrganizations(client);
  const organization = data?.organizations?.[0];
  if (organization) {
    setUserProperties({
      organizationID: organization.ID,
      organizationName: organization.name ?? 'Unknown',
    });
  }
}
