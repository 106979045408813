import { TouchableOpacity } from 'react-native';

import { View } from '@src/components/View';
import { Color, useTheme } from '@src/styles';

import { Icon } from './Icon';

function Star(props: {
  accessibilityLabel?: string;
  size?: number;
  value?: boolean;
  onChangeValue: (value: boolean) => void;
  testID?: string;
}) {
  const { theme } = useTheme();
  const size = props.size ?? 40;

  return (
    <TouchableOpacity
      onPress={() => {
        props.onChangeValue(!props.value);
      }}
      accessibilityLabel={props.accessibilityLabel}
      accessibilityRole="radio"
      accessibilityState={{
        checked: !!props.value,
      }}
      style={{ marginHorizontal: 10 }}
      testID={props.testID}
    >
      <Icon
        name={props.value ? 'star-selected' : 'star'}
        size={size}
        color={props.value ? theme.color.warning : Color.styleGuide.Gray5}
      />
    </TouchableOpacity>
  );
}

export function StarRating(props: {
  accessibilityLabel?: string;
  size?: number;
  value?: number;
  onChangeValue: (value: number) => void;
  testID?: string;
}) {
  const value = props.value ?? 0;
  return (
    <View
      row
      style={{ alignSelf: 'center' }}
      accessibilityRole="radiogroup"
      accessibilityLabel={props.accessibilityLabel ?? 'Star rating'}
      testID={props.testID}
    >
      <Star
        accessibilityLabel="1 star"
        size={props.size}
        value={value >= 1}
        onChangeValue={() => props.onChangeValue(1)}
        testID="StarRating_1"
      />
      <Star
        accessibilityLabel="2 stars"
        size={props.size}
        value={value >= 2}
        onChangeValue={() => props.onChangeValue(2)}
        testID="StarRating_2"
      />
      <Star
        accessibilityLabel="3 stars"
        size={props.size}
        value={value >= 3}
        onChangeValue={() => props.onChangeValue(3)}
        testID="StarRating_3"
      />
      <Star
        accessibilityLabel="4 stars"
        size={props.size}
        value={value >= 4}
        onChangeValue={() => props.onChangeValue(4)}
        testID="StarRating_4"
      />
      <Star
        accessibilityLabel="5 stars"
        size={props.size}
        value={value >= 5}
        onChangeValue={() => props.onChangeValue(5)}
        testID="StarRating_5"
      />
    </View>
  );
}
