import { Image, ScrollView } from 'react-native';

import { AuthScreenContainer } from '@oui/app-core/src/components/AuthScreenContainer';

import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { APP_SLUG, manifest } from '@src/constants';
import { useWindowDimensions } from '@src/hooks/useWindowDimensions';
import { I18nProvider } from '@src/lib/i18n';

const PLAY_STORE_LOGO_URL =
  'https://storage.googleapis.com/asset.oui.dev/email/google-play-badge.png';
const APP_STORE_LOGO_URL = 'https://storage.googleapis.com/asset.oui.dev/email/app-store-badge.png';

export default function WebBlocker() {
  const { width } = useWindowDimensions();
  const appName = manifest.name || 'Aviva';
  return (
    <I18nProvider getMessages={() => ({})}>
      <AuthScreenContainer heading={`Download the ${appName} app`} _scrollView={ScrollView}>
        <View
          style={{
            alignSelf: 'center',
            alignItems: 'center',
            paddingTop: 20,
            paddingHorizontal: 30,
            width: 600,
          }}
          spacing={40}
        >
          <Text
            text={
              APP_SLUG === 'oui-aviva'
                ? `${appName} is currently only available on Android and iOS devices. Please download the ${appName} app to your phone.`
                : `${appName} is currently only available on iPad. Please download the app.`
            }
            style={{ textAlign: 'center' }}
          />
          {APP_SLUG === 'oui-aviva' ? (
            <View
              row={width < 800 ? false : true}
              spacing={20}
              childFlex={1}
              style={{ alignSelf: 'stretch' }}
            >
              <View>
                <a
                  href="https://play.google.com/store/apps/details?id=com.ouitherapeutics.aviva"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    source={{ uri: PLAY_STORE_LOGO_URL }}
                    style={{ height: 70 }}
                    resizeMode="contain"
                  />
                </a>
              </View>
              <View>
                <a
                  href="https://apps.apple.com/app/aviva/id1480869629"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    source={{ uri: APP_STORE_LOGO_URL }}
                    style={{ height: 70 }}
                    resizeMode="contain"
                  />
                </a>
              </View>
            </View>
          ) : (
            <View style={{ alignSelf: 'stretch', justifyContent: 'center' }}>
              <View>
                <a
                  href="https://apps.apple.com/app/aviva-staff/id1480857301"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    source={{ uri: APP_STORE_LOGO_URL }}
                    style={{ height: 70 }}
                    resizeMode="contain"
                  />
                </a>
              </View>
            </View>
          )}
        </View>
      </AuthScreenContainer>
    </I18nProvider>
  );
}
