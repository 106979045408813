import { Asset } from 'expo-asset';
import { activateKeepAwakeAsync, deactivateKeepAwake } from 'expo-keep-awake';
import {
  ComponentProps,
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Animated, Easing, Platform, TouchableOpacity as RNTouchableOpacity } from 'react-native';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import {
  GestureDetector as GHGestureDetector,
  TouchableOpacity as GHTouchableOpacity,
  Gesture,
} from 'react-native-gesture-handler';
import { runOnJS } from 'react-native-reanimated';
import { Circle } from 'react-native-svg';

import RNVideo from '@oui/app-core/src/components/RNVideo';
import { Subtitles } from '@oui/app-core/src/components/Subtitles';
import { TranscriptModal } from '@oui/app-core/src/components/TranscriptModal';
import { useFormatDuration } from '@oui/app-core/src/hooks/useFormatDuration';
import { usePersistedState } from '@oui/app-core/src/hooks/usePersistedState';
import { getMMSSFromMillis, getMinuteSecondFromMillis } from '@oui/app-core/src/lib/mediaPlayer';
import { getVolume } from '@oui/app-core/src/lib/systemSetting';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { Icon } from '@src/components/Icon';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { checkIfBareAssetExists } from '@src/lib/checkIfBareAssetExists';
import { logEvent } from '@src/lib/log';
import { Color } from '@src/styles';

const EXERCISE = {
  audio: Asset.fromURI(
    'https://storage.googleapis.com/asset.oui.dev/mindfulness_audio/BreathingRelaxation.mp3',
  ),
  subtitleUri:
    'https://storage.googleapis.com/asset.oui.dev/mindfulness_audio/BreathingRelaxation.vtt',
};
async function preloadExercises() {
  const exists = await checkIfBareAssetExists(EXERCISE.audio);
  if (!exists) {
    await EXERCISE.audio.downloadAsync();
  }
}

const useGHForNestedTouchables = Platform.OS === 'android';
const TouchableOpacity = useGHForNestedTouchables ? GHTouchableOpacity : RNTouchableOpacity;

// https://github.com/software-mansion/react-native-gesture-handler/issues/1840
const WebGestureDetector = (props: PropsWithChildren) => props.children;
const GestureDetector =
  Platform.OS === 'web'
    ? (WebGestureDetector as unknown as typeof GHGestureDetector)
    : GHGestureDetector;

const AudioPlayer = forwardRef<RNVideo, ComponentProps<typeof RNVideo>>((props, forwardRef) => {
  if (Platform.OS === 'web') {
    // eslint-disable-next-line
    const ref = useRef<HTMLAudioElement>();
    // eslint-disable-next-line
    useEffect(() => {
      const source = props.source as { uri?: string };
      if (source.uri) {
        const audio = new Audio(source.uri);
        ref.current = audio;

        audio.addEventListener('loadedmetadata', () => {
          // eslint-disable-next-line
          props.onLoad?.({ duration: audio.duration } as any);
        });

        audio.addEventListener('timeupdate', () => {
          props.onProgress?.({
            currentTime: audio.currentTime,
            seekableDuration: audio.duration,
            playableDuration: audio.duration,
          });
        });
      }
      // eslint-disable-next-line
    }, [(props.source as any)?.uri]);

    // @ts-ignore
    // eslint-disable-next-line
    useImperativeHandle(forwardRef, () => ({
      play: () => {
        ref.current?.play();
      },
      pause: () => {
        ref.current?.pause();
      },
      seek: (time: number) => {
        ref.current!.currentTime = time;
      },
    }));

    // eslint-disable-next-line
    useEffect(() => {
      if (props.paused) {
        ref.current?.pause();
      } else {
        ref.current?.play();
      }
    }, [props.paused]);

    return null;
  }

  return (
    <RNVideo
      muted={false}
      repeat={false}
      audioOnly={true}
      resizeMode={'cover'}
      volume={1.0}
      rate={1.0}
      ignoreSilentSwitch={'ignore'}
      bufferConfig={
        // android suffers from OOM during Relax e2e tests which are audio heavy. To try to
        // mitigate that issue, we'll cap the video buffering
        global.e2e && Platform.OS === 'android'
          ? {
              minBufferMs: 1,
              maxBufferMs: 10,
              bufferForPlaybackMs: 0,
              bufferForPlaybackAfterRebufferMs: 0,
            }
          : undefined
      }
      {...props}
      ref={forwardRef}
    />
  );
});

type Props = {
  hidePlayButton?: boolean;
  onEnd?: () => void;
  onLoaded?: (isLoaded: boolean) => void;
  onPause?: (completion: number) => void;
  onStart?: () => void;
};

function BreathingCircleOrb({
  growDuration = 5000,
  opacity = 1,
  iteration,
  shrinkDuration = 5000,
  size = 100,
}: {
  growDuration?: number;
  opacity?: number;
  iteration: number;
  shrinkDuration?: number;
  size?: number;
}) {
  const animated = useRef(new Animated.Value(0));

  useEffect(() => {
    if (iteration && !(Platform.OS === 'android' && global.e2e)) {
      Animated.sequence([
        Animated.timing(animated.current, {
          duration: growDuration,
          toValue: 1,
          useNativeDriver: true,
        }),
        Animated.timing(animated.current, {
          duration: shrinkDuration,
          toValue: 0.5,
          useNativeDriver: true,
        }),
      ]).start();
    }
  }, [iteration, growDuration, shrinkDuration]);

  return (
    <Animated.View
      style={{
        opacity,
        position: 'absolute',
        borderRadius: size / 2,
        backgroundColor: Color.styleGuide.LogoCyan,
        width: size,
        height: size,
        transform: [
          {
            scale: animated.current.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 1.5],
            }),
          },
        ],
      }}
    ></Animated.View>
  );
}

function BreathingCircleOrbs(props: {
  iteration: number;
  growDuration: number;
  shrinkDuration: number;
}) {
  return (
    <>
      <BreathingCircleOrb {...props} size={200} opacity={0.2} />
      <BreathingCircleOrb {...props} size={240} opacity={0.1} />
      <BreathingCircleOrb {...props} size={250} opacity={0.1} />
    </>
  );
}

const DurationLabel = (props: {
  durationStr: string;
  playbackStatus:
    | {
        isLoaded: false;
        isMuted: boolean;
      }
    | {
        isLoaded: true;
        isMuted: boolean;
        positionMillis: number;
        durationMillis: number;
      };
}) => {
  const { formatDuration } = useFormatDuration();
  const { durationStr, playbackStatus } = props;

  const duration = useMemo(() => {
    if (!playbackStatus.isLoaded) return '';

    const { minutes, seconds } = getMinuteSecondFromMillis(
      playbackStatus.durationMillis! - playbackStatus.positionMillis,
    );

    return formatDuration({
      minutes,
      seconds,
    });
  }, [playbackStatus, formatDuration]);

  return (
    <Text
      text={durationStr}
      accessibilityLabel={playbackStatus.isLoaded ? `Current time: ${duration}` : ''}
    />
  );
};

const BREATHING_KEYFRAMES: [number, { growDuration: number; shrinkDuration: number }][] = [
  [13000, { growDuration: 12000, shrinkDuration: 8000 }],
  [37000, { growDuration: 5500, shrinkDuration: 5500 }],
  [55000, { growDuration: 3000, shrinkDuration: 3000 }],
  [61000, { growDuration: 3000, shrinkDuration: 3000 }],
  [67000, { growDuration: 3000, shrinkDuration: 3000 }],
  [73000, { growDuration: 3000, shrinkDuration: 3000 }],
  [79000, { growDuration: 3000, shrinkDuration: 3000 }],
  [85000, { growDuration: 3000, shrinkDuration: 3000 }],
  [91000, { growDuration: 3000, shrinkDuration: 3000 }],
  [97000, { growDuration: 3000, shrinkDuration: 3000 }],
  [103000, { growDuration: 3000, shrinkDuration: 3000 }],
  [109000, { growDuration: 3000, shrinkDuration: 3000 }],
  [115000, { growDuration: 3000, shrinkDuration: 3000 }],
  [121000, { growDuration: 3000, shrinkDuration: 3000 }],
  [127000, { growDuration: 3000, shrinkDuration: 3000 }],
  [133000, { growDuration: 3000, shrinkDuration: 3000 }],
  [139000, { growDuration: 3000, shrinkDuration: 3000 }],
  [145000, { growDuration: 3000, shrinkDuration: 3000 }],
  [151000, { growDuration: 3000, shrinkDuration: 3000 }],
  [157000, { growDuration: 3000, shrinkDuration: 3000 }],
  [164000, { growDuration: 5000, shrinkDuration: 4000 }],
];

const KEEP_AWAKE_TAG = 'KeepAwake:BreathingCircle';
const PROGRESS_SIZE = 180;
const PROGRESS_PADDING = 10;
const PROGRESS_WIDTH = 10;

export type Handles = {
  pauseAsync: () => Promise<unknown>;
  playAsync: () => Promise<unknown>;
};
export default forwardRef<Handles, Props>(function BreathingCircle(props: Props, ref) {
  const [orbState, setOrbState] = useState({ iteration: 0, growDuration: 0, shrinkDuration: 0 });
  const [playbackStatus, setPlaybackStatus] = useState<
    | { isLoaded: false; isMuted: boolean }
    | {
        isLoaded: true;
        isMuted: boolean;
        positionMillis: number;
        durationMillis: number;
      }
  >({ isLoaded: false, isMuted: false });
  const [isPlaying, setIsPlaying] = useState(false);
  const [showTranscript, setShowTranscript] = useState(false);
  const [isClosedCaptionEnabled, setIsClosedCaptionEnabled, isLoadingCaptionState] =
    usePersistedState('enableClosedCaptions', false);
  const audioRef = useRef<RNVideo>(null);
  const progress = useRef<AnimatedCircularProgress>();
  const hasStartedRef = useRef(false);
  const [uri, setUri] = useState(EXERCISE.audio.localUri || EXERCISE.audio.uri);

  useEffect(() => {
    preloadExercises().then(() => setUri(EXERCISE.audio.localUri || EXERCISE.audio.uri));
  }, []);

  const _onStart = props.onStart;
  const onStart = useCallback(() => {
    if (!hasStartedRef.current) {
      hasStartedRef.current = true;
      _onStart?.();
    }
  }, [_onStart]);

  useEffect(() => {
    if (!isLoadingCaptionState) {
      getVolume().then((volume) => {
        if (volume === 0) {
          setIsClosedCaptionEnabled(true);
        }
      });
    }
  }, [setIsClosedCaptionEnabled, isLoadingCaptionState]);

  useImperativeHandle(
    ref,
    () => ({
      playAsync: async () => {
        setIsPlaying(true);
        onStart();
      },
      pauseAsync: async () => {
        setIsPlaying(false);
      },
    }),
    [onStart],
  );

  const onLoaded = props.onLoaded;
  const onEnd = props.onEnd;

  useEffect(() => {
    if (!playbackStatus.isLoaded) return;
    if (isPlaying) {
      const remainingDurationMillis =
        playbackStatus.durationMillis! - playbackStatus.positionMillis;
      const currentPosition = playbackStatus.positionMillis / playbackStatus.durationMillis!;
      if (!(Platform.OS === 'android' && global.e2e)) {
        progress.current!.reAnimate(currentPosition * 100, 100, remainingDurationMillis);
      }
    } else if (!isPlaying) {
      if (playbackStatus.positionMillis === 0) return;
      const currentPosition = playbackStatus.positionMillis / playbackStatus.durationMillis!;
      if (!(Platform.OS === 'android' && global.e2e)) {
        progress.current!.animate(currentPosition * 100, 100000).stop();
      }
    }
    // playbackStatus only needed to refresh when isPlaying changes
    // eslint-disable-next-line
  }, [isPlaying]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      if (isPlaying) {
        activateKeepAwakeAsync(KEEP_AWAKE_TAG);
      }
      return () => {
        deactivateKeepAwake(KEEP_AWAKE_TAG);
      };
    }
    return;
  }, [isPlaying]);

  useEffect(() => {
    if (playbackStatus.isLoaded) {
      const currentKeyframeIndex =
        BREATHING_KEYFRAMES.findIndex(
          (frame, i) => frame[0] <= playbackStatus.positionMillis && i > orbState.iteration - 1,
        ) + 1;
      if (currentKeyframeIndex !== 0 && currentKeyframeIndex !== orbState.iteration) {
        setOrbState({
          iteration: currentKeyframeIndex,
          ...BREATHING_KEYFRAMES[currentKeyframeIndex - 1][1],
        });
      }
    }
  }, [playbackStatus, orbState.iteration]);

  const durationStr = playbackStatus.isLoaded
    ? getMMSSFromMillis(
        playbackStatus.durationMillis! - playbackStatus.positionMillis,
        playbackStatus.durationMillis!,
      )
    : '0:00';

  const handleSeekToCoordinate = ({ x, y }: { x: number; y: number }) => {
    if (!playbackStatus.isLoaded) return;

    function calcAngleDegrees(x: number, y: number) {
      return (Math.atan2(y, x) * 180) / Math.PI;
    }
    const radius = PROGRESS_SIZE / 2;
    const circleX = x - PROGRESS_PADDING - radius;
    const circleY = y - PROGRESS_PADDING - radius;
    const distanceFromCenter = Math.sqrt(circleX ** 2 + circleY ** 2);
    const isValidTouch = Math.abs(radius - PROGRESS_WIDTH - distanceFromCenter) < 15;

    if (!isValidTouch) return;

    let angle = calcAngleDegrees(circleY * -1, circleX);
    if (angle < 0) angle = 360 + angle;
    const percent = angle / 360;

    const currentIsPlaying = isPlaying;
    if (currentIsPlaying) {
      setIsPlaying(false);
    }
    audioRef.current?.seek((playbackStatus.durationMillis / 1000) * percent);
    progress.current?.animate(percent * 100, 1);
    setPlaybackStatus((s) => ({
      ...s,
      positionMillis: playbackStatus.durationMillis * percent,
    }));
    if (currentIsPlaying) {
      setTimeout(() => {
        setIsPlaying(true);
      }, 0);
    }
  };

  const tap = Gesture.Tap().onStart((e) => {
    runOnJS(handleSeekToCoordinate)(e);
  });

  const pan = Gesture.Pan()
    .minDistance(3)
    .onEnd((e) => {
      runOnJS(handleSeekToCoordinate)(e);
    });

  const seek = Gesture.Race(tap, pan);

  return (
    <View
      style={{
        alignSelf: 'center',
        width: 200,
        height: 200,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      accessibilityLabel="Breathing exercise"
    >
      <AudioPlayer
        ref={audioRef}
        source={{ uri }}
        onEnd={() => {
          setIsPlaying(false);
          onEnd?.();
          setTimeout(() => {
            audioRef.current?.seek(0);
            setPlaybackStatus((s) => ({
              ...s,
              positionMillis: 0,
            }));
            progress.current?.animate(0, 1);
          }, 0);
        }}
        onLoad={(l) => {
          setPlaybackStatus({
            isLoaded: true,
            isMuted: playbackStatus.isMuted,
            positionMillis: 0,
            durationMillis: l.duration * 1000,
          });
          onLoaded?.(true);
        }}
        onProgress={(p) => {
          setPlaybackStatus({
            isLoaded: true,
            isMuted: playbackStatus.isMuted,
            positionMillis: p.currentTime * 1000,
            durationMillis: p.seekableDuration * 1000,
          });
        }}
        paused={!isPlaying}
        muted={'isMuted' in playbackStatus && playbackStatus.isMuted}
      />
      <BreathingCircleOrbs {...orbState} />
      <GestureDetector gesture={seek}>
        <Animated.View>
          <AnimatedCircularProgress
            ref={(r) => {
              if (!progress.current) {
                progress.current = r!;
                if (progress.current) {
                  progress.current.animate(100, 10000).stop();
                }
              }
            }}
            rotation={0}
            duration={10000}
            lineCap="round"
            size={180}
            width={10}
            fill={100}
            tintColor={Color.styleGuide.LogoCyan}
            backgroundColor="white"
            easing={Easing.linear}
            padding={10}
            renderCap={({ center }) => {
              return <Circle cx={center.x} cy={center.y} r="10" fill={Color.styleGuide.LogoCyan} />;
            }}
          >
            {() => (
              <View
                flex={1}
                style={{
                  justifyContent: 'space-between',
                  padding: 30,
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <DurationLabel durationStr={durationStr} playbackStatus={playbackStatus} />
                <TouchableOpacity
                  testID={
                    playbackStatus.isLoaded
                      ? isPlaying
                        ? 'BreathingCircle_pauseButton'
                        : 'BreathingCircle_playButton'
                      : undefined
                  }
                  onPress={() => {
                    if (!playbackStatus.isLoaded) return;
                    if (isPlaying) {
                      setIsPlaying(false);
                      props.onPause?.(
                        playbackStatus.positionMillis / playbackStatus.durationMillis,
                      );
                    } else {
                      logEvent('breathing_relaxation_exercise');
                      onStart();
                      setIsPlaying(true);
                    }
                  }}
                  style={[
                    {
                      backgroundColor: 'rgba(255,255,255,0.4)',
                      borderRadius: 40,
                      padding: 10,
                      marginVertical: 6,
                    },
                    props.hidePlayButton ? { display: 'none' } : {},
                  ]}
                  accessibilityRole="button"
                  accessibilityLabel={isPlaying ? 'pause' : 'play'}
                >
                  {playbackStatus.isLoaded ? (
                    <Icon name={isPlaying ? 'pause' : 'play'} size={24} />
                  ) : (
                    <ActivityIndicator />
                  )}
                </TouchableOpacity>
                {playbackStatus.isLoaded ? (
                  <View row spacing={12}>
                    <Icon
                      _useGestureHandler={useGHForNestedTouchables}
                      accessibilityLabel={playbackStatus.isMuted ? 'Unmute' : 'Mute'}
                      name={playbackStatus.isMuted ? 'audio-mute' : 'audio'}
                      size={20}
                      onPress={() => {
                        setPlaybackStatus((s) =>
                          'isMuted' in s ? { ...s, isMuted: !s.isMuted } : s,
                        );
                      }}
                    />
                    <Icon
                      _useGestureHandler={useGHForNestedTouchables}
                      accessibilityLabel={
                        isClosedCaptionEnabled ? 'Disable captions' : 'Enable captions'
                      }
                      name={
                        isClosedCaptionEnabled ? 'closed-captioning-selected' : 'closed-captioning'
                      }
                      size={20}
                      onPress={() => {
                        setIsClosedCaptionEnabled(!isClosedCaptionEnabled);
                      }}
                    />
                    <Icon
                      _useGestureHandler={useGHForNestedTouchables}
                      accessibilityLabel="Show transcript"
                      name="transcript"
                      size={20}
                      onPress={() => {
                        setShowTranscript(true);
                      }}
                    />
                  </View>
                ) : (
                  <Text text="" accessibilityRole="none" />
                )}
              </View>
            )}
          </AnimatedCircularProgress>
        </Animated.View>
      </GestureDetector>
      <View
        style={{
          position: 'absolute',
          right: 0,
          left: 0,
          bottom: -80,
        }}
      >
        <Subtitles
          enabled={isClosedCaptionEnabled}
          positionMillis={playbackStatus.isLoaded ? playbackStatus.positionMillis : 0}
          uri={EXERCISE.subtitleUri}
        />
      </View>
      {showTranscript ? (
        <TranscriptModal
          onRequestClose={() => setShowTranscript(false)}
          uri={EXERCISE.subtitleUri}
        />
      ) : null}
    </View>
  );
});
