// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserProfileQuery = { readonly avatar?: string | null, readonly currentUser: { readonly __typename: 'User', readonly ID: CustomScalars.GQLUserID, readonly name: { readonly first: string, readonly last: string } }, readonly lessonLearned?: ReadonlyArray<{ readonly session: string, readonly text: string }> | null };

export type UploadAvatarMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type UploadAvatarMutation = { readonly uploadResumableAsset: { readonly resumableUploadAssetURI: string } };


export const UserProfileDocument = gql`
    query UserProfile {
  currentUser {
    __typename
    ID
    name {
      first
      last
    }
  }
  avatar: asset(context: "user", key: "avatar")
  lessonLearned {
    session
    text
  }
}
    `;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
      }
export function useUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;

export function queryUserProfile(client: Apollo.ApolloClient<unknown>, variables?: UserProfileQueryVariables | undefined, options?: Omit<Apollo.QueryOptions<UserProfileQuery, UserProfileQueryVariables>, "query" | 'variables'>) {
  return client.query<UserProfileQuery, UserProfileQueryVariables>({
    query: UserProfileDocument,
    variables,
    ...options,
  });
}

export const UploadAvatarDocument = gql`
    mutation UploadAvatar {
  uploadResumableAsset(
    input: {context: "user", key: "avatar", fileName: "avatar.jpeg", uploadType: IMAGE}
  ) {
    resumableUploadAssetURI
  }
}
    `;

/**
 * __useUploadAvatarMutation__
 *
 * To run a mutation, you first call `useUploadAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAvatarMutation, { data, loading, error }] = useUploadAvatarMutation({
 *   variables: {
 *   },
 * });
 */
export function useUploadAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UploadAvatarMutation, UploadAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadAvatarMutation, UploadAvatarMutationVariables>(UploadAvatarDocument, options);
      }
export type UploadAvatarMutationHookResult = ReturnType<typeof useUploadAvatarMutation>;

export function performUploadAvatar(client: Apollo.ApolloClient<unknown>, variables?: UploadAvatarMutationVariables | undefined, options?: Omit<Apollo.MutationOptions<UploadAvatarMutation, UploadAvatarMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<UploadAvatarMutation, UploadAvatarMutationVariables>({
    mutation: UploadAvatarDocument,
    variables,
    ...options,
  });
}
