import isEqual from 'lodash/isEqual';
import { ReactNode } from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import { AccessibleInput } from '@oui/app-core/src/hooks/useAccessibleInput';

import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useTheme } from '@src/styles';

type Props<T> = {
  disabled?: boolean;
  items: { label: string; value: T; key?: string }[];
  onChangeValue?: (value: T) => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  value?: T | null;
} & (
  | {
      label: string;
      accessibilityLabel?: string;
    }
  | {
      label?: () => ReactNode;
      accessibilityLabel: string;
    }
);

export function PillGroup<T>({ disabled, items, onChangeValue, value, ...props }: Props<T>) {
  const { Color } = useTheme();

  return (
    <AccessibleInput {...props}>
      {(accessibleProps) => (
        <View
          {...accessibleProps}
          accessibilityRole="radiogroup"
          spacing={15}
          row
          style={{ justifyContent: 'space-evenly' }}
        >
          {items.map((item) => {
            const selected = isEqual(value, item.value);
            return (
              <TouchableOpacity
                testID={props.testID ? `${props.testID}_${item.value}` : undefined}
                key={item.key || item.label}
                disabled={disabled}
                accessibilityRole="radio"
                onPress={() => onChangeValue?.(item.value)}
                accessibilityState={{
                  selected,
                }}
                style={[
                  { paddingHorizontal: 14, paddingVertical: 12, borderRadius: 10 },
                  selected ? { backgroundColor: '#f8e9e9' } : null,
                ]}
              >
                <Text
                  text={item.label}
                  weight="semibold"
                  size={15}
                  color={selected ? Color.accent : Color.styleGuide.Gray4}
                />
              </TouchableOpacity>
            );
          })}
        </View>
      )}
    </AccessibleInput>
  );
}
