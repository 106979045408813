import { useNavigation, useRoute } from '@react-navigation/native';
import { Component, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Animated, Easing, Platform, SafeAreaView, StatusBar } from 'react-native';
import RNAnimated, { Easing as RNEasing, SlideInDown, SlideOutDown } from 'react-native-reanimated';

import NotConnected from '@oui/app-core/src/assets/notconnected.svg';
import { ContentType, ProductVariant } from '@oui/lib/src/types/graphql.generated';

import { Button } from '@src/components/Button';
import { ChatV2 } from '@src/components/Chat';
import { ChatInputV2 } from '@src/components/ChatInput';
import { SessionTab, SessionTabs } from '@src/components/Session/Tabs/Tabs';
import { VideosActivities } from '@src/components/Session/Tabs/VideosActivities';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { ChatSideEffectContext, SideEffect } from '@src/hooks/useChatSideEffect';
import { ClientStatus, useConversation } from '@src/hooks/useConversation';
import {
  useCurrentPatient,
  useProgressByContent,
  useProgressFetcher,
} from '@src/hooks/useCurrentUser';
import { useI18n } from '@src/lib/i18n';
import { logEvent } from '@src/lib/log';
import Sentry from '@src/sentry';
import { Shadow, useTheme } from '@src/styles';
import { ActionEnvelope, Actor, Kind, OnInputCallback, StackScreenProps } from '@src/types';

import { useCurrentUserIdQuery } from './Conversation.graphql.generated';

type Props = Partial<StackScreenProps<'Conversation'>> & { _cmsEmbed?: true };
type ActionEnvelopeExtraFields = { ID: string; from: Actor; t: number | string };

function ConversationV2Inner(props: Props) {
  const navigation = useNavigation<StackScreenProps<'Conversation'>['navigation']>();
  const route = useRoute<StackScreenProps<'Conversation'>['route']>();
  const { scheme, Color } = useTheme();

  const { data } = useCurrentUserIdQuery();
  const translateY = useRef(new Animated.Value(0));
  const [inputHeight, setInputHeight] = useState(0);
  const fetchProgress = useProgressFetcher();

  const userID = data?.currentUser?.ID;
  const convoID = route.params?.ID;
  const completed = route.params?.completed === 'true';

  useEffect(() => {
    logEvent('view_session', { session: convoID });
  }, [convoID]);

  const handleSideEffect = (effect: SideEffect) => {
    if (effect.kind === 'navigate') {
      if (effect.routeName === 'home') {
        if (!props._cmsEmbed) {
          fetchProgress();
          navigation.goBack();
        }
      } else {
        // @ts-expect-error we don't have precise enough types for routeName/params so we just have to trust
        // the SideEffect
        navigation.navigate(effect.routeName, effect.params);
      }
    }
  };

  const { checkNetworkStatus, status, messages, send } = useConversation(
    userID,
    convoID,
    completed,
    handleSideEffect,
  );
  const [ignoreNetworkError, setIgnoreNetworkError] = useState(false);

  useLayoutEffect(() => {
    setIgnoreNetworkError(false);
  }, [status]);

  const handleInput: OnInputCallback<{ ID: string; from?: Actor }> = (payload) => {
    send({
      ...payload,
      t: Date.now(),
    } as ActionEnvelope & ActionEnvelopeExtraFields);
  };

  const hasEnvelopes = !!(messages.input.length || messages.chat.length);

  function onInputChangeHeight(height: number) {
    setInputHeight(height);
    Animated.timing(translateY.current, {
      easing: Easing.inOut(Easing.ease),
      useNativeDriver: true,
      toValue: -height,
      duration: 200,
    }).start();
  }

  const lastChatEnvelope = messages.chat[messages.chat.length - 1];
  return (
    <>
      <SafeAreaView style={{ flex: 1 }} testID="Conversation">
        <ChatSideEffectContext.Provider value={handleSideEffect}>
          <View style={{ flex: 1 }}>
            <Animated.View
              style={[
                {
                  flex: 1,
                  zIndex: 1,
                  backgroundColor:
                    scheme === 'dark' ? Color.backgroundColor : Color.styleGuide.Gray8,
                  transform: [{ translateY: translateY.current }],
                },
                Platform.OS === 'ios'
                  ? // on iOS translateY causes the ChatV2 component to be rendered at a smaller Y value
                    // (above) than the screen header.
                    // This results in VoiceOver ordering elements in the following manner:
                    // * Last chat message
                    // * Screen header elements
                    // * ChatInput
                    // rather than the order we actually want. By applying a marginTop, we "fix" the order
                    {
                      marginTop: inputHeight,
                    }
                  : null,
              ]}
            >
              <ChatV2 messages={messages.chat} hasEnvelopes={hasEnvelopes} />
            </Animated.View>
            {hasEnvelopes ? (
              <Animated.View
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 3,
                  transform: [
                    {
                      translateY: translateY.current.interpolate({
                        inputRange: [-inputHeight, 0],
                        outputRange: [0, inputHeight],
                        extrapolate: 'clamp',
                      }),
                    },
                  ],
                }}
              >
                <ChatInputV2
                  onInputChangeHeight={onInputChangeHeight}
                  onInput={handleInput}
                  curr={messages.input[messages.input.length - 1]}
                  lastTextWasUser={messages.chat[messages.chat.length - 1]?.from === Actor.User}
                  lastTextContent={
                    lastChatEnvelope?.kind === Kind.ChatText
                      ? lastChatEnvelope.props.text[0]?.replace(/\*/g, '')
                      : undefined
                  }
                />
              </Animated.View>
            ) : null}
          </View>
        </ChatSideEffectContext.Provider>
        {ignoreNetworkError ||
        [ClientStatus.CONNECTED || ClientStatus.UNKNOWN].includes(status) ? null : (
          <RNAnimated.View
            pointerEvents="box-none"
            style={{
              bottom: 0,
              height: 400,
              left: 0,
              position: 'absolute',
              right: 0,
              zIndex: 3,
              elevation: 10,
            }}
            entering={SlideInDown.duration(400).easing(RNEasing.in(RNEasing.ease))}
            exiting={SlideOutDown.duration(400).easing(RNEasing.in(RNEasing.ease))}
          >
            <View
              testID="Conversation_networkIssue"
              style={[
                Shadow.high,
                {
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  borderTopStartRadius: 20,
                  borderTopEndRadius: 20,
                  backgroundColor: Color.backgroundColor,
                  alignItems: 'center',
                  padding: 25,
                  paddingHorizontal: 45,
                },
              ]}
              spacing={8}
            >
              <NotConnected accessibilityLabel={undefined} />
              <Text text="You're offline" weight="bold" size={21} />
              <Text
                text="Aviva is having trouble connecting. Check your wifi or data connection."
                textAlign="center"
              />
              <View row spacing={24}>
                <Button
                  variant="text"
                  text="Dismiss"
                  onPress={() => {
                    setIgnoreNetworkError(true);
                  }}
                  style={{ marginVertical: 25 }}
                  testID="Converation_networkIssueDismissButton"
                />
                <Button
                  text="Retry"
                  onPress={() => {
                    Sentry.captureMessage('retry network connection');
                    return checkNetworkStatus();
                  }}
                  style={{ marginVertical: 25 }}
                />
              </View>
            </View>
          </RNAnimated.View>
        )}
      </SafeAreaView>
    </>
  );
}

const ConversationDisplayMode = (props: Props) => {
  const { user } = useCurrentPatient();
  const { data: progress } = useProgressByContent();
  const { $t } = useI18n();
  const navigation = useNavigation<StackScreenProps<'Conversation'>['navigation']>();
  const route = useRoute<StackScreenProps<'Conversation'>['route']>();
  const showTabs =
    user?.productVariant === ProductVariant.AVIVA_INPATIENT ||
    (user?.productVariant === ProductVariant.AVIVA_ADOLESCENT &&
      progress[route.params.ID as ContentType]?.completed);

  useEffect(() => {
    if (showTabs) {
      navigation.setOptions({ headerStyle: { shadowColor: 'transparent' } });
    }
  }, [showTabs, navigation]);

  if (showTabs) {
    const chatTab: SessionTab = {
      value: 'chat',
      text: $t({ id: 'Conversation_chatTabText', defaultMessage: 'Chat' }),
      icon: 'chat-double',
      Component: <ConversationV2Inner {...props} />,
    };

    const videoTab: SessionTab = {
      value: 'cms-video',
      text: $t({ id: 'Conversation_videoTabText', defaultMessage: 'Videos & activities' }),
      icon: 'cms-video',
      Component: (
        <VideosActivities
          contentType={route.params.ID as ContentType}
          onComplete={navigation.goBack}
          testID="Conversation_videosAndActivitiesScrollView"
        />
      ),
    };

    return <SessionTabs defaultTab="chat" sessionTabs={[chatTab, videoTab]} />;
  }

  return <ConversationV2Inner {...props} />;
};

export class Conversation extends Component<Props, { hasError: boolean }> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    Sentry.withScope((scope) => {
      scope.setExtra('ConversationID', this.props.route?.params?.ID);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <View style={{ padding: 20 }}>
          <Text text="Something went wrong" />
        </View>
      );
    }

    return (
      <>
        <StatusBar barStyle={'dark-content'} translucent backgroundColor="#00000000" />
        <ConversationDisplayMode {...this.props} />
      </>
    );
  }
}
