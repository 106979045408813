// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SessionVideosQueryVariables = Types.Exact<{
  contentType: Types.Scalars['String']['input'];
  productVariant: Types.Scalars['String']['input'];
  productVersion: Types.Scalars['String']['input'];
}>;


export type SessionVideosQuery = { readonly sessionContentForApp?: { readonly __typename: 'SessionContentForApp', readonly contentTypes: ReadonlyArray<string>, readonly activities: ReadonlyArray<{ readonly name: Types.AppActivityName, readonly params?: CustomScalars.GQLJSONObject | null }>, readonly quizSetCollections: ReadonlyArray<{ readonly __typename: 'QuizSetCollection', readonly quizSetCollectionID: CustomScalars.GQLUUID, readonly name: string, readonly sets: ReadonlyArray<{ readonly __typename: 'QuizSet', readonly ID: string, readonly description: string, readonly name: string, readonly previewTime?: number | null, readonly quizSetID: string, readonly items: ReadonlyArray<{ readonly __typename: 'QuizSetItemChoice', readonly quizSetItemID: CustomScalars.GQLUUID } | { readonly __typename: 'QuizSetItemVideo', readonly url?: string | null, readonly quizSetItemID: CustomScalars.GQLUUID, readonly fileUpload: { readonly __typename: 'FileUpload', readonly fileName: string, readonly fileUploadID: CustomScalars.GQLUUID, readonly fileUploadType: string, readonly gcsPath: string, readonly mimeType: string } }>, readonly legacyItems: ReadonlyArray<{ readonly __typename: 'LegacyQuizSetItemMultipleChoiceQuestion' } | { readonly __typename: 'LegacyQuizSetItemSingleChoiceQuestion' } | { readonly __typename: 'LegacyQuizSetItemVideo', readonly props: { readonly url: string, readonly title?: string | null, readonly compulsory?: boolean | null } }>, readonly previewOptions?: { readonly fitMode?: Types.MuxFitMode | null, readonly height?: number | null, readonly time?: number | null } | null }> }> } | null };


export const SessionVideosDocument = gql`
    query SessionVideos($contentType: String!, $productVariant: String!, $productVersion: String!) {
  sessionContentForApp(
    query: {contentType: $contentType, productVariant: $productVariant, productVersion: $productVersion}
  ) {
    __typename
    activities {
      name
      params
    }
    quizSetCollections {
      __typename
      quizSetCollectionID
      name
      sets {
        __typename
        ID
        description
        items {
          __typename
          quizSetItemID
          ... on QuizSetItemVideo {
            fileUpload {
              __typename
              fileName
              fileUploadID
              fileUploadType
              gcsPath
              mimeType
            }
            url
          }
        }
        legacyItems {
          __typename
          ... on LegacyQuizSetItemVideo {
            props {
              url
              title
              compulsory
            }
          }
        }
        name
        previewOptions {
          fitMode
          height
          time
        }
        previewTime
        quizSetID
      }
    }
    contentTypes
  }
}
    `;

/**
 * __useSessionVideosQuery__
 *
 * To run a query within a React component, call `useSessionVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionVideosQuery({
 *   variables: {
 *      contentType: // value for 'contentType'
 *      productVariant: // value for 'productVariant'
 *      productVersion: // value for 'productVersion'
 *   },
 * });
 */
export function useSessionVideosQuery(baseOptions: Apollo.QueryHookOptions<SessionVideosQuery, SessionVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionVideosQuery, SessionVideosQueryVariables>(SessionVideosDocument, options);
      }
export function useSessionVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionVideosQuery, SessionVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionVideosQuery, SessionVideosQueryVariables>(SessionVideosDocument, options);
        }
export type SessionVideosQueryHookResult = ReturnType<typeof useSessionVideosQuery>;
export type SessionVideosLazyQueryHookResult = ReturnType<typeof useSessionVideosLazyQuery>;

export function querySessionVideos(client: Apollo.ApolloClient<unknown>, variables: SessionVideosQueryVariables, options?: Omit<Apollo.QueryOptions<SessionVideosQuery, SessionVideosQueryVariables>, "query" | 'variables'>) {
  return client.query<SessionVideosQuery, SessionVideosQueryVariables>({
    query: SessionVideosDocument,
    variables,
    ...options,
  });
}
