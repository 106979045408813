import { Component } from 'react';

import { SliderInput } from '@oui/app-core/src/components/SliderInput';
import { ChatInputBaseProps, ChatInputSliderProps, Kind } from '@oui/lib/src/types';

import { Button } from '@src/components/Button';
import { View } from '@src/components/View';

type Props = ChatInputBaseProps & ChatInputSliderProps;
type State = {
  currentValue: number;
};

export default class ChatInputSlider extends Component<Props, State> {
  static defaultProps = {
    step: 1,
    maxValue: 10,
    minValue: 0,
    onInput: () => {},
  };

  state: State = { currentValue: this.props.value || this.props.minValue };

  handleSubmit = () => {
    const { onInput } = this.props;
    if (onInput) {
      const value = this.state.currentValue;
      onInput({ kind: Kind.InputSlider, props: value });
    }
  };

  render() {
    const { style } = this.props;

    return (
      <View
        style={[
          {
            paddingTop: 8,
            paddingLeft: 25,
            paddingBottom: 8,
            paddingRight: 25,
          },
          style,
        ]}
      >
        <SliderInput {...this.props} onChangeValue={(v) => this.setState({ currentValue: v })} />
        <Button
          text="Choose"
          onPress={this.handleSubmit}
          testID="ChatInputSlider_submit"
          alignSelf="center"
          style={{ marginTop: 8 }}
        />
      </View>
    );
  }
}
