import { useNavigation } from '@react-navigation/native';
import batchPromises from 'batch-promises';
import * as FileSystem from 'expo-file-system';
import * as ImagePicker from 'expo-image-picker';
import * as MediaLibrary from 'expo-media-library';
import equals from 'fast-deep-equal';
import { produce } from 'immer';
import chunk from 'lodash/chunk';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Alert,
  Animated,
  Image,
  Keyboard,
  Platform,
  SectionList,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import { useDebounce } from 'use-debounce/lib';
import { v4 as uuid } from 'uuid';

import { Permissions, askAsync } from '@oui/app-core/src/lib/permissionsManager';
import { ImageObject, searchImages } from '@oui/lib/src/searchImages';

import HopeKitEmpty from '@src/assets/hopeKitEmpty.svg';
import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { Button } from '@src/components/Button';
import { classifyAsset } from '@src/components/ChatAsset';
import {
  ContextMenu,
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
} from '@src/components/ContextMenu';
import { DiaryTabs } from '@src/components/DiaryTabs';
import { Divider } from '@src/components/Divider';
import { HeaderButtons, HeaderItem } from '@src/components/HeaderButtons';
import { Icon } from '@src/components/Icon';
import { ImageTile, MultipleMediaLibrary } from '@src/components/MultipleMediaLibrary';
import { ScrollView } from '@src/components/ScrollView';
import { Swiper } from '@src/components/Swiper';
import { Text } from '@src/components/Text';
import { TextInput } from '@src/components/TextInput';
import { View } from '@src/components/View';
import { IOS_14_OR_GREATER, IS_PRODUCTION } from '@src/constants';
import { useAddAction } from '@src/hooks/practices';
import { useForm } from '@src/hooks/useForm';
import { useWindowDimensions } from '@src/hooks/useWindowDimensions';
import { getUploadUriForExpoAsset } from '@src/lib/getUploadUriForExpoAsset';
import { useI18n } from '@src/lib/i18n';
import { SessionUri, resumableUploadManager } from '@src/lib/resumableUploadManager';
import {
  useAddHopeKitImageMutation,
  useAddHopeKitQuoteMutation,
  useAddHopeKitVideoMutation,
} from '@src/screens/AddHopeKit.graphql.generated';
import Sentry from '@src/sentry';
import { Color as ColorObject, useTheme } from '@src/styles';
import { ActionType, StackScreenProps } from '@src/types';

const AnimatedSectionList: typeof SectionList = Animated.createAnimatedComponent(
  SectionList,
) as any;

const TOP_VIEW_HEIGHT = 375;
const TOP_STICKY_HEIGHT = 40;

type Quote = { ID: string; text: string; author?: string };
type HopeKitEntry<T> = T & { reason?: string };

resumableUploadManager.registerUriRefreshCallback(async (upload) => {
  if (upload.metaData) {
    const md = upload.metaData as MediaLibrary.AssetInfo;
    if ('id' in md) {
      const assetInfo = await MediaLibrary.getAssetInfoAsync(md.id);
      if (assetInfo) {
        const { uri } = await getUploadUriForExpoAsset({ asset: assetInfo });
        return uri;
      }
    }
  }
  return null;
});

export function QuoteBox(props: { quote: Quote; style?: StyleProp<ViewStyle>; preview?: boolean }) {
  return (
    <View
      style={[
        {
          borderWidth: 9,
          borderColor: '#1b9fa0',
          width: '100%',
          padding: props.preview ? 10 : '15%',
          backgroundColor: '#ecffff',
        },
        props.style,
      ]}
    >
      <View
        spacing={props.preview ? 6 : 18}
        style={{ overflow: 'hidden', height: '100%', width: '100%' }}
      >
        <Icon name="quotes" size={props.preview ? 20 : 42} color="#086365" />
        <Text
          text={props.quote.text}
          size={props.preview ? 12 : 21}
          color="#086365"
          weight="semibold"
        />
        {props.quote.author ? (
          <Text
            text={props.quote.author}
            size={props.preview ? 10 : 17}
            color="#086365"
            style={{ marginTop: 12 }}
          />
        ) : null}
      </View>
    </View>
  );
}

function EditQuote(props: {
  value: Quote;
  onChangeValue: (value: Quote) => void;
  onRemove: () => void;
}) {
  const { clear, data, bind } = useForm(props.value);
  const onChangeValueRef = useRef(props.onChangeValue);
  onChangeValueRef.current = props.onChangeValue;
  const { $t } = useI18n();

  useEffect(() => {
    if (!equals(data, props.value)) {
      const newData = data.ID ? data : { ...data, ID: uuid() };
      onChangeValueRef.current(newData);
    }
  }, [props.value, data]);

  return (
    <View spacing={20}>
      <View>
        <TextInput
          multiline
          {...bind('text', {
            label: $t({
              id: 'AddHopeKit_editQuote_textLabel',
              defaultMessage: 'Quote',
            }),
          })}
          placeholder={$t({
            id: 'AddHopeKit_editQuote_textPlaceholder',
            defaultMessage: "What's the quote?",
          })}
          // onBlur={submit}
          inputStyle={{ minHeight: 100 }}
        />
        {props.value.ID ? (
          <View style={{ position: 'absolute', right: 0, top: 0 }}>
            <Icon
              name="close"
              size={14}
              onPress={() => {
                props.onRemove();
                clear();
              }}
              accessibilityLabel={$t({
                id: 'AddHopeKit_editQuote_removeButton',
                defaultMessage: 'Remove',
              })}
            />
          </View>
        ) : null}
      </View>
      <TextInput
        placeholder={$t({
          id: 'AddHopeKit_editQuote_authorPlaceholder',
          defaultMessage: 'Who said this quote?',
        })}
        {...bind('author', {
          label: $t({
            id: 'AddHopeKit_editQuote_authorLabel',
            defaultMessage: 'Author',
          }),
        })}
      />
    </View>
  );
}

function renderPreview({ item, small }: { item: SwiperItemType; small?: boolean }) {
  switch (item.type) {
    case 'imageSearch': {
      return (
        <Image
          source={{ uri: item.value.thumbnailUrl }}
          style={{ width: '100%', height: '100%' }}
          resizeMode="cover"
        />
      );
    }
    case 'asset': {
      const { isVideo } = classifyAsset(item.value.uri);
      return (
        <View style={{ width: '100%', height: '100%' }}>
          {isVideo ? (
            <View
              style={{
                zIndex: 1,
                ...StyleSheet.absoluteFillObject,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 75,
                  height: 75,
                  backgroundColor: 'rgba(255,255,255,0.8)',
                  borderRadius: 75 / 2,
                }}
              >
                <Icon color={ColorObject.accent} name="play" size={40} />
              </View>
            </View>
          ) : null}
          <Image
            source={{ uri: item.value.uri }}
            resizeMode="cover"
            style={{ width: '100%', height: '100%' }}
          />
        </View>
      );
    }
    case 'quote': {
      return <QuoteBox quote={item.value} style={{ height: '100%' }} preview={small} />;
    }
  }
}

export type SwiperItemType =
  | { type: 'asset'; value: HopeKitEntry<MediaLibrary.AssetInfo> }
  | { type: 'imageSearch'; value: HopeKitEntry<ImageObject> }
  | { type: 'quote'; value: HopeKitEntry<Quote> };

export function AddHopeKit() {
  const { $t } = useI18n();
  const { height, width } = useWindowDimensions();
  const { navigate, goBack, setOptions } =
    useNavigation<StackScreenProps<'AddHopeKit'>['navigation']>();
  const scrollY = useRef(new Animated.Value(0));
  const [selections, setSelections] = useState<{
    assetInfos: HopeKitEntry<MediaLibrary.AssetInfo>[];
    imageSearch: HopeKitEntry<ImageObject>[];
    quotes: HopeKitEntry<Quote>[];
  }>({ assetInfos: [], imageSearch: [], quotes: [] });
  const [activeTab, setActiveTab] = useState<'gallery' | 'imageSearch' | 'quote'>('gallery');
  const [currentStep, setCurrentStep] = useState<'select' | 'reasons'>('select');
  const [currentReasonsIndex, setCurrentReasonsIndex] = useState(0);
  const { Color } = useTheme();
  const [imageSearch, setImageSearch] = useState('');
  const [imageSearchResults, setImageSearchResults] = useState<ImageObject[]>([]);
  const [imageSearchDebounced] = useDebounce(imageSearch, 300);
  const [addHopeKitImage] = useAddHopeKitImageMutation();
  const [addHopeKitVideo] = useAddHopeKitVideoMutation();
  const [addHopeKitQuote] = useAddHopeKitQuoteMutation();
  const [addAction] = useAddAction();

  const [granted, setGranted] = useState<'all' | 'limited' | false | undefined>();
  const requestPermission = useCallback(() => {
    askAsync(Permissions.MEDIA_LIBRARY, () => MediaLibrary.requestPermissionsAsync(), {
      retryIfDenied: false,
    }).then((result) => {
      Sentry.addBreadcrumb({
        type: 'media-library',
        message: 'requestPermissionsAsync result',
        data: result,
      });

      const accessPrivileges =
        Platform.OS === 'ios' && IOS_14_OR_GREATER
          ? result.accessPrivileges
          : result.granted
          ? 'all'
          : 'none';
      if (accessPrivileges === 'all') {
        setGranted('all');
      } else if (accessPrivileges === 'limited') {
        MediaLibrary.presentPermissionsPickerAsync().then(() => {
          setGranted('limited');
        });
      } else if (result.canAskAgain) {
        Alert.alert(
          $t({ id: 'AddHopeKit_requestPermissionTitle', defaultMessage: 'Permissions requested' }),
          $t({
            id: 'AddHopeKit_requestPermissionDescription',
            defaultMessage: 'Please accept permissions in order to select photos or videos.',
          }),
          [
            {
              text: $t({
                id: 'AddHopeKit_requestPermissionRetryButton',
                defaultMessage: 'Ask again',
              }),
              onPress: () => requestPermission(),
            },
            {
              text: $t({
                id: 'AddHopeKit_requestPermissionCancelButton',
                defaultMessage: 'Not now',
              }),
              onPress: () => {},
              style: 'cancel',
            },
          ],
          { cancelable: false },
        );
      } else {
        setGranted(false);
      }
    });
  }, [$t]);

  useEffect(() => {
    MediaLibrary.getPermissionsAsync().then((result) => {
      if (IOS_14_OR_GREATER && result.canAskAgain && result.accessPrivileges !== 'all') {
        setGranted('limited');
      } else {
        requestPermission();
      }
    });
  }, [requestPermission]);

  useEffect(() => {
    const currentGranted = granted;
    const listener = MediaLibrary.addListener(() => {
      setGranted(undefined);
      setGranted(currentGranted);
    });
    return () => listener.remove();
  }, [granted]);

  const [searchingImages, setSearchingImages] = useState(false);
  useEffect(() => {
    if (imageSearchDebounced) {
      setSearchingImages(true);
      searchImages(imageSearchDebounced).then((result) => {
        setSearchingImages(false);
        if (result && result._type === 'Images') {
          setImageSearchResults(
            result.value.filter((res) =>
              // on iOS NSAppTransportSecurity setting prevents us from loading any http content
              // so we ensure our results are https only
              res.contentUrl
                ? res.contentUrl.startsWith('https://')
                : res.thumbnailUrl?.startsWith('https://'),
            ),
          );
        } else if (result?._type === 'ErrorResponse') {
          Sentry.captureMessage('searchImages error', { extra: result });
        }
      });
    }
  }, [imageSearchDebounced]);

  const { swiperItems, editSwiperItemReason } = useMemo(() => {
    return {
      swiperItems: [
        ...selections.assetInfos.map((value) => ({ type: 'asset', value })),
        ...selections.imageSearch.map((value) => ({ type: 'imageSearch', value })),
        ...selections.quotes.map((value) => ({ type: 'quote', value })),
      ] as Array<SwiperItemType>,
      editSwiperItemReason: (index: number, reason: string) => {
        let category: keyof typeof selections = 'assetInfos';
        if (index >= selections.assetInfos.length) {
          category = 'imageSearch';
          index = index - selections.assetInfos.length;
          if (index >= selections.imageSearch.length) {
            category = 'quotes';
            index = index - selections.imageSearch.length;
          }
        }
        const newSelections = produce(selections, (draft) => {
          draft[category][index].reason = reason;
        });
        setSelections(newSelections);
      },
    };
  }, [selections]);

  const addItems = useCallback(async () => {
    try {
      await batchPromises(3, swiperItems, async (item) => {
        try {
          switch (item.type) {
            case 'asset': {
              const {
                uri: finalUri,
                mimeType,
                mimeTypePrefix,
              } = await getUploadUriForExpoAsset({
                asset: item.value,
              });
              return mimeTypePrefix === 'image'
                ? addHopeKitImage({
                    variables: {
                      input: {
                        hopeKitImage: {
                          reason: item.value.reason,
                        },
                        uploadContentType: mimeType,
                      },
                    },
                  }).then((r) => {
                    if (r.data) {
                      const uploadSessionUri = r.data.addHopeKitAsset.uploadSession.url;
                      resumableUploadManager.uploadFile(finalUri, uploadSessionUri as SessionUri, {
                        cacheKey: r.data.addHopeKitAsset.hopeKitImage.hopeKitItemID,
                        metaData: item,
                      });
                    }
                  })
                : addHopeKitVideo({
                    variables: {
                      input: {
                        hopeKitVideo: {
                          reason: item.value.reason,
                        },
                        uploadContentType: mimeType,
                      },
                    },
                  }).then((r) => {
                    if (r.data) {
                      const uploadSessionUri = r.data.addHopeKitAsset.uploadSession.url;
                      resumableUploadManager.uploadFile(finalUri, uploadSessionUri as SessionUri, {
                        cacheKey: r.data.addHopeKitAsset.hopeKitVideo.hopeKitItemID,
                        metaData: item,
                      });
                    }
                  });
            }
            case 'imageSearch': {
              if (Platform.OS === 'web') {
                Sentry.captureException('AddHopeKit imageSearch not supported on web');
                return;
              }
              const extension = item.value.encodingFormat ?? 'jpg';

              let fallbackToThumbnail = false;
              const source = item.value.contentUrl ?? item.value.thumbnailUrl!;
              const destination =
                FileSystem.cacheDirectory +
                `hopeKitImageSearchDownload-${item.value.imageId}.${extension}`;

              return FileSystem.downloadAsync(source, destination)
                .then(async ({ uri }) => {
                  const preview = await FileSystem.readAsStringAsync(destination, {
                    length: 1,
                    position: 0,
                    encoding: 'base64',
                  });
                  // check if the first byte of the file is angle bracket: <
                  // If so, we likely got an HTML response from the server due to cloudflare captcha
                  // or other server issue. In that case, fallback to downloading the thumbnailUrl
                  if (preview === 'PA==') {
                    fallbackToThumbnail = true;
                    return FileSystem.downloadAsync(item.value.thumbnailUrl!, destination);
                  } else {
                    return { uri };
                  }
                })
                .then(({ uri }) => {
                  return addHopeKitImage({
                    variables: {
                      input: {
                        hopeKitImage: {
                          reason: item.value.reason,
                        },
                        uploadContentType: `image/${extension}`,
                      },
                    },
                  }).then((r) => {
                    if (r.data) {
                      const uploadSessionUri = r.data.addHopeKitAsset.uploadSession.url;
                      resumableUploadManager.uploadFile(uri, uploadSessionUri as SessionUri, {
                        cacheKey: r.data.addHopeKitAsset.hopeKitImage.hopeKitItemID,
                        metaData: produce(item, (draft) => {
                          if (fallbackToThumbnail) {
                            draft.value.contentUrl = item.value.thumbnailUrl;
                          }
                        }),
                      });
                    }
                  });
                });
            }
            case 'quote': {
              return addHopeKitQuote({
                variables: {
                  input: {
                    hopeKitQuote: {
                      text: item.value.text,
                      reason: item.value.reason,
                      author: item.value.author,
                    },
                  },
                },
              });
            }
          }
        } catch (e) {
          Sentry.captureException(e, {
            extra: IS_PRODUCTION ? { item: { type: item.type } } : { item },
          });
        }
      });

      addAction({
        actionType: ActionType.HOPE_KIT_ADD,
      });
      navigate({
        name: 'HopeKit',
        params: {},
        merge: true, // to preserve artifact request param
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [navigate, addAction, swiperItems, addHopeKitImage, addHopeKitVideo, addHopeKitQuote]);

  const nextReason = useCallback(() => {
    const isLastItem = currentReasonsIndex === swiperItems.length - 1;
    if (isLastItem) {
      return addItems();
    } else {
      setCurrentReasonsIndex((i) => Math.min(i + 1, swiperItems.length - 1));
    }
    return;
  }, [swiperItems.length, addItems, currentReasonsIndex]);

  const prevReason = useCallback(() => {
    if (currentReasonsIndex === 0) {
      setCurrentStep('select');
    } else {
      setCurrentReasonsIndex((i) => Math.max(0, i - 1));
    }
  }, [currentReasonsIndex]);

  const swiperItemsLength = swiperItems.length;
  useEffect(() => {
    if (currentStep === 'select') {
      setOptions({
        headerLeft: () => {
          return (
            <HeaderButtons>
              <HeaderItem
                iconName="close"
                title=""
                onPress={goBack}
                color={Color.accent}
                accessibilityLabel={$t({ id: 'AddHopeKit_backButton', defaultMessage: 'Close' })}
              />
            </HeaderButtons>
          );
        },
        headerRight: () => {
          return (
            <HeaderButtons>
              <Button
                text={$t({ id: 'AddHopeKit_nextButton', defaultMessage: 'Next' })}
                onPress={() => {
                  // Debugging info for https://sentry.io/organizations/oui/issues/2503301525/?environment=production&project=1512711&referrer=alert_email
                  Sentry.addBreadcrumb({
                    message: 'AddHopeKit advance to reasons',
                    data: { length: swiperItemsLength },
                  });
                  setCurrentStep('reasons');
                }}
                disabled={swiperItemsLength === 0}
                testID="AddHopeKit_nextButton"
                size="small"
              />
            </HeaderButtons>
          );
        },
      });
    } else {
      setOptions({
        headerLeft: () => {
          return (
            <HeaderButtons>
              <HeaderItem
                accessibilityLabel={$t({
                  id: 'AddHopeKit_previousButton',
                  defaultMessage: 'Previous',
                })}
                iconName="caret-left"
                title=""
                onPress={prevReason}
                color={Color.accent}
              />
            </HeaderButtons>
          );
        },
        headerRight: () => {
          return (
            <HeaderButtons>
              <Button
                text={$t({ id: 'AddHopeKit_nextButton', defaultMessage: 'Next' })}
                onPress={nextReason}
                testID="AddHopeKit_nextButton"
                size="small"
              />
            </HeaderButtons>
          );
        },
      });
    }
  }, [currentStep, setOptions, Color, nextReason, prevReason, goBack, swiperItemsLength, $t]);

  if (currentStep === 'reasons') {
    return (
      <ScrollView
        style={{ backgroundColor: Color.grayBackground, flex: 1 }}
        contentContainerStyle={{ flexGrow: 1, padding: 20 }}
      >
        <View style={{ height: 144, width: 144, marginBottom: 20, alignSelf: 'center' }}>
          {renderPreview({ item: swiperItems[currentReasonsIndex], small: true })}
        </View>
        <TextInput
          testID={`AddHopeKit_reasonInput_${currentReasonsIndex}`}
          label={$t({
            id: 'AddHopeKit_reasonLabel',
            defaultMessage: 'Why does this give me hope?',
          })}
          value={swiperItems[currentReasonsIndex].value.reason ?? ''}
          onChangeValue={(val) => {
            editSwiperItemReason(currentReasonsIndex, val);
          }}
          multiline
          inputStyle={{ minHeight: 140 }}
          placeholder={$t({
            id: 'AddHopeKit_reasonPlaceholder',
            defaultMessage:
              'Does this inspire you? Motivate you? Give you a reason to keep living?',
          })}
        />
        <Button
          variant="text"
          text={$t({ id: 'AddHopeKit_skipReasonButton', defaultMessage: 'Skip' })}
          onPress={nextReason}
          alignSelf="center"
        />
      </ScrollView>
    );
  }

  const selectedItem = swiperItems.length ? (
    <Swiper
      indicator="overlay"
      width={width}
      style={{ height: TOP_VIEW_HEIGHT }}
      data={swiperItems}
      renderItem={renderPreview}
    />
  ) : (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        backgroundColor: 'rgba(232, 174, 235, 0.15)',
      }}
    >
      <HopeKitEmpty accessibilityRole="none" accessibilityLabel={undefined} />
    </View>
  );

  return (
    <View style={{ flex: 1 }}>
      <Animated.View
        onStartShouldSetResponder={() => {
          Keyboard.dismiss();
          return false;
        }}
        style={{
          height: TOP_VIEW_HEIGHT,
          transform: [
            {
              translateY: scrollY.current.interpolate({
                inputRange: [0, TOP_VIEW_HEIGHT - TOP_STICKY_HEIGHT],
                outputRange: [0, -TOP_VIEW_HEIGHT + TOP_STICKY_HEIGHT],
                extrapolate: 'clamp',
              }),
            },
          ],
          zIndex: 2,
        }}
      >
        {selectedItem}
      </Animated.View>
      <MultipleMediaLibrary
        $t={$t}
        key={granted?.toString() ?? 'undefined'}
        badgeColor={Color.accent}
        callback={async ([assetInfo]) => {
          const newSelections = produce(selections, (draft) => {
            const existingIndex = draft.assetInfos.findIndex((info) => info.id === assetInfo.id);
            if (existingIndex === -1) {
              draft.assetInfos.push(assetInfo);
            } else {
              draft.assetInfos.splice(existingIndex, 1);
            }
          });
          setSelections(newSelections);
        }}
        max={10}
        render={({
          permissionGranted,
          sections,
          ListEmptyComponent,
          albums,
          selectedAlbumId,
          onSelectAlbumId,
          showDatePicker,
          dateFilterString,
          dateFilterAccessibilityLabel,
          ...props
        }) => (
          <View
            style={{
              paddingTop: TOP_STICKY_HEIGHT,
              position: 'absolute',
              height: '100%',
              width: '100%',
            }}
          >
            <AnimatedSectionList
              {...props}
              sections={
                (activeTab === 'gallery'
                  ? granted
                    ? sections
                    : [{ title: '', data: [[]] }]
                  : activeTab === 'imageSearch'
                  ? [{ title: '', data: chunk(imageSearchResults, 4) }]
                  : [
                      {
                        title: '',
                        data: selections.quotes.length ? selections.quotes : [{ text: '' }],
                      },
                    ]) as any
              }
              renderItem={
                activeTab === 'gallery'
                  ? granted
                    ? props.renderItem
                    : () => {
                        return (
                          <View style={{ padding: 20 }}>
                            {Platform.OS === 'web' ? (
                              <Button
                                alignSelf="center"
                                text={$t({
                                  id: 'AddHopeKit_chooseImageFileButton',
                                  defaultMessage: 'Choose image',
                                })}
                                onPress={async () => {
                                  const result = await ImagePicker.launchImageLibraryAsync({
                                    allowsMultipleSelection: true,
                                  });
                                  if (!result.canceled && result.assets) {
                                    const newSelections = produce(selections, (draft) => {
                                      result.assets.map((imageInfo) => {
                                        draft.assetInfos.push(imageInfo as any);
                                      });
                                    });
                                    setSelections(newSelections);
                                  }
                                }}
                                testID="AddHopeKit_chooseImageFileButton"
                              />
                            ) : typeof granted === 'undefined' ? (
                              <ActivityIndicator />
                            ) : (
                              <Button
                                testID="AddHopeKit_requestPermissionsButton"
                                alignSelf="center"
                                text={$t({
                                  id: 'AddHopeKit_requestPermissionsButton',
                                  defaultMessage: 'Allow media library access',
                                })}
                                onPress={() => {
                                  askAsync(
                                    Permissions.MEDIA_LIBRARY,
                                    () => MediaLibrary.requestPermissionsAsync(),
                                    {
                                      retryIfDenied: true,
                                    },
                                  ).then((result) => {
                                    if (
                                      Platform.OS === 'ios' &&
                                      result.accessPrivileges !== 'all'
                                    ) {
                                      requestPermission();
                                    }
                                  });
                                }}
                              />
                            )}
                          </View>
                        );
                      }
                  : ({
                      item: row,
                      index,
                    }: {
                      item: MediaLibrary.Asset[] | ImageObject[] | Quote;
                      index: number;
                    }) => {
                      if (!Array.isArray(row)) {
                        return (
                          <View style={{ padding: 20 }} testID={`AddHopeKit_editQuote_${index}`}>
                            <EditQuote
                              onRemove={() => {
                                const newSelections = produce(selections, (draft) => {
                                  draft.quotes.splice(index, 1);
                                });
                                setSelections(newSelections);
                              }}
                              value={row}
                              onChangeValue={(quote) => {
                                const newSelections = produce(selections, (draft) => {
                                  draft.quotes[index] = quote;
                                });
                                setSelections(newSelections);
                              }}
                            />
                          </View>
                        );
                      }

                      return (
                        <View row style={{ backgroundColor: 'white' }}>
                          {(row as ImageObject[]).map((item, i) => {
                            const existingIndex = selections.imageSearch.findIndex(
                              (info) => info.imageId === item.imageId,
                            );
                            const isSelected = existingIndex !== -1;
                            return (
                              <ImageTile
                                key={item.imageId}
                                item={{ uri: item.thumbnailUrl! } as any}
                                selectImage={async () => {
                                  const newSelections = produce(selections, (draft) => {
                                    if (existingIndex === -1) {
                                      draft.imageSearch.push(item);
                                    } else {
                                      draft.imageSearch.splice(existingIndex, 1);
                                    }
                                  });
                                  setSelections(newSelections);
                                  return { isAllowed: true };
                                }}
                                badgeColor={Color.accent}
                                index={index + i}
                                selected={isSelected}
                                selectedItemCount={selections.assetInfos.length + existingIndex + 1}
                              />
                            );
                          })}
                        </View>
                      );
                    }
              }
              keyExtractor={
                activeTab === 'gallery'
                  ? props.keyExtractor
                  : (item: MediaLibrary.Asset[] | ImageObject[] | Quote, index: number) => {
                      if (Array.isArray(item)) {
                        return (item as ImageObject[]).map((i) => i.imageId).join('-');
                      }
                      return index.toString();
                    }
              }
              ListFooterComponent={
                activeTab === 'quote' ? (
                  <>
                    <View
                      style={{ padding: 20 }}
                      key={selections.quotes.length.toString()}
                      spacing={12}
                    >
                      <Button
                        alignSelf="center"
                        icon="plus"
                        variant="text"
                        text={$t({
                          id: 'AddHopeKit_addQuoteButton',
                          defaultMessage: 'Add another quote',
                        })}
                        onPress={() => {
                          const newSelections = produce(selections, (draft) => {
                            draft.quotes.push({ text: '', ID: uuid() });
                          });
                          setSelections(newSelections);
                        }}
                      />
                    </View>
                  </>
                ) : activeTab === 'gallery' && granted === 'limited' ? (
                  <View style={{ padding: 20 }}>
                    <Button
                      text={$t({
                        id: 'AddHopeKit_addImagesButton',
                        defaultMessage: 'Choose more photos',
                      })}
                      onPress={MediaLibrary.presentPermissionsPickerAsync}
                      alignSelf="center"
                    />
                  </View>
                ) : null
              }
              ItemSeparatorComponent={activeTab === 'quote' ? Divider : undefined}
              ListEmptyComponent={activeTab === 'gallery' ? ListEmptyComponent : undefined}
              stickySectionHeadersEnabled
              onScroll={Animated.event(
                [
                  {
                    nativeEvent: {
                      contentOffset: {
                        y: scrollY.current,
                      },
                    },
                  },
                ],
                { useNativeDriver: true },
              )}
              contentContainerStyle={{
                paddingTop: TOP_VIEW_HEIGHT - TOP_STICKY_HEIGHT,
                flexGrow: 1,
                minHeight: height + TOP_VIEW_HEIGHT - 100,
              }}
              renderSectionHeader={() => (
                <View style={{ paddingTop: 10, backgroundColor: 'white' }}>
                  <DiaryTabs
                    testID="AddHopeKit_tabs"
                    value={activeTab}
                    onChangeValue={setActiveTab}
                    items={[
                      {
                        text: $t({ id: 'AddHopeKit_tabs_gallery', defaultMessage: 'Gallery' }),
                        value: 'gallery',
                      },
                      {
                        text: $t({ id: 'AddHopeKit_tabs_imageSearch', defaultMessage: 'Search' }),
                        value: 'imageSearch',
                      },
                      {
                        text: $t({ id: 'AddHopeKit_tabs_quote', defaultMessage: 'Quote' }),
                        value: 'quote',
                      },
                    ]}
                    style={{ backgroundColor: 'white' }}
                  />
                  {activeTab === 'gallery' && granted ? (
                    <View
                      style={{
                        padding: 10,
                        paddingHorizontal: 40,
                        justifyContent: 'space-between',
                      }}
                      row
                    >
                      <Menu renderer={ContextMenu}>
                        <MenuTrigger
                          customStyles={{
                            triggerTouchable: {
                              accessibilityLabel: $t(
                                {
                                  id: 'AddHopeKit_albumFilterAccessibilityLabel',
                                  defaultMessage: 'Filter files. Current filter: {filter}',
                                },
                                { filter: albums.find((a) => a.id === selectedAlbumId)?.title },
                              ),
                            },
                          }}
                          testID="AddHopeKit_albumMenuTrigger"
                        >
                          <View row spacing={12}>
                            <Text
                              color={Color.styleGuide.Gray4}
                              text={albums.find((a) => a.id === selectedAlbumId)?.title ?? ''}
                              weight="semibold"
                            />
                            <Icon name="caret-down" color={Color.styleGuide.Gray4} size={10} />
                          </View>
                        </MenuTrigger>
                        <MenuOptions>
                          {albums.map((a, i) => (
                            <MenuOption
                              key={a.id}
                              onSelect={() => onSelectAlbumId(a.id)}
                              text={a.title}
                              customStyles={{
                                optionText: {
                                  fontFamily: 'OpenSansSemiBold',
                                },
                              }}
                              testID={`AddHopeKit_albumMenuOption__${i}`}
                            />
                          ))}
                        </MenuOptions>
                      </Menu>
                      <TouchableOpacity
                        onPress={showDatePicker}
                        accessibilityLabel={
                          dateFilterAccessibilityLabel ||
                          $t({
                            id: 'AddHopeKit_chooseDateFilterLabel',
                            defaultMessage: 'All dates',
                          })
                        }
                        accessibilityHint={$t({
                          id: 'AddHopeKit_chooseDateFilterHint',
                          defaultMessage: 'choose date range filter',
                        })}
                      >
                        <View row spacing={12}>
                          <Text
                            color={Color.styleGuide.Gray4}
                            weight="semibold"
                            text={
                              dateFilterString ||
                              $t({
                                id: 'AddHopeKit_dateFilterDefault',
                                defaultMessage: 'All dates',
                              })
                            }
                          />
                          <Icon name="calendar" color={Color.styleGuide.Gray4} size={18} />
                        </View>
                      </TouchableOpacity>
                    </View>
                  ) : activeTab === 'imageSearch' ? (
                    <View style={{ paddingHorizontal: 12 }}>
                      <View
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: 24,
                          transform: [{ translateY: -10 }],
                          zIndex: 2,
                        }}
                        importantForAccessibility="no-hide-descendants"
                        accessibilityElementsHidden
                      >
                        {searchingImages ? (
                          <ActivityIndicator />
                        ) : (
                          <Icon name="search" color={Color.styleGuide.Gray6} />
                        )}
                      </View>
                      <TextInput
                        autoFocus
                        value={imageSearch}
                        onChangeValue={setImageSearch}
                        inputStyle={{ paddingLeft: 36, paddingVertical: 12 }}
                        style={{ zIndex: 1 }}
                        testID="AddHopeKit_imageSearchInput"
                        accessibilityRole="search"
                      />
                    </View>
                  ) : null}
                </View>
              )}
            />
          </View>
        )}
        immediateCallback
        defaultToAllMediaAlbum
      />
    </View>
  );
}
