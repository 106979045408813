import { ReactNode } from 'react';
import { DimensionValue, StyleProp, ViewStyle } from 'react-native';

import { Markdown } from '@src/components/Markdown';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { Color } from '@src/styles';
import { ChatTableProps } from '@src/types';

function Cell(props: {
  header?: boolean;
  isFirstColumn: boolean;
  isFirstRow: boolean;
  markdown?: boolean;
  style?: StyleProp<ViewStyle>;
  node: ReactNode;
}) {
  return (
    <View
      flex={1}
      style={[
        {
          flexBasis: '100%',
          flexShrink: 1,
          padding: 10,
          paddingVertical: 16,
          backgroundColor: props.header ? '#EBF1ED' : undefined,
          borderLeftWidth: props.isFirstColumn ? 0 : 2,
          borderTopWidth: props.isFirstRow ? 0 : 2,
          borderColor: Color.secondary,
          alignItems: 'center',
          justifyContent: 'center',
        },
        props.style,
      ]}
    >
      {props.markdown ? (
        <Markdown fontFamily="OpenSansSemiBold">{props.node}</Markdown>
      ) : typeof props.node === 'string' ? (
        <Text text={props.node} weight={props.isFirstRow ? 'bold' : 'semibold'} size={14} />
      ) : (
        props.node
      )}
    </View>
  );
}

export function ChatTable({
  alignItems,
  flexBasis,
  markdown,
  data,
  borderColor,
  borderWidth,
  headerStyle,
  rowTestIDPrefix,
}: Omit<ChatTableProps, 'data'> & {
  flexBasis?: DimensionValue[];
  alignItems?: ViewStyle['alignItems'][];
  data: Array<Array<ReactNode | { config: { style?: StyleProp<ViewStyle> }; node: ReactNode }>>;
  borderColor?: string;
  borderWidth?: number;
  headerStyle?: StyleProp<ViewStyle>;
  rowTestIDPrefix?: string;
}) {
  return (
    <View style={{ flexGrow: 1, alignSelf: 'stretch' }}>
      {data.map((row, j) => (
        <View
          row
          key={j}
          flex={1}
          style={{ alignItems: 'stretch' }}
          testID={`${rowTestIDPrefix ?? 'ChatTable_row'}_${j}`}
        >
          {row.map((nodeOrConfigNode, i) => {
            const node =
              nodeOrConfigNode !== null &&
              typeof nodeOrConfigNode === 'object' &&
              'config' in nodeOrConfigNode
                ? nodeOrConfigNode.node
                : nodeOrConfigNode;
            const config =
              nodeOrConfigNode !== null &&
              typeof nodeOrConfigNode === 'object' &&
              'config' in nodeOrConfigNode
                ? nodeOrConfigNode.config
                : {};
            return (
              <Cell
                markdown={markdown}
                // eslint-disable-next-line
                key={typeof node === 'string' ? node || i : (node as any).key ?? i}
                node={node}
                header={j === 0}
                isFirstColumn={i === 0}
                style={[
                  {
                    flexBasis: flexBasis ? flexBasis[i] : i === row.length - 1 ? '170%' : '100%',
                    borderTopRightRadius: j === 0 && i === row.length - 1 ? 14 : 0,
                    borderTopLeftRadius: j === 0 && i === 0 ? 14 : 0,
                  },
                  j === 0 ? headerStyle : null,
                  alignItems?.[i]
                    ? {
                        alignItems: alignItems[i],
                      }
                    : null,
                  borderColor ? { borderColor } : null,
                  borderWidth
                    ? {
                        borderLeftWidth: i === 0 ? 0 : borderWidth,
                        borderTopWidth: j === 0 ? 0 : borderWidth,
                      }
                    : null,
                  config.style,
                ]}
                isFirstRow={j === 0}
              />
            );
          })}
        </View>
      ))}
    </View>
  );
}

export default ChatTable;
