// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExchangeActivationTokenQueryVariables = Types.Exact<{
  activationTokenValue: Types.Scalars['String']['input'];
}>;


export type ExchangeActivationTokenQuery = { readonly ouiUserEmailWithToken: string };


export const ExchangeActivationTokenDocument = gql`
    query ExchangeActivationToken($activationTokenValue: String!) {
  ouiUserEmailWithToken(value: $activationTokenValue)
}
    `;

/**
 * __useExchangeActivationTokenQuery__
 *
 * To run a query within a React component, call `useExchangeActivationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeActivationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeActivationTokenQuery({
 *   variables: {
 *      activationTokenValue: // value for 'activationTokenValue'
 *   },
 * });
 */
export function useExchangeActivationTokenQuery(baseOptions: Apollo.QueryHookOptions<ExchangeActivationTokenQuery, ExchangeActivationTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExchangeActivationTokenQuery, ExchangeActivationTokenQueryVariables>(ExchangeActivationTokenDocument, options);
      }
export function useExchangeActivationTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExchangeActivationTokenQuery, ExchangeActivationTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExchangeActivationTokenQuery, ExchangeActivationTokenQueryVariables>(ExchangeActivationTokenDocument, options);
        }
export type ExchangeActivationTokenQueryHookResult = ReturnType<typeof useExchangeActivationTokenQuery>;
export type ExchangeActivationTokenLazyQueryHookResult = ReturnType<typeof useExchangeActivationTokenLazyQuery>;

export function queryExchangeActivationToken(client: Apollo.ApolloClient<unknown>, variables: ExchangeActivationTokenQueryVariables, options?: Omit<Apollo.QueryOptions<ExchangeActivationTokenQuery, ExchangeActivationTokenQueryVariables>, "query" | 'variables'>) {
  return client.query<ExchangeActivationTokenQuery, ExchangeActivationTokenQueryVariables>({
    query: ExchangeActivationTokenDocument,
    variables,
    ...options,
  });
}
