// @ts-expect-error
import computeMd5 from 'blueimp-md5';
import { Asset } from 'expo-asset';
import * as FileSystem from 'expo-file-system';
import { Platform } from 'react-native';

// a workaround until https://github.com/expo/expo/pull/8222 is merged
export async function checkIfBareAssetExists(asset: Asset) {
  if (Platform.OS === 'web') return false;

  const { uri, hash, type } = asset;
  // TODO: does this make sense to bail out if it's already at a file URL
  // because it's already available locally?
  if (uri.startsWith('file://')) {
    return !!uri;
  }
  const cacheFileId = hash || computeMd5(uri);
  const localUri = `${FileSystem.cacheDirectory}ExponentAsset-${cacheFileId}.${type}`;
  const { exists } = await FileSystem.getInfoAsync(localUri);
  if (exists) {
    asset.localUri = localUri;
    asset.downloaded = true;
    asset._downloadCallbacks.forEach(({ resolve }) => resolve());
  }
  return exists;
}
