import { createContext, useContext } from 'react';

import { RootStackParamList } from '@src/types';

export type SideEffect = {} & {
  kind: 'navigate';
  routeName: keyof RootStackParamList;
  params?: object | undefined;
};

export const ChatSideEffectContext = createContext<(effect: SideEffect) => void>(() => {});

export function useChatSideEffect() {
  return useContext(ChatSideEffectContext);
}
