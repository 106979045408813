// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LearnQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LearnQuery = { readonly treatmentV2?: ReadonlyArray<{ readonly ordinal: number, readonly slug: string, readonly title: string, readonly subtitle?: string | null, readonly description: string, readonly progress: string }> | null };


export const LearnDocument = gql`
    query Learn {
  treatmentV2 {
    ordinal
    slug
    title
    subtitle
    description
    progress
  }
}
    `;

/**
 * __useLearnQuery__
 *
 * To run a query within a React component, call `useLearnQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearnQuery({
 *   variables: {
 *   },
 * });
 */
export function useLearnQuery(baseOptions?: Apollo.QueryHookOptions<LearnQuery, LearnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LearnQuery, LearnQueryVariables>(LearnDocument, options);
      }
export function useLearnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LearnQuery, LearnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LearnQuery, LearnQueryVariables>(LearnDocument, options);
        }
export type LearnQueryHookResult = ReturnType<typeof useLearnQuery>;
export type LearnLazyQueryHookResult = ReturnType<typeof useLearnLazyQuery>;

export function queryLearn(client: Apollo.ApolloClient<unknown>, variables?: LearnQueryVariables | undefined, options?: Omit<Apollo.QueryOptions<LearnQuery, LearnQueryVariables>, "query" | 'variables'>) {
  return client.query<LearnQuery, LearnQueryVariables>({
    query: LearnDocument,
    variables,
    ...options,
  });
}
