import { useRef, useState } from 'react';

import { ChatInputSliderProps } from '@oui/lib/src/types';

import { Slider } from '@src/components/Slider';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { Color } from '@src/styles';

type Props = ChatInputSliderProps;

export function SliderInput(
  props: Props & {
    accessibilityLabel?: string;
    testID?: string;
    onChangeValue: (value: number) => void;
    showMinMaxValues?: boolean;
  },
) {
  const [currentValue, setCurrentValue] = useState(props.value || props.minValue);
  const sliderRef = useRef<React.ElementRef<typeof Slider>>(null);

  return (
    <>
      <View
        accessible={true}
        accessibilityRole="adjustable"
        accessibilityLabel={
          props.minLabel && props.maxLabel
            ? `${props.accessibilityLabel ?? ''} Slider from ${props.minLabel} to ${props.maxLabel}`
            : undefined
        }
        accessibilityValue={{
          min: props.minValue,
          max: props.maxValue,
          now: currentValue,
        }}
        accessibilityActions={[
          { name: 'increment', label: 'increment' },
          { name: 'decrement', label: 'decrement' },
        ]}
        onAccessibilityAction={(event) => {
          switch (event.nativeEvent.actionName) {
            case 'increment': {
              const newValue = Math.min(currentValue + 1, props.maxValue);
              sliderRef.current?.setValue(newValue);
              setCurrentValue(newValue);
              props.onChangeValue(newValue);
              break;
            }
            case 'decrement': {
              const newValue = Math.max(currentValue - 1, props.minValue);
              sliderRef.current?.setValue(newValue);
              setCurrentValue(newValue);
              props.onChangeValue(newValue);
              break;
            }
          }
        }}
      >
        <View style={{ alignItems: 'center', marginBottom: 8 }}>
          <Text
            accessibilityLiveRegion="polite"
            accessibilityRole={props.labels?.[currentValue] ? undefined : 'none'}
            accessibilityLabel={
              props.labels?.[currentValue]
                ? `Current value: ${props.labels?.[currentValue]}`
                : undefined
            }
            text={props.labels?.[currentValue] || ' '}
            weight="semibold"
            size={17}
            color={Color.tertiary}
          />
        </View>
        <Slider
          ref={sliderRef}
          testID={props.testID ?? 'ChatInputSlider_slider'}
          step={props.step}
          minimumValue={props.minValue}
          maximumValue={props.maxValue}
          value={currentValue}
          onValueChange={(val: number) => {
            setCurrentValue(val);
            props.onChangeValue(val);
          }}
        />
      </View>
      {props.showMinMaxValues || props.displayStyle === 'both' || props.displayStyle === 'value' ? (
        <View
          row
          style={{ justifyContent: 'space-between', marginTop: -4 }}
          importantForAccessibility="no-hide-descendants"
          accessibilityElementsHidden
        >
          <Text text={props.minValue.toString()} size={15} color={Color.styleGuide.Gray3} />
          <Text text={props.maxValue.toString()} size={15} color={Color.styleGuide.Gray3} />
        </View>
      ) : null}

      {props.displayStyle === 'both' || props.displayStyle === 'label' ? (
        <View
          row
          style={{ justifyContent: 'space-between' }}
          importantForAccessibility="no-hide-descendants"
          accessibilityElementsHidden
        >
          <Text text={props.minLabel || ' '} size={15} color={Color.styleGuide.Gray3} />
          <Text text={props.maxLabel || ' '} size={15} color={Color.styleGuide.Gray3} />
        </View>
      ) : null}
    </>
  );
}
