import { useCallback, useEffect, useRef, useState } from 'react';
import { AccessibilityInfo, Platform, View, findNodeHandle } from 'react-native';

import { useAccessibilityContext } from '@oui/app-core/src/components/AccessibilityContext';

import { ChatInputArtifact } from '@src/components/ChatInputArtifact';
import ChatInputButton from '@src/components/ChatInputButton';
import ChatInputCarousel from '@src/components/ChatInputCarousel';
import InputChoice from '@src/components/ChatInputChoice';
import ChatInputContactsPicker from '@src/components/ChatInputContactsPicker';
import ChatInputMediaPicker from '@src/components/ChatInputMediaPicker';
import ChatInputMultipleChoice from '@src/components/ChatInputMultipleChoice';
import ChatInputSignature from '@src/components/ChatInputSignature';
import ChatInputSingleChoice from '@src/components/ChatInputSingleChoice';
import ChatInputSlider from '@src/components/ChatInputSlider';
import InputText from '@src/components/ChatInputText';
import ChatInputTimePicker from '@src/components/ChatInputTimePicker';
import { useTheme } from '@src/styles';
import { Actor, InputEnvelope, Kind, OnInputCallback } from '@src/types';

const EMPTY_ENVELOPE: InputEnvelope = {
  t: 0,
  ID: '',
  kind: Kind.NULL,
  props: {},
  from: Actor.NULL,
};

export function ChatInputV2(props: {
  onInputChangeHeight: (height: number) => void;
  curr?: InputEnvelope;
  onInput: OnInputCallback<{ ID: string }>;
  lastTextWasUser: boolean;
  lastTextContent?: string;
}) {
  const { Color } = useTheme();
  const [active, setActive] = useState(!!props.curr);
  const inactiveTimeoutRef = useRef(0 as unknown as NodeJS.Timeout);
  const { announceForAccessibility } = useAccessibilityContext();
  const ref = useRef<View>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (props.curr) {
      clearTimeout(inactiveTimeoutRef.current);
      inactiveTimeoutRef.current = 0 as unknown as NodeJS.Timeout;

      setActive(true);
      if (ref.current) {
        const handle = findNodeHandle(ref.current!);
        if (handle) {
          AccessibilityInfo.setAccessibilityFocus(handle);
        }
      }
    }
  }, [props.curr, announceForAccessibility]);

  const setInactive = useCallback(() => {
    // clear remaining timeout if it hasn't yet run and set setInactive for other reasons
    clearTimeout(inactiveTimeoutRef.current);
    inactiveTimeoutRef.current = 0 as unknown as NodeJS.Timeout;
    setActive(false);
  }, []);

  const curr = props.curr || EMPTY_ENVELOPE;
  const input: OnInputCallback = (e) => {
    props.onInput({ ID: curr.ID, ...e });
    inactiveTimeoutRef.current = setTimeout(
      () => {
        setInactive();
      },
      Platform.OS === 'web' && global.e2e ? 0 : 500,
    );
  };

  useEffect(() => {
    if (props.lastTextWasUser && active && inactiveTimeoutRef.current) {
      setInactive();
    }
  }, [active, props.lastTextWasUser, setInactive]);

  let el;
  if (active) {
    switch (curr.kind) {
      case Kind.InputText:
        el = <InputText {...curr.props} onInput={input} lastTextContent={props.lastTextContent} />;
        break;
      case Kind.InputChoice:
        el = (
          <InputChoice {...curr.props} onInput={input} lastTextContent={props.lastTextContent} />
        );
        break;
      case Kind.InputSingleChoice:
        el = (
          <ChatInputSingleChoice
            {...curr.props}
            onInput={input}
            lastTextContent={props.lastTextContent}
          />
        );
        break;
      case Kind.InputMultipleChoice:
        el = (
          <ChatInputMultipleChoice
            {...curr.props}
            onInput={input}
            lastTextContent={props.lastTextContent}
          />
        );
        break;
      case Kind.InputButton:
        el = <ChatInputButton {...curr.props} onInput={input} />;
        break;
      case Kind.InputSlider:
        el = <ChatInputSlider {...curr.props} onInput={input} />;
        break;
      case Kind.InputCarousel:
        return (
          <View style={{ backgroundColor: '#eee' }}>
            <ChatInputCarousel {...curr.props} onInput={input} />
          </View>
        );
      case Kind.InputTimePicker:
        el = <ChatInputTimePicker {...curr.props} onInput={input} />;
        break;
      case Kind.InputContactsPicker:
        el = <ChatInputContactsPicker {...curr.props} onInput={input} />;
        break;
      case Kind.InputSignature:
        el = <ChatInputSignature {...curr.props} onInput={input} />;
        break;
      case Kind.InputMediaPicker:
        el = <ChatInputMediaPicker {...curr.props} onInput={input} />;
        break;
      case Kind.InputArtifact:
        el = <ChatInputArtifact {...curr.props} onInput={input} />;
        break;
    }
  } else {
    el = null;
  }

  return (
    <View
      style={[
        {
          elevation: 10,
          shadowRadius: 3,
          shadowOpacity: 0.1,
          shadowOffset: { width: 0, height: -3 },
          backgroundColor: Color.backgroundColor,
          zIndex: 2,
        },
        el
          ? {
              minHeight: 48,
            }
          : {
              borderBottomWidth: 1,
              borderBottomColor: '#dddddd',
            },
      ]}
      onLayout={(e) => {
        if (e.nativeEvent.layout.height !== height) {
          setHeight(e.nativeEvent.layout.height);
          props.onInputChangeHeight(e.nativeEvent.layout.height);
        }
      }}
    >
      <View
        ref={ref}
        accessible
        accessibilityLabel="Chat input"
        accessibilityRole="header"
        // We need an accessible ref to focus on when chat input is activated. However, we cannot
        // use the parent View because on iOS it doesn't allow swiping to focus on children
        style={{ height: 5, width: '100%', marginBottom: -5 }}
      />
      {el}
    </View>
  );
}
