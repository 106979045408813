import { StyleProp, ViewStyle } from 'react-native';

import { useCurrentPatientID } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';

import { PatientSupporterCard } from '@src/components/PatientSupporterCard';
import { usePatientSupportersQuery } from '@src/components/PatientSupporters.graphql.generated';
import { Heading, Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { card } from '@src/styles';

export function PatientSupporters({ style }: { style?: StyleProp<ViewStyle> }) {
  const { $t } = useI18n();
  const patientID = useCurrentPatientID()!;

  const { data, loading } = usePatientSupportersQuery({
    variables: {
      patientID,
    },
    skip: !patientID,
  });

  if (loading || !data?.patientByPatientID?.supporters.length) {
    return null;
  }

  return (
    <View testID="PatientSupporters" style={style}>
      <View
        spacing={12}
        style={{
          marginBottom: 24,
        }}
      >
        <Heading
          level={2}
          text={$t({
            id: 'PatientSupporters_support',
            defaultMessage: 'Support',
          })}
        />
        <Text
          size={15}
          text={$t({
            id: 'PatientSupporters_title',
            defaultMessage:
              'Support people have their own app that describes what you are learning with Aviva.',
          })}
        />
      </View>
      <View>
        {data?.patientByPatientID?.supporters
          .filter((i) => i.claimed)
          .map(({ supporterID, supporter: { person }, relation }) => (
            <View
              testID={`PatientSupporters_supporter_${supporterID}`}
              key={supporterID}
              style={[card, { paddingHorizontal: 15, paddingVertical: 20, marginBottom: 20 }]}
              spacing={15}
            >
              <PatientSupporterCard
                name={`${person.givenName} ${person.familyName}`}
                relation={relation}
              />
            </View>
          ))}
      </View>
    </View>
  );
}
