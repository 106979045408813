import { useSessionVideosQuery } from '../Videos/Videos.graphql.generated';
import { SafeAreaView } from 'react-native';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { ScrollView } from '@src/components/ScrollView';
import { SessionActivities } from '@src/components/Session/Activities/Activities';
import { MarkComplete } from '@src/components/Session/MarkComplete/MarkComplete';
import { SessionVideos } from '@src/components/Session/Videos/Videos';
import { View } from '@src/components/View';
import { useCurrentPatient } from '@src/hooks/useCurrentUser';
import { ContentType, ProductVariant, ProductVersion } from '@src/types';

export function VideosActivities<T extends string>({
  testID,
  contentType,
  onComplete,
}: {
  testID?: string;
  contentType: ContentType;
  onComplete: () => void;
}) {
  const { user } = useCurrentPatient();

  const productVariant = user?.productVariant || ProductVariant.AVIVA_INPATIENT;
  const productVersion = user?.productVersion || ProductVersion.AVIVA_2_0;
  const { loading } = useSessionVideosQuery({
    variables: {
      contentType,
      productVariant,
      productVersion,
    },
  });

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView testID={testID} contentContainerStyle={{ padding: 20 }}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <View spacing={50}>
            <SessionVideos contentType={contentType} />
            <SessionActivities contentType={contentType} />
            <MarkComplete contentType={contentType} onComplete={onComplete} />
          </View>
        )}
      </ScrollView>
    </SafeAreaView>
  );
}
