import { ComponentProps, useState } from 'react';

import { Icon } from '@src/components/Icon';
import { Tabs } from '@src/components/Tabs';
import { View } from '@src/components/View';

export type SessionTab = {
  icon?: ComponentProps<typeof Icon>['name'];
  text: string;
  value: string;
  disabled?: boolean;
  Component: JSX.Element;
};

export type TabsProps<T extends string> = {
  defaultTab: T;
  sessionTabs: Array<SessionTab>;
};

export function SessionTabs<T extends string>({ defaultTab, sessionTabs }: TabsProps<T>) {
  const [activeTab, setActiveTab] = useState<string>(defaultTab);

  return (
    <View testID="Session_tabs" flex={1}>
      <View
        style={{
          // zIndex required to prevent transformed ConversationInner view from covering tabs
          // when translated up due to ChatInput showing
          zIndex: 1,
          paddingTop: 10,
          backgroundColor: 'white',
        }}
      >
        <Tabs
          testID="Conversation_tabs"
          value={activeTab}
          onChangeValue={setActiveTab}
          items={sessionTabs}
        />
      </View>
      <View flex={1}>
        {sessionTabs
          .filter((t) => t.value === activeTab)
          .map(({ Component }, i) => (
            <View key={i} row style={{ flex: 1, alignItems: 'stretch' }}>
              {Component}
            </View>
          ))}
      </View>
    </View>
  );
}
