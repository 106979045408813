import { getHopeKitName } from '@oui/lib/src/getHopeKitName';

import { useCurrentPatient } from '@src/hooks/useCurrentUser';
import { useI18n } from '@src/lib/i18n';

export function useHopeKitName(): string {
  const { $t } = useI18n();
  const { user } = useCurrentPatient();
  return user?.productVariant ? getHopeKitName({ productVariant: user?.productVariant, $t }) : '';
}
