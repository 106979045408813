import { useI18n } from '@oui/app-core/src/lib/i18n';
import { getPatientSupporterRelation } from '@oui/lib/src/getPatientSupporterRelation';
import { PatientSupporterRelation } from '@oui/lib/src/types';

import { Text } from '@src/components/Text';
import { View } from '@src/components/View';

export function PatientSupporterCard(props: { name: string; relation: PatientSupporterRelation }) {
  const { $t } = useI18n();

  const initials = props.name
    .split(' ')
    .map((word) => word[0])
    .join('');

  return (
    <View row spacing={12}>
      <View
        style={{
          borderRadius: 15,
          borderWidth: 1,
          borderColor: '#EEE8EE',
          backgroundColor: '#EEE8EE',
          padding: 2,
        }}
      >
        <Text
          testID="PatientSupporterCard_initials"
          text={initials}
          style={{
            width: 20,
            height: 20,
            textAlign: 'center',
          }}
          size={13}
          weight="bold"
          color="#8B688D"
        />
      </View>
      <View>
        <Text testID="PatientSupporterCard_name" weight="semibold" text={props.name} />
        <Text
          testID="PatientSupporterCard_relation"
          size={15}
          text={getPatientSupporterRelation({ patientSupporterRelation: props.relation, $t })}
        />
      </View>
    </View>
  );
}
