import { useState } from 'react';
import DateTimePicker from 'react-native-modal-datetime-picker';

import { parseHoursAndMinutes } from '@oui/lib/src/parseHoursAndMinutes';

import { Button } from '@src/components/Button';
import { IOSDateTimeInputHeader } from '@src/components/DateTimeInput';
import { View } from '@src/components/View';
import { useI18n } from '@src/lib/i18n';
import { useSystemColorScheme } from '@src/styles';
import { ChatInputBaseProps, ChatInputTimePickerProps, Kind } from '@src/types';

export function ChatInputTimePicker(props: ChatInputTimePickerProps & ChatInputBaseProps) {
  const [showPicker, setShowPicker] = useState(false);
  const systemColorScheme = useSystemColorScheme();
  const { formatDate } = useI18n();

  const [initialDate] = useState(() =>
    props.defaultTime ? parseHoursAndMinutes(props.defaultTime) : new Date(),
  );

  return (
    <View style={{ flexGrow: 1, justifyContent: 'center' }}>
      <Button
        alignSelf="center"
        variant="text"
        onPress={() => setShowPicker(true)}
        text="Choose time"
      />
      <DateTimePicker
        date={initialDate}
        mode="time"
        customHeaderIOS={() => <IOSDateTimeInputHeader mode="time" />}
        isDarkModeEnabled={systemColorScheme === 'dark'}
        isVisible={showPicker}
        onConfirm={(date) => {
          const hoursMinutes = formatDate(date, {
            hour: 'numeric',
            minute: '2-digit',
            hourCycle: 'h23',
          });
          props.onInput({ kind: Kind.InputTimePicker, props: { time: hoursMinutes } });
          setShowPicker(false);
        }}
        onCancel={() => setShowPicker(false)}
      />
    </View>
  );
}

ChatInputTimePicker.defaultProps = {
  onInput: () => {},
  disabled: false,
};

export default ChatInputTimePicker;
