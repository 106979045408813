import { Component, createRef } from 'react';
import { Platform, StyleSheet, TextInput, View } from 'react-native';

import { ChatInputBaseProps, ChatInputTextProps, Kind } from '@oui/lib/src/types';

import { Icon } from '@src/components/Icon';
import { Text } from '@src/components/Text';
import { Color } from '@src/styles';

export class ChatInputText extends Component<ChatInputTextProps & ChatInputBaseProps> {
  static defaultProps = {
    onInput: () => {},
    disabled: false,
  };
  input = createRef<TextInput>();
  focusTimeout = 0 as unknown as NodeJS.Timeout;

  componentDidMount() {
    // Not sure why the autoFocus prop on TextInput isn't working as expected,
    // but we seem to need a short delay to get the focus to actually take effect
    if (this.props.autoFocus) {
      this.focusTimeout = global.setTimeout(() => {
        if (this.input.current) {
          this.input.current.focus();
        }
      }, 100);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.focusTimeout);
  }

  state: { isFocused: boolean; value: null | string; valid: boolean } = {
    isFocused: false,
    value: null,
    valid: false,
  };

  emit(value: string) {
    const { onInput } = this.props;
    if (onInput) {
      onInput({ kind: Kind.InputText, props: value.trim() });
    }
  }

  handleInput({ value: next }: { value: string }) {
    const { value: curr } = this.state;
    if (curr !== next) {
      this.setState({ value: next, valid: next !== '' });
    }
  }

  handleSubmit() {
    const { value: curr, valid } = this.state;
    if (curr !== null && valid) {
      this.emit(curr!);
      this.setState({ value: null, valid: false });
      if (this.input.current) this.input.current.blur();
    }
  }

  render() {
    const { disabled } = this.props;
    const { value, valid } = this.state;
    const change = (text: string) => this.handleInput({ value: text });
    const submit = () => this.handleSubmit();

    // Due to existing RN issue, we need to customize our TextInput behavior on Android
    // otherwise when our TextInput has a value, the lastTextContent won't be read by TalkBack.
    // Usually, we can get around this using the placeholder attribute, but in this case our
    // the placeholder is the last chat message which is likely too long to fit without wrapping
    // and expanding our text box.
    // https://github.com/facebook/react-native/issues/26739
    const inner = (
      <>
        <TextInput
          importantForAccessibility="no-hide-descendants"
          accessibilityLabel={this.props.lastTextContent}
          testID="ChatInputText_input"
          ref={this.input}
          keyboardType={this.props.keyboardType}
          style={[
            {
              color: Color.text,
              paddingLeft: 16,
              paddingTop: 12,
              height: '100%',
              flex: 1,
              fontFamily: 'OpenSansRegular',
              fontSize: 18,
            },
            disabled ? { opacity: 0.5 } : null,
          ]}
          value={value || ''}
          onChangeText={change}
          onSubmitEditing={valid ? submit : undefined}
          onFocus={() => this.setState({ isFocused: true })}
          onBlur={() => this.setState({ isFocused: false })}
          returnKeyType="send"
          editable={!disabled}
          multiline
        />
        {disabled || value?.length ? null : (
          <View
            style={[StyleSheet.absoluteFillObject, { justifyContent: 'center', paddingLeft: 16 }]}
            pointerEvents="none"
          >
            <Text
              text={this.props.placeholder || 'Enter text'}
              size={18}
              color="#c7c7c7"
              accessibilityRole="none"
            />
          </View>
        )}
      </>
    );

    return (
      <View
        testID="ChatInputText"
        style={{
          flexGrow: 1,
          maxHeight: 120,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {Platform.OS === 'android' ? (
          <View
            accessible
            accessibilityLabel={[
              this.state.isFocused ? 'Editing' : undefined,
              value,
              'Edit box',
              this.props.lastTextContent,
            ]
              .filter((v) => !!v)
              .join('.')}
            style={{ flexDirection: 'row', flex: 1 }}
            accessibilityActions={[
              { name: 'activate', label: this.state.isFocused ? 'blur' : 'focus' },
            ]}
            onAccessibilityAction={(event) => {
              if (event.nativeEvent.actionName === 'activate') {
                if (this.state.isFocused) {
                  this.input.current?.blur();
                } else {
                  this.input.current?.focus();
                }
              }
            }}
          >
            {inner}
          </View>
        ) : (
          inner
        )}
        {disabled ? null : (
          <Icon
            accessibilityLabel="send"
            color={valid ? Color.accent : Color.styleGuide.Gray4}
            disabled={!valid || disabled}
            name={valid ? 'post-ready' : 'post-open'}
            onPress={submit}
            size={32}
            style={{ marginRight: 12 }}
            testID="ChatInputText_submit"
          />
        )}
      </View>
    );
  }
}

export default ChatInputText;
