import { useNavigation } from '@react-navigation/native';
import { StyleSheet } from 'react-native';

import { encodeHostedQuizSetSlug } from '@oui/lib/src/quizSetSlug';
import { ProductVariant, ProductVersion } from '@oui/lib/src/types/graphql.generated';
import { GQLUUID } from '@oui/lib/src/types/scalars';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import ChatArtifactPreview from '@src/components/ChatArtifactPreview';
import { ErrorPresenter } from '@src/components/ErrorPresenter';
import { Heading } from '@src/components/Text';
import { View } from '@src/components/View';
import { useCurrentPatient } from '@src/hooks/useCurrentUser';
import { useI18n } from '@src/lib/i18n';
import type { ContentType, StackScreenProps } from '@src/types';

import { useSessionVideosQuery } from './Videos.graphql.generated';

export type SessionVideosProps = {
  testID?: string;
  contentType: ContentType;
};

export function SessionVideos({ testID, contentType }: SessionVideosProps) {
  const { $t } = useI18n();
  const { user } = useCurrentPatient();
  const { navigate } = useNavigation<StackScreenProps<'Conversation'>['navigation']>();

  const productVariant = user?.productVariant || ProductVariant.AVIVA_INPATIENT;
  const productVersion = user?.productVersion || ProductVersion.AVIVA_2_0;

  const { data, loading, error } = useSessionVideosQuery({
    variables: {
      contentType,
      productVariant,
      productVersion,
    },
    fetchPolicy: 'cache-only',
  });

  const collections = data?.sessionContentForApp?.quizSetCollections;

  return (
    <View
      testID={testID}
      style={[
        {
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      ]}
      spacing={15}
    >
      <Heading
        level={2}
        text={$t({ id: 'Conversation_videoTabVideosHeadline', defaultMessage: 'Videos' })}
      />
      {loading ? (
        <View
          style={{
            flex: 1,
            marginVertical: 30,
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <ActivityIndicator size="large" style={StyleSheet.absoluteFillObject} />
        </View>
      ) : (
        <View
          style={[
            {
              flex: 1,
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'space-between',
              margin: 5,
            },
          ]}
        >
          {error ? <ErrorPresenter error={error} /> : null}
          {collections?.map((collection, i) => (
            <View
              key={collection.quizSetCollectionID}
              style={{ marginBottom: i < collections.length - 2 ? 40 : 0 }}
            >
              <ChatArtifactPreview
                _width={159}
                _height={177}
                _onSideEffect={() => {
                  navigate('QuizSet', {
                    slug: encodeHostedQuizSetSlug({
                      quizSetCollectionID: collection.quizSetCollectionID as GQLUUID,
                    }),
                  });
                }}
                artifactName="QuizSet"
                params={{
                  slug: encodeHostedQuizSetSlug({
                    quizSetCollectionID: collection.quizSetCollectionID as GQLUUID,
                  }),
                }}
                linkText={collection.name}
              />
            </View>
          ))}
          {(collections?.length ?? 0) % 2 === 1 ? <View style={{ width: 159 }} /> : null}
        </View>
      )}
    </View>
  );
}
