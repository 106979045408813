// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinishPatientRegistrationMutationVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.UserNameInput>;
  address?: Types.InputMaybe<Types.UserAddressInput>;
  demo?: Types.InputMaybe<Types.UserDemoInput>;
  phone?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type FinishPatientRegistrationMutation = { readonly updateInfo: boolean };


export const FinishPatientRegistrationDocument = gql`
    mutation FinishPatientRegistration($name: UserNameInput, $address: UserAddressInput, $demo: UserDemoInput, $phone: String) {
  updateInfo(name: $name, address: $address, demo: $demo, phone: $phone)
}
    `;

/**
 * __useFinishPatientRegistrationMutation__
 *
 * To run a mutation, you first call `useFinishPatientRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishPatientRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishPatientRegistrationMutation, { data, loading, error }] = useFinishPatientRegistrationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      demo: // value for 'demo'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useFinishPatientRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<FinishPatientRegistrationMutation, FinishPatientRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishPatientRegistrationMutation, FinishPatientRegistrationMutationVariables>(FinishPatientRegistrationDocument, options);
      }
export type FinishPatientRegistrationMutationHookResult = ReturnType<typeof useFinishPatientRegistrationMutation>;

export function performFinishPatientRegistration(client: Apollo.ApolloClient<unknown>, variables?: FinishPatientRegistrationMutationVariables | undefined, options?: Omit<Apollo.MutationOptions<FinishPatientRegistrationMutation, FinishPatientRegistrationMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<FinishPatientRegistrationMutation, FinishPatientRegistrationMutationVariables>({
    mutation: FinishPatientRegistrationDocument,
    variables,
    ...options,
  });
}
