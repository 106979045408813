import Sentry from '@sentry/core';
import * as FileSystem from 'expo-file-system';
import { Draft } from 'immer';
import { useCallback } from 'react';
import { v4 as uuid, validate } from 'uuid';

import { MyStoryMyPlanCompositionDataHash } from '@oui/app-core/src/hooks/useComposition';
import { SessionUri } from '@oui/app-core/src/lib/resumableUpload';
import { resumableUploadManager } from '@oui/app-core/src/lib/resumableUploadManager';
import { MyStoryMyPlanContact } from '@oui/lib/src/transformExpoContactToResponseContact';
import { UploadType } from '@oui/lib/src/types/graphql.generated';

import { useStartMyPlanContactImageUploadMutation } from '@src/lib/myplanContacts.graphql.generated';

async function performResumableUploadForContactImage(
  uploadImage: ReturnType<typeof useStartMyPlanContactImageUploadMutation>[0],
  section: keyof MyStoryMyPlanCompositionDataHash,
  localUri: string,
) {
  const assetID = uuid();
  const fileName = localUri.split('/').reverse()[0];
  const resumableUpload = await uploadImage({
    variables: {
      input: { key: assetID, context: section, fileName, uploadType: UploadType.IMAGE },
    },
  });

  if (resumableUpload.data?.uploadResumableAsset.resumableUploadAssetURI) {
    let finalUri = localUri;
    if (!localUri.startsWith('file://')) {
      finalUri = FileSystem.cacheDirectory + `contactPhoto-${assetID}-${fileName}`;
      await FileSystem.copyAsync({
        from: localUri,
        to: finalUri,
      });
    }

    await resumableUploadManager.uploadFile(
      finalUri,
      resumableUpload.data.uploadResumableAsset.resumableUploadAssetURI as SessionUri,
      {
        cacheKey: assetID,
        metaData: null,
      },
    );
    return assetID;
  }

  if (resumableUpload.errors?.length) {
    Sentry.captureException(new Error('failed to upload contact image in myplan'), {
      extra: { errors: resumableUpload.errors, assetID, section },
    });
  }

  return '';
}

export function useMaybeReplaceImageAssetKeyURIWithResumableUpload() {
  const [uploadImage] = useStartMyPlanContactImageUploadMutation();

  return useCallback(
    async function maybeReplaceImageAssetKeyURIWithResumableUpload(
      section: keyof MyStoryMyPlanCompositionDataHash,
      contact: Draft<MyStoryMyPlanContact>,
    ): Promise<Draft<MyStoryMyPlanContact>> {
      if (contact.imageAssetKey && !validate(contact.imageAssetKey)) {
        const localUri = contact.imageAssetKey;
        contact.imageAssetKey = await performResumableUploadForContactImage(
          uploadImage,
          section,
          localUri,
        );
      }
      return contact;
    },
    [uploadImage],
  );
}
