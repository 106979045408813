// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkAsCompleteMutationVariables = Types.Exact<{
  content: Types.ContentType;
  value: Types.Scalars['Float']['input'];
}>;


export type MarkAsCompleteMutation = { readonly setOuiProgress: { readonly completed: boolean, readonly completion: number, readonly content: Types.ContentType, readonly updatedAt: CustomScalars.GQLDateTime } };


export const MarkAsCompleteDocument = gql`
    mutation MarkAsComplete($content: ContentType!, $value: Float!) {
  setOuiProgress(content: $content, value: $value) {
    completed
    completion
    content
    updatedAt
  }
}
    `;

/**
 * __useMarkAsCompleteMutation__
 *
 * To run a mutation, you first call `useMarkAsCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsCompleteMutation, { data, loading, error }] = useMarkAsCompleteMutation({
 *   variables: {
 *      content: // value for 'content'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useMarkAsCompleteMutation(baseOptions?: Apollo.MutationHookOptions<MarkAsCompleteMutation, MarkAsCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAsCompleteMutation, MarkAsCompleteMutationVariables>(MarkAsCompleteDocument, options);
      }
export type MarkAsCompleteMutationHookResult = ReturnType<typeof useMarkAsCompleteMutation>;

export function performMarkAsComplete(client: Apollo.ApolloClient<unknown>, variables: MarkAsCompleteMutationVariables, options?: Omit<Apollo.MutationOptions<MarkAsCompleteMutation, MarkAsCompleteMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<MarkAsCompleteMutation, MarkAsCompleteMutationVariables>({
    mutation: MarkAsCompleteDocument,
    variables,
    ...options,
  });
}
