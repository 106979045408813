import { useSessionVideosQuery } from '../Videos/Videos.graphql.generated';

import { Button } from '@src/components/Button';
import { View } from '@src/components/View';
import { useCurrentPatient } from '@src/hooks/useCurrentUser';
import { useI18n } from '@src/lib/i18n';
import { ContentType, ProductVariant, ProductVersion } from '@src/types';

import {
  MarkAsCompleteMutation,
  useMarkAsCompleteMutation,
} from './MarkComplete.graphql.generated';

export type MarkCompleteProps = {
  onComplete: (mutationData?: MarkAsCompleteMutation | null) => void;
  contentType: ContentType;
};

export function MarkComplete({ onComplete, contentType }: MarkCompleteProps) {
  const { user } = useCurrentPatient();
  const productVariant = user?.productVariant || ProductVariant.AVIVA_INPATIENT;
  const productVersion = user?.productVersion || ProductVersion.AVIVA_2_0;

  const { data } = useSessionVideosQuery({
    variables: {
      contentType,
      productVariant,
      productVersion,
    },
    fetchPolicy: 'cache-only',
  });

  const { $t } = useI18n();
  const [markAsComplete, { data: mutationData }] = useMarkAsCompleteMutation();

  const request = async () => {
    const promises = [];
    for (let contentType of data?.sessionContentForApp?.contentTypes ?? []) {
      promises.push(
        markAsComplete({
          variables: { content: contentType as ContentType, value: 1 },
        }),
      );
    }
    await Promise.all(promises);
    onComplete(mutationData);
  };

  return (
    <View
      testID="Conversation_markSessionComplete"
      style={[
        {
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
        },
      ]}
    >
      <View
        row
        style={[
          {
            justifyContent: 'center',
          },
        ]}
      >
        <Button
          onPress={request}
          text={$t({
            id: 'Conversation_markSessionComplete',
            defaultMessage: 'Mark session complete',
          })}
          variant="contained"
        />
      </View>
    </View>
  );
}
