// adapted from https://github.com/wcandillon/can-it-be-done-in-react-native/blob/master/the-10-min/src/Swiper/Swiper.tsx
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Platform, StyleProp, View, ViewStyle } from 'react-native';

import { Text } from '@src/components/Text';
import { ViewPager } from '@src/components/ViewPager';

type Props<T> = {
  style?: StyleProp<ViewStyle>;
  width: number;
  data: T[];
  renderItem: (data: { item: T; index: number }) => ReactNode;
  spacing?: number;
  initialValue?: number;
  indicator?: 'bubbles' | 'overlay';
};
export function Swiper<T>(props: Props<T>) {
  const [currentIndex, setCurrentIndex] = useState(props.initialValue ?? 0);
  const viewPagerRef = useRef<ViewPager>(null);

  const setCurrentIndexCallback = useCallback((value: number) => {
    value = Math.max(0, value);
    viewPagerRef.current?.setPage(value);
    setCurrentIndex(value);
  }, []);

  useEffect(() => {
    if (typeof props.initialValue === 'number') {
      setCurrentIndexCallback(props.initialValue);
    }
  }, [props.initialValue, setCurrentIndexCallback]);

  const pageCount = props.data.length;
  useEffect(() => {
    if (currentIndex >= pageCount) {
      setCurrentIndex(pageCount - 1);
    }
  }, [currentIndex, pageCount]);

  return (
    <View style={[{ flex: 1 }, props.style]}>
      <ViewPager
        pageMargin={10}
        ref={viewPagerRef}
        style={[
          {
            width: '100%',
            padding: 10,
            flex: 1,
          },
        ]}
        initialPage={0}
        onPageSelected={(p) => {
          if (p.nativeEvent.position !== currentIndex) {
            setCurrentIndexCallback(p.nativeEvent.position);
          }
        }}
        // Workaround for
        // https://github.com/callstack/react-native-pager-view/issues/109
        // https://github.com/callstack/react-native-pager-view/issues/219
        key={Platform.OS === 'android' ? props.data.length.toString() : 'swiper'}
      >
        {props.data.map((item, i) => (
          <View
            key={i}
            collapsable={false}
            style={{
              // for web
              flexGrow: 1,
            }}
          >
            {props.renderItem({ item, index: i })}
          </View>
        ))}
      </ViewPager>
      {props.indicator === 'overlay' ? (
        <View
          style={{
            position: 'absolute',
            right: 16,
            top: 16,
            backgroundColor: 'rgba(36, 34, 38, 0.8)',
            paddingHorizontal: 10,
            paddingVertical: 2,
            borderRadius: 20,
          }}
        >
          <Text
            text={`${currentIndex + 1}/${props.data.length}`}
            size={13}
            color="white"
            accessibilityLabel={`page ${currentIndex + 1} of ${props.data.length}`}
          />
        </View>
      ) : (
        <View
          style={{
            width: '100%',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            paddingVertical: 12,
          }}
        >
          {props.data.map((_, i) => (
            <View
              accessibilityActions={[{ name: 'activate', label: 'activate' }]}
              accessibilityHint="Change page"
              onAccessibilityAction={() => {
                setCurrentIndexCallback(i);
              }}
              accessibilityRole="button"
              accessibilityLabel={`Page ${i + 1} of ${props.data.length}`}
              key={i}
              style={[
                {
                  backgroundColor: 'white',
                  borderRadius: 6,
                  width: 12,
                  height: 12,
                  marginHorizontal: 10,
                  opacity: i === currentIndex ? 1 : 0.5,
                  transform: [
                    {
                      scale: i === currentIndex ? 1.3 : 0.8,
                    },
                  ],
                },
              ]}
            />
          ))}
        </View>
      )}
    </View>
  );
}
