import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import parseISO from 'date-fns/parseISO';
import startOfWeek from 'date-fns/startOfWeek';
import { LinearGradient } from 'expo-linear-gradient';
import { ComponentProps, Fragment, memo, useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';

import { RoundedSection } from '@oui/app-core/src/components/RoundedSection';
import { useCurrentPatient } from '@oui/app-core/src/hooks/useCurrentUser';
import { useFormatDuration } from '@oui/app-core/src/hooks/useFormatDuration';
import { getGQLDate } from '@oui/lib/src/getGQLDate';
import { PracticeType, ProductVariant, RatingType } from '@oui/lib/src/types/graphql.generated';

import { Button } from '@src/components/Button';
import { DiaryTabs } from '@src/components/DiaryTabs';
import Divider from '@src/components/Divider';
import { HeaderButtons, HeaderItem } from '@src/components/HeaderButtons';
import { Icon } from '@src/components/Icon';
import { PillGroup } from '@src/components/PillGroup';
import { RatingGraph } from '@src/components/RatingGraph';
import { OldHeading, Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { WorksheetListItem } from '@src/components/WorksheetListItem';
import { usePracticeRatings, useSleepDiaryConfig } from '@src/hooks/practices';
import { useSleepDiaryEntriesQuery } from '@src/hooks/practices.graphql.generated';
import { useArtifactRequest } from '@src/hooks/useArtifactResult';
import { useDailyState } from '@src/hooks/useDailyState';
import {
  SleepDiaryEntryType,
  SleepStat as SleepStatType,
  getSleepDiaryTips,
  getSleepStats,
} from '@src/lib/getSleepDiaryTips';
import { useI18n } from '@src/lib/i18n';
import {
  isMorningChecklistComplete,
  isNightChecklistComplete,
  useSleepDiaryEntryPracticeByDate,
} from '@src/screens/SleepDiaryEntry';
import { useTheme } from '@src/styles';
import { StackScreenProps } from '@src/types';

type Tab = 'hours' | 'rating';

const LinearGradientMemo = memo(() => (
  <LinearGradient
    colors={['rgba(255,255,255,0)', '#C6D7F1']}
    style={StyleSheet.absoluteFillObject}
    start={[0, 0.11]}
    end={[0, 0.7]}
  />
));

function SleepStat({
  style,
  variant,
  ...rest
}: {
  variant?: 'dark';
} & ComponentProps<typeof View>) {
  const { Color } = useTheme();
  return (
    <View
      style={[
        {
          backgroundColor: 'white',
          padding: 20,
          borderWidth: 1,
          borderColor: '#cadffd',
          borderRadius: 20,
        },
        variant === 'dark' ? { borderColor: 'white', backgroundColor: Color.tertiary } : null,
        style,
      ]}
      {...rest}
      accessible
    />
  );
}

function HoursSlept({ stats }: { stats?: SleepStatType[] }) {
  const latestStatIndex = stats?.findIndex((s) => s.data.sleepMinutes.avg > 0) ?? -1;
  const { formatDuration } = useFormatDuration();
  const { $t, formatDate } = useI18n();
  if (!stats || latestStatIndex === -1) return null;
  const latestStat = stats[latestStatIndex];
  const previousStat: (typeof stats)[number] | undefined = stats[latestStatIndex + 1];
  const thisWeekDate = getGQLDate(startOfWeek(new Date()));
  const isThisWeek = latestStat.date === thisWeekDate;
  const minutesDiffFromPreviousWeek = previousStat
    ? Math.round(latestStat.data.sleepMinutes.avg - previousStat.data.sleepMinutes.avg)
    : 0;
  const minutesDiffFromRecommended = Math.round(latestStat.data.sleepMinutes.avg - 8 * 60);

  return (
    <View
      spacing={20}
      style={{ justifyContent: 'space-evenly', flex: 1 }}
      testID="SleepDiary_hoursSlept"
    >
      <SleepStat style={{ alignItems: 'center' }} spacing={10}>
        <Icon name="zz" color="#4082E0" />
        <Text
          text={
            isThisWeek
              ? $t({
                  id: 'SleepDiary_hoursSleptThisWeek',
                  defaultMessage: 'Average hours slept this week',
                })
              : $t(
                  {
                    id: 'SleepDiary_hoursSleptWeekOf',
                    defaultMessage: 'Average hours slept week of {formattedDate}',
                  },
                  {
                    formattedDate: formatDate(parseISO(latestStat.date), {
                      month: 'numeric',
                      day: 'numeric',
                    }),
                  },
                )
          }
          weight="semibold"
          textAlign="center"
        />
        <Text
          text={formatDuration({
            hours: Math.floor(latestStat.data.sleepMinutes.avg / 60),
            minutes: Math.ceil(latestStat.data.sleepMinutes.avg % 60),
          })}
          color={'#202E53'}
          weight="bold"
          size={21}
        />
      </SleepStat>
      <View row childFlex={1} spacing={20}>
        {previousStat ? (
          <SleepStat style={{ alignSelf: 'stretch' }} spacing={12}>
            {minutesDiffFromPreviousWeek === 0 ? (
              <View
                style={{ alignSelf: 'center' }}
                row
                importantForAccessibility="no-hide-descendants"
                accessibilityElementsHidden
              >
                <Text text=" " color={'#202E53'} weight="bold" size={21} />
                <Icon name="zz" color="#4082E0" />
                <Text text=" " color={'#202E53'} weight="bold" size={21} />
              </View>
            ) : (
              <View row spacing={10} style={{ alignSelf: 'center' }}>
                <Icon
                  name="arrow-right"
                  style={{
                    transform: [{ rotateZ: minutesDiffFromPreviousWeek > 0 ? '-90deg' : '90deg' }],
                  }}
                  color="#4082E0"
                />
                <Text
                  text={formatDuration(
                    {
                      minutes: Math.abs(minutesDiffFromPreviousWeek),
                    },
                    'short',
                  )}
                  color={'#202E53'}
                  weight="bold"
                  size={21}
                />
              </View>
            )}
            <Text
              text={
                minutesDiffFromPreviousWeek === 0
                  ? $t({
                      id: 'SleepDiary_previousWeekDiffSame',
                      defaultMessage: 'same as previous week',
                    })
                  : minutesDiffFromPreviousWeek < 0
                  ? $t({
                      id: 'SleepDiary_previousWeekDiffLess',
                      defaultMessage: 'less than previous week',
                    })
                  : $t({
                      id: 'SleepDiary_previousWeekDiffMore',
                      defaultMessage: 'more than previous week',
                    })
              }
              weight="semibold"
              textAlign="center"
            />
          </SleepStat>
        ) : null}
        <SleepStat style={{ alignSelf: 'stretch' }} spacing={12}>
          {minutesDiffFromRecommended === 0 ? (
            <View
              style={{ alignSelf: 'center' }}
              row
              importantForAccessibility="no-hide-descendants"
              accessibilityElementsHidden
            >
              <Text text=" " color={'#202E53'} weight="bold" size={21} />
              <Icon name="zz" color="#4082E0" />
              <Text text=" " color={'#202E53'} weight="bold" size={21} />
            </View>
          ) : (
            <View row spacing={10} style={{ alignSelf: 'center' }}>
              <Icon
                name="arrow-right"
                style={{
                  transform: [{ rotateZ: minutesDiffFromRecommended > 0 ? '-90deg' : '90deg' }],
                }}
                color="#4082E0"
              />
              <Text
                text={formatDuration(
                  {
                    minutes: Math.abs(minutesDiffFromRecommended),
                  },
                  'short',
                )}
                color={'#202E53'}
                weight="bold"
                size={21}
              />
            </View>
          )}
          <Text
            text={
              minutesDiffFromRecommended === 0
                ? $t({
                    id: 'SleepDiary_diffFromRecommendedSame',
                    defaultMessage: 'same as the recommended 8 hours',
                  })
                : minutesDiffFromRecommended < 0
                ? $t({
                    id: 'SleepDiary_diffFromRecommendedLess',
                    defaultMessage: 'less than the recommended 8 hours',
                  })
                : $t({
                    id: 'SleepDiary_diffFromRecommendedMore',
                    defaultMessage: 'more than the recommended 8 hours',
                  })
            }
            weight="semibold"
            textAlign="center"
          />
        </SleepStat>
      </View>
    </View>
  );
}

function SleepRatings() {
  const { Color } = useTheme();
  const [timeScale, setTimeScale] = useState<'WEEK' | 'MONTH' | 'YEAR'>('WEEK');
  const payload = usePracticeRatings({
    practiceType: PracticeType.SLEEP_DIARY_ENTRY,
    ratingType: RatingType.RATING,
    timeScale,
  });
  const { $t } = useI18n();

  return (
    <>
      <PillGroup
        accessibilityLabel={$t({
          id: 'SleepDiary_ratingGraphTimescaleAccessibilityLabel',
          defaultMessage: 'Chart time scale',
        })}
        value={timeScale}
        onChangeValue={setTimeScale}
        items={[
          {
            label: $t({
              id: 'SleepDiary_ratingGraphTimescaleWeekButton',
              defaultMessage: 'Week',
            }),
            value: 'WEEK',
          },
          {
            label: $t({
              id: 'SleepDiary_ratingGraphTimescaleMonthButton',
              defaultMessage: 'Month',
            }),
            value: 'MONTH',
          },
          {
            label: $t({
              id: 'SleepDiary_ratingGraphTimescaleYearButton',
              defaultMessage: 'Year',
            }),
            value: 'YEAR',
          },
        ]}
      />
      <View style={{ flex: 1, marginTop: 20 }}>
        <RatingGraph
          testID="SleepDiary_ratingGraph"
          accessibilityLabel={$t({
            id: 'SleepDiary_ratingGraphAccessibilityLabel',
            defaultMessage: 'A graph of sleep quality ratings.',
          })}
          showDots={timeScale === 'WEEK'}
          xLabels={payload.xLabels}
          ratings={[payload.data]}
          legend={[
            {
              color: Color.tertiary,
              text: $t({
                id: 'SleepDiary_ratingGraphLabel',
                defaultMessage: 'Rating',
              }),
            },
          ]}
          yAxisLabel={$t({
            id: 'SleepDiary_ratingsGraphYAxisLabel',
            defaultMessage: 'Ratings',
          })}
          xAxisLabel={payload.xAxisLabel}
        />
      </View>
    </>
  );
}

function SleepDiaryEntries() {
  const { navigate } = useNavigation<StackScreenProps<'SleepDiary'>['navigation']>();
  const { data } = useSleepDiaryEntriesQuery({
    variables: {},
  });
  const { Color } = useTheme();
  const { $t, formatDate } = useI18n();

  const [today] = useState(getGQLDate);
  const { data: todayEntry } = useSleepDiaryEntryPracticeByDate(today);
  const morningComplete = isMorningChecklistComplete(todayEntry?.sleepDiaryEntryPracticeByDate);
  const nightComplete = isNightChecklistComplete($t, todayEntry?.sleepDiaryEntryPracticeByDate);

  return (
    <View>
      <View
        row
        style={{
          backgroundColor: '#F5F8FF',
          padding: 20,
          justifyContent: 'space-between',
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        }}
      >
        <View style={{ flex: 1 }}>
          <Text
            text={$t({
              id: 'SleepDiary_todayHeader',
              defaultMessage: 'Today',
            })}
            weight="semibold"
            accessibilityRole="header"
          />
        </View>
        <View style={{ flex: 2, marginRight: 10 }}>
          <Button
            text={$t({
              id: 'SleepDiary_viewMorningCheckinButton',
              defaultMessage: 'Morning',
            })}
            accessibilityLabel={
              morningComplete
                ? $t({
                    id: 'SleepDiary_morningCompleteAccessibilityLabel',
                    defaultMessage: 'Morning. completed',
                  })
                : $t({ id: 'SleepDiary_morningAccessibilityLabel', defaultMessage: 'Morning' })
            }
            icon="sun"
            onPress={() => navigate('EditSleepDiaryEntry', { step: 'morning', date: today })}
            variant={morningComplete ? 'text' : 'contained'}
            alignSelf={morningComplete ? 'flex-start' : 'center'}
            testID="SleepDiary_viewMorningCheckinButton"
          />
          {morningComplete ? (
            <Text
              text={$t({
                id: 'SleepDiary_morningDone',
                defaultMessage: 'Done',
              })}
              color={Color.styleGuide.Gray4}
              size={15}
              accessibilityRole="none"
              testID="SleepDiary_morningDone"
            />
          ) : null}
        </View>
        <View style={{ flex: 2 }}>
          <Button
            text={$t({
              id: 'SleepDiary_viewNightCheckinButton',
              defaultMessage: 'Night',
            })}
            accessibilityLabel={
              nightComplete
                ? $t({
                    id: 'SleepDiary_nightCompleteAccessibilityLabel',
                    defaultMessage: 'Night. completed',
                  })
                : $t({ id: 'SleepDiary_nightAccessibilityLabel', defaultMessage: 'Night' })
            }
            icon="moon"
            onPress={() => navigate('EditSleepDiaryEntry', { step: 'night', date: today })}
            variant={nightComplete ? 'text' : 'contained'}
            alignSelf={nightComplete ? 'flex-start' : 'center'}
            testID="SleepDiary_viewNightCheckinButton"
          />
          {nightComplete ? (
            <Text
              text={$t({
                id: 'SleepDiary_nightDone',
                defaultMessage: 'Done',
              })}
              color={Color.styleGuide.Gray4}
              size={15}
              accessibilityRole="none"
              testID="SleepDiary_nightDone"
            />
          ) : null}
        </View>
      </View>
      <View style={{ padding: 20 }}>
        <Text
          text={$t({
            id: 'SleepDiaryEntries_listHeader',
            defaultMessage: 'Check-ins',
          })}
          weight="semibold"
          accessibilityRole="header"
        />
        <Divider />
        {data?.practices.map(
          (d, i) =>
            d.__typename === 'SleepDiaryEntryPractice' && (
              <Fragment key={d.practiceID}>
                <WorksheetListItem
                  text={
                    d.practiceValues.date
                      ? formatDate(parseISO(d.practiceValues.date), {
                          weekday: 'short',
                          month: 'short',
                          day: 'numeric',
                        })
                      : ''
                  }
                  onPress={() => navigate('SleepDiaryEntry', { date: d.practiceValues.date! })}
                  testID={`SleepDiary_row_${i}`}
                />
                <Divider />
              </Fragment>
            ),
        )}
      </View>
    </View>
  );
}

export function SleepDiary() {
  const { setOptions, navigate } = useNavigation<StackScreenProps<'SleepDiary'>['navigation']>();
  const route = useRoute<StackScreenProps<'SleepDiary'>['route']>();
  const { Color } = useTheme();
  const [activeTab, setActiveTab] = useState<Tab>('hours');
  const { data: config, isComplete } = useSleepDiaryConfig();
  const [tipIndex, setTipIndex] = useDailyState('SleepDiaryTipIndex', 0);
  const { user } = useCurrentPatient();
  const { data } = useSleepDiaryEntriesQuery({
    variables: {},
    fetchPolicy: 'cache-only',
  });
  const { $t } = useI18n();

  const hasSleepStatData = isComplete && (data?.practices.length ?? 0) >= 3;
  const sleepStats =
    config && data?.practices
      ? getSleepStats({
          config,
          entries: data?.practices as ReadonlyArray<SleepDiaryEntryType>,
        })
      : null;

  useArtifactRequest(route.name, !!isComplete);
  useEffect(() => {
    setOptions({
      headerRight: isComplete
        ? ({ tintColor }) => (
            <HeaderButtons>
              <HeaderItem
                testID="SleepDiary_settingsButton"
                title=""
                iconName="settings"
                accessibilityLabel={$t({
                  id: 'SleepDiary_settingsButtonAccessibilityLabel',
                  defaultMessage: 'Sleep diary settings',
                })}
                onPress={() => navigate('SetupSleepDiary', { fromSleepDiary: true })}
                color={tintColor}
              />
            </HeaderButtons>
          )
        : undefined,
    });
  }, [navigate, setOptions, isComplete, $t]);

  useFocusEffect(
    useCallback(() => {
      return () => setTipIndex((t) => t + 1);
    }, [setTipIndex]),
  );

  const tips =
    config && data?.practices
      ? getSleepDiaryTips({
          $t,
          entries: data?.practices as any, // eslint-disable-line
          config,
          productVariant: user?.productVariant ?? ProductVariant.AVIVA_ADULT,
        })
      : [];
  const tip = tips[Math.max(tipIndex % tips.length, 0)];

  const sleepTip = tip ? (
    <SleepStat variant="dark" spacing={12}>
      <View row spacing={12}>
        <Icon name="sleep-bed" color="white" />
        <Text
          text={$t({
            id: 'SleepDiary_sleepStat',
            defaultMessage: 'Sleep stat',
          })}
          color="white"
          weight="semibold"
        />
      </View>
      <Text text={tip.text} color="white" />
    </SleepStat>
  ) : null;

  return (
    <RoundedSection
      color={Color.tertiary}
      secondaryColor={'white'}
      title={$t({
        id: 'SleepDiary_header',
        defaultMessage: 'Sleep Diary',
      })}
      preview={false}
      testID="SleepDiary_scrollView"
    >
      {hasSleepStatData ? (
        <>
          <DiaryTabs
            testID="SleepDiary_tabs"
            value={activeTab}
            onChangeValue={setActiveTab}
            items={[
              {
                icon: 'calendar',
                text: $t({
                  id: 'SleepDiary_hoursTab',
                  defaultMessage: 'Hours slept',
                }),
                value: 'hours',
              },
              {
                icon: 'chart',
                text: $t({
                  id: 'SleepDiary_ratingsTab',
                  defaultMessage: 'Sleep rating',
                }),
                value: 'rating',
              },
            ]}
            style={{
              marginHorizontal: -20,
            }}
          />
          <View
            spacing={20}
            style={{ marginHorizontal: -20, paddingBottom: 40, paddingHorizontal: 20 }}
          >
            <LinearGradientMemo />
            <View>
              {activeTab === 'hours' ? (
                <HoursSlept stats={sleepStats ?? undefined} />
              ) : (
                <SleepRatings />
              )}
            </View>
            {sleepTip}
          </View>
        </>
      ) : isComplete ? (
        <View
          spacing={20}
          style={{ marginHorizontal: -20, paddingBottom: 40, paddingHorizontal: 20 }}
        >
          <LinearGradientMemo />
          <OldHeading
            text={$t({
              id: 'SleepDiary_setupCompleteHeader',
              defaultMessage: 'You’re all set up',
            })}
            textAlign="center"
            testID="SleepDiary_setupCompleteHeader"
          />
          <Text
            text={$t({
              id: 'SleepDiary_setupCompleteMessage',
              defaultMessage:
                "Start tracking your sleep below. We'll show you personal stats after 3 days of entries.",
            })}
            textAlign="center"
            weight="semibold"
            size={15}
          />
          {sleepTip}
        </View>
      ) : (
        <View
          spacing={20}
          style={{ marginHorizontal: -20, paddingBottom: 40, paddingHorizontal: 20 }}
        >
          <LinearGradientMemo />
          <OldHeading
            text={$t({
              id: 'SleepDiary_finishSetupHeader',
              defaultMessage: "Let's finish your setup.",
            })}
            textAlign="center"
          />
          <Text
            text={$t({
              id: 'SleepDiary_finishSetupMessage',
              defaultMessage: "You're almost done customizing your diary.",
            })}
            textAlign="center"
            weight="semibold"
            size={15}
          />
          <Button
            text={$t({ id: 'SleepDiary_setupButton', defaultMessage: 'Finish Setup' })}
            onPress={() => navigate('SetupSleepDiary', { fromSleepDiary: true })}
            alignSelf="center"
            variant="solid"
            testID="SleepDiary_setupButton"
          />
        </View>
      )}
      <View
        style={{
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          marginTop: -14,
          marginHorizontal: -20,
          backgroundColor: 'white',
        }}
      >
        <SleepDiaryEntries />
        <View style={{ width: '100%', height: 50 }} />
      </View>
    </RoundedSection>
  );
}
