// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateHopeKitImageMutationVariables = Types.Exact<{
  input: Types.UpdateHopeKitImageInput;
}>;


export type UpdateHopeKitImageMutation = { readonly updateHopeKitImage: { readonly hopeKitImage: { readonly __typename: 'HopeKitImage', readonly hopeKitItemID: CustomScalars.GQLUUID, readonly reason?: string | null, readonly staticUrl?: string | null } } };

export type UpdateHopeKitVideoMutationVariables = Types.Exact<{
  input: Types.UpdateHopeKitVideoInput;
}>;


export type UpdateHopeKitVideoMutation = { readonly updateHopeKitVideo: { readonly hopeKitVideo: { readonly __typename: 'HopeKitVideo', readonly hopeKitItemID: CustomScalars.GQLUUID, readonly reason?: string | null, readonly staticUrl?: string | null } } };

export type UpdateHopeKitQuoteMutationVariables = Types.Exact<{
  input: Types.UpdateHopeKitQuoteInput;
}>;


export type UpdateHopeKitQuoteMutation = { readonly updateHopeKitQuote: { readonly hopeKitQuote: { readonly __typename: 'HopeKitQuote', readonly hopeKitItemID: CustomScalars.GQLUUID, readonly reason?: string | null, readonly text: string, readonly author?: string | null } } };

export type HopeKitQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HopeKitQuery = { readonly hopeKitItems: ReadonlyArray<{ readonly __typename: 'HopeKitImage', readonly hopeKitItemID: CustomScalars.GQLUUID, readonly reason?: string | null, readonly staticUrl?: string | null } | { readonly __typename: 'HopeKitQuote', readonly hopeKitItemID: CustomScalars.GQLUUID, readonly text: string, readonly author?: string | null, readonly reason?: string | null } | { readonly __typename: 'HopeKitVideo', readonly hopeKitItemID: CustomScalars.GQLUUID, readonly reason?: string | null, readonly staticUrl?: string | null }> };

export type RemoveHopeKitItemMutationVariables = Types.Exact<{
  input: Types.RemoveHopeKitItemInput;
}>;


export type RemoveHopeKitItemMutation = { readonly removeHopeKitItem: { readonly hopeKitItemID: CustomScalars.GQLUUID } };


export const UpdateHopeKitImageDocument = gql`
    mutation UpdateHopeKitImage($input: UpdateHopeKitImageInput!) {
  updateHopeKitImage(input: $input) {
    hopeKitImage {
      __typename
      hopeKitItemID
      reason
      staticUrl
    }
  }
}
    `;

/**
 * __useUpdateHopeKitImageMutation__
 *
 * To run a mutation, you first call `useUpdateHopeKitImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHopeKitImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHopeKitImageMutation, { data, loading, error }] = useUpdateHopeKitImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHopeKitImageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHopeKitImageMutation, UpdateHopeKitImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHopeKitImageMutation, UpdateHopeKitImageMutationVariables>(UpdateHopeKitImageDocument, options);
      }
export type UpdateHopeKitImageMutationHookResult = ReturnType<typeof useUpdateHopeKitImageMutation>;

export function performUpdateHopeKitImage(client: Apollo.ApolloClient<unknown>, variables: UpdateHopeKitImageMutationVariables, options?: Omit<Apollo.MutationOptions<UpdateHopeKitImageMutation, UpdateHopeKitImageMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<UpdateHopeKitImageMutation, UpdateHopeKitImageMutationVariables>({
    mutation: UpdateHopeKitImageDocument,
    variables,
    ...options,
  });
}

export const UpdateHopeKitVideoDocument = gql`
    mutation UpdateHopeKitVideo($input: UpdateHopeKitVideoInput!) {
  updateHopeKitVideo(input: $input) {
    hopeKitVideo {
      __typename
      hopeKitItemID
      reason
      staticUrl
    }
  }
}
    `;

/**
 * __useUpdateHopeKitVideoMutation__
 *
 * To run a mutation, you first call `useUpdateHopeKitVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHopeKitVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHopeKitVideoMutation, { data, loading, error }] = useUpdateHopeKitVideoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHopeKitVideoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHopeKitVideoMutation, UpdateHopeKitVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHopeKitVideoMutation, UpdateHopeKitVideoMutationVariables>(UpdateHopeKitVideoDocument, options);
      }
export type UpdateHopeKitVideoMutationHookResult = ReturnType<typeof useUpdateHopeKitVideoMutation>;

export function performUpdateHopeKitVideo(client: Apollo.ApolloClient<unknown>, variables: UpdateHopeKitVideoMutationVariables, options?: Omit<Apollo.MutationOptions<UpdateHopeKitVideoMutation, UpdateHopeKitVideoMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<UpdateHopeKitVideoMutation, UpdateHopeKitVideoMutationVariables>({
    mutation: UpdateHopeKitVideoDocument,
    variables,
    ...options,
  });
}

export const UpdateHopeKitQuoteDocument = gql`
    mutation UpdateHopeKitQuote($input: UpdateHopeKitQuoteInput!) {
  updateHopeKitQuote(input: $input) {
    hopeKitQuote {
      __typename
      hopeKitItemID
      reason
      text
      author
    }
  }
}
    `;

/**
 * __useUpdateHopeKitQuoteMutation__
 *
 * To run a mutation, you first call `useUpdateHopeKitQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHopeKitQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHopeKitQuoteMutation, { data, loading, error }] = useUpdateHopeKitQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHopeKitQuoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHopeKitQuoteMutation, UpdateHopeKitQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHopeKitQuoteMutation, UpdateHopeKitQuoteMutationVariables>(UpdateHopeKitQuoteDocument, options);
      }
export type UpdateHopeKitQuoteMutationHookResult = ReturnType<typeof useUpdateHopeKitQuoteMutation>;

export function performUpdateHopeKitQuote(client: Apollo.ApolloClient<unknown>, variables: UpdateHopeKitQuoteMutationVariables, options?: Omit<Apollo.MutationOptions<UpdateHopeKitQuoteMutation, UpdateHopeKitQuoteMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<UpdateHopeKitQuoteMutation, UpdateHopeKitQuoteMutationVariables>({
    mutation: UpdateHopeKitQuoteDocument,
    variables,
    ...options,
  });
}

export const HopeKitDocument = gql`
    query HopeKit {
  hopeKitItems {
    ... on HopeKitImage {
      __typename
      hopeKitItemID
      reason
      staticUrl
    }
    ... on HopeKitVideo {
      __typename
      hopeKitItemID
      reason
      staticUrl
    }
    ... on HopeKitQuote {
      __typename
      hopeKitItemID
      text
      author
      reason
    }
  }
}
    `;

/**
 * __useHopeKitQuery__
 *
 * To run a query within a React component, call `useHopeKitQuery` and pass it any options that fit your needs.
 * When your component renders, `useHopeKitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHopeKitQuery({
 *   variables: {
 *   },
 * });
 */
export function useHopeKitQuery(baseOptions?: Apollo.QueryHookOptions<HopeKitQuery, HopeKitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HopeKitQuery, HopeKitQueryVariables>(HopeKitDocument, options);
      }
export function useHopeKitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HopeKitQuery, HopeKitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HopeKitQuery, HopeKitQueryVariables>(HopeKitDocument, options);
        }
export type HopeKitQueryHookResult = ReturnType<typeof useHopeKitQuery>;
export type HopeKitLazyQueryHookResult = ReturnType<typeof useHopeKitLazyQuery>;

export function queryHopeKit(client: Apollo.ApolloClient<unknown>, variables?: HopeKitQueryVariables | undefined, options?: Omit<Apollo.QueryOptions<HopeKitQuery, HopeKitQueryVariables>, "query" | 'variables'>) {
  return client.query<HopeKitQuery, HopeKitQueryVariables>({
    query: HopeKitDocument,
    variables,
    ...options,
  });
}

export const RemoveHopeKitItemDocument = gql`
    mutation RemoveHopeKitItem($input: RemoveHopeKitItemInput!) {
  removeHopeKitItem(input: $input) {
    hopeKitItemID
  }
}
    `;

/**
 * __useRemoveHopeKitItemMutation__
 *
 * To run a mutation, you first call `useRemoveHopeKitItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHopeKitItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHopeKitItemMutation, { data, loading, error }] = useRemoveHopeKitItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveHopeKitItemMutation(baseOptions?: Apollo.MutationHookOptions<RemoveHopeKitItemMutation, RemoveHopeKitItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveHopeKitItemMutation, RemoveHopeKitItemMutationVariables>(RemoveHopeKitItemDocument, options);
      }
export type RemoveHopeKitItemMutationHookResult = ReturnType<typeof useRemoveHopeKitItemMutation>;

export function performRemoveHopeKitItem(client: Apollo.ApolloClient<unknown>, variables: RemoveHopeKitItemMutationVariables, options?: Omit<Apollo.MutationOptions<RemoveHopeKitItemMutation, RemoveHopeKitItemMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<RemoveHopeKitItemMutation, RemoveHopeKitItemMutationVariables>({
    mutation: RemoveHopeKitItemDocument,
    variables,
    ...options,
  });
}
