import { useNavigation, useRoute } from '@react-navigation/native';
import parseISO from 'date-fns/parseISO';
import omit from 'lodash/omit';
import { useCallback, useEffect } from 'react';
import { AsyncReturnType } from 'type-fest';

import { SliderInput } from '@oui/app-core/src/components/SliderInput';
import { getGQLDate } from '@oui/lib/src/getGQLDate';
import { RatingType } from '@oui/lib/src/types/graphql.generated';

import { Button } from '@src/components/Button';
import { HeaderButtons } from '@src/components/HeaderButtons';
import { Icon } from '@src/components/Icon';
import { ScrollView } from '@src/components/ScrollView';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { getRatingsLabels, useActivityPractice } from '@src/hooks/practices';
import { useUpdateActivityPracticeMutation } from '@src/hooks/practices.graphql.generated';
import { useForm } from '@src/hooks/useForm';
import { useI18n } from '@src/lib/i18n';
import { ActivityEventInstanceTiming } from '@src/screens/ActivityPractice';
import { Shadow, card, useTheme } from '@src/styles';
import { StackScreenProps } from '@src/types';
import { namedAvivaOperations } from '@src/types/namedOperations.generated';

export function EditActivityPractice() {
  const { replace, navigate, setOptions } =
    useNavigation<StackScreenProps<'EditActivityPractice'>['navigation']>();
  const { Color } = useTheme();
  const { activityPractice } = useActivityPractice();
  const { params } = useRoute<StackScreenProps<'EditActivityPractice'>['route']>();
  const activityPlan = activityPractice?.activity;
  const [updateActivityPractice] = useUpdateActivityPracticeMutation();
  const { $t } = useI18n();

  const { data, bind } = useForm({
    ratingBefore:
      activityPractice?.practiceValues.ratings.find((r) => r.type === RatingType.RATING_BEFORE)
        ?.value ?? 3,
    ratingAfter:
      activityPractice?.practiceValues.ratings.find((r) => r.type === RatingType.RATING_AFTER)
        ?.value ?? 3,
  });

  const save = useCallback(() => {
    function afterSave(r: AsyncReturnType<typeof updateActivityPractice>) {
      const newPracticeID = r.data?.saveActivityDiaryEntry.activityPractice.practiceID;
      if (newPracticeID) {
        if (params.fromActivityDiary || params.fromActivityPractice) {
          navigate('ActivityPractice', {
            practiceID: newPracticeID,
          });
        } else {
          replace('ActivityPractice', {
            practiceID: newPracticeID,
          });
        }
      }
    }

    if (!activityPractice) return;

    return updateActivityPractice({
      variables: {
        input: {
          practiceID: activityPractice.practiceID,
          practiceValues: {
            patientID: activityPractice.practiceValues.patient.ID,
            date: getGQLDate(parseISO(activityPractice.activity.startTime)),
            ratings: [
              {
                type: RatingType.RATING_BEFORE,
                value: data.ratingBefore,
              },
              {
                type: RatingType.RATING_AFTER,
                value: data.ratingAfter,
              },
            ],
          },
          activity: omit(activityPractice.activity, ['__typename']),
        },
      },
      refetchQueries: [namedAvivaOperations.Query.ActivityDiaryEntries],
    }).then(afterSave);
  }, [
    navigate,
    replace,
    params.fromActivityDiary,
    params.fromActivityPractice,
    updateActivityPractice,
    data,
    activityPractice,
  ]);

  useEffect(() => {
    setOptions({
      headerRight: () => (
        <HeaderButtons>
          <Button
            text={$t({ id: 'EditActivityPractice_saveButton', defaultMessage: 'Done' })}
            onPress={save}
            testID="EditActivityPractice_saveButton"
          />
        </HeaderButtons>
      ),
    });
  }, [setOptions, save, $t]);

  const ratingLabels = getRatingsLabels($t);

  if (!activityPlan) return null;
  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: Color.grayBackground }}
      contentContainerStyle={{ paddingHorizontal: 20, paddingVertical: 25 }}
    >
      <View style={[card, { padding: 28, paddingVertical: 20 }, Shadow.high]}>
        <View row spacing={8} style={{ marginBottom: 30 }}>
          <Text text={activityPlan.title} size={21} weight="semibold" style={{ flex: 1 }} />
          <Icon
            accessibilityLabel={$t({
              id: 'EditActivityPractice_editButton',
              defaultMessage: 'Edit activity',
            })}
            onPress={() => navigate('EditActivityEvent', { practiceID: params.practiceID })}
            name="edit"
            color={Color.styleGuide.Gray4}
            testID="EditActivityPractice_editButton"
          />
        </View>
        <ActivityEventInstanceTiming
          startTime={activityPlan.startTime}
          endTime={activityPlan.endTime}
        />
        {activityPlan?.attendee ? (
          <View row spacing={16} style={{ marginTop: 20 }}>
            <Icon name="people" color={Color.primary} size={24} />
            <Text text={activityPlan.attendee} weight="semibold" size={17} />
          </View>
        ) : null}
      </View>
      <View style={{ marginTop: 40 }} spacing={12}>
        <Text
          text={$t({
            id: 'EditActivityPractice_ratingBeforeLabel',
            defaultMessage: 'What was your mood before doing this?',
          })}
          textAlign="center"
          size={17}
          weight="semibold"
          style={{ alignSelf: 'center' }}
        />
        <View style={{ paddingHorizontal: 20 }}>
          <SliderInput
            minValue={1}
            minLabel={ratingLabels[1]}
            maxValue={5}
            maxLabel={ratingLabels[5]}
            labels={getRatingsLabels($t)}
            showMinMaxValues
            {...bind('ratingBefore', {
              accessibilityLabel: $t({
                id: 'EditActivityPractice_ratingBeforeLabel',
                defaultMessage: 'What was your mood before doing this?',
              }),
            })}
          />
        </View>
      </View>
      <View style={{ marginTop: 40 }} spacing={12}>
        <Text
          text={$t({
            id: 'EditActivityPractice_ratingAfterLabel',
            defaultMessage: 'What was your mood after doing this?',
          })}
          textAlign="center"
          size={17}
          weight="semibold"
          style={{ alignSelf: 'center' }}
        />
        <View style={{ paddingHorizontal: 20 }}>
          <SliderInput
            minValue={1}
            minLabel={ratingLabels[1]}
            maxValue={5}
            maxLabel={ratingLabels[5]}
            labels={getRatingsLabels($t)}
            showMinMaxValues
            {...bind('ratingAfter', {
              accessibilityLabel: $t({
                id: 'EditActivityPractice_ratingAfterLabel',
                defaultMessage: 'What was your mood after doing this?',
              }),
            })}
          />
        </View>
      </View>
    </ScrollView>
  );
}
