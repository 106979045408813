import { useIsFocused } from '@react-navigation/native';
import { ComponentProps } from 'react';
import { Platform, SafeAreaView, StatusBar, View } from 'react-native';
import Svg, { G, Path, Symbol, Use } from 'react-native-svg';

import { BezierCurve } from '@oui/app-core/src/components/BezierCurve';

import { APPROX_STATUSBAR_HEIGHT, manifest } from '@src/constants';
import { Color } from '@src/styles';

const LogoSymbol = ({ color }: { color?: boolean }) => (
  <Symbol id="logo" viewBox="0 0 45 38">
    <G id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <G id="Home" transform="translate(-30.000000, -50.000000)" fill="#FFFFFF">
        <G id="Brand-element-white" transform="translate(30.000000, 50.000000)">
          <Path
            d="M15.8504564,25.4374759 C15.8504564,22.7825472 13.6263352,20.6577561 13.6263352,18 C13.6263352,15.3422439 16,13.2146254 16,10.5611104 C16,8.59323994 14.4664983,7 12.5740921,7 C10.6830453,7 9.1495436,8.59323994 9.1495436,10.5611104 C9.1495436,13.2146254 11.563993,15.3224521 11.563993,18 C11.563993,20.6775479 9,22.5535278 9,25.4374759 C9,27.4053464 10.5335017,29 12.4245485,29 C14.3169547,29 15.8504564,27.4053464 15.8504564,25.4374759"
            id="Fill-6"
            fill={color ? Color.styleGuide.LogoCyan : undefined}
          ></Path>
          <Path
            d="M35.8517871,25.4374759 C35.8517871,22.7825472 33.6272339,20.6577561 33.6272339,18 C33.6272339,15.3422439 36,13.2146254 36,10.5611104 C36,8.59323994 34.4662005,7 32.5747863,7 C30.6833722,7 29.1482129,8.59323994 29.1482129,10.5611104 C29.1482129,13.2146254 31.5644911,15.3224521 31.5644911,18 C31.5644911,20.6775479 29,22.5535278 29,25.4374759 C29,27.4053464 30.5337995,29 32.4252137,29 C34.3179876,29 35.8517871,27.4053464 35.8517871,25.4374759"
            id="Fill-8"
            fill={color ? Color.styleGuide.LogoCyan : undefined}
          ></Path>
          <Path
            d="M26,34.4215116 C26,36.3982558 24.4319659,38 22.5,38 C20.5666125,38 19,36.3982558 19,34.4215116 C19,32.4447674 20.5666125,26 22.5,26 C24.4333875,26 26,32.4447674 26,34.4215116"
            id="Fill-10"
            fill={color ? Color.styleGuide.LogoCyan : undefined}
          ></Path>
          <Path
            d="M26,18.5 C26,20.4333875 24.4319659,22 22.5,22 C20.5666125,22 19,20.4333875 19,18.5 C19,16.5666125 20.5666125,15 22.5,15 C24.4333875,15 26,16.5666125 26,18.5"
            id="Fill-12"
            fill={color ? Color.styleGuide.LogoLilac : undefined}
          ></Path>
          <Path
            d="M25.7927,4.34835 C25.7927,6.38835 24.1382,11.93835 22.0997,11.93835 C20.0597,11.93835 18.4067,6.38835 18.4067,4.34835 C18.4067,2.30835 20.0597,0.65535 22.0997,0.65535 C24.1397,0.65535 25.7927,2.30835 25.7927,4.34835"
            id="Fill-14"
            fill={color ? Color.styleGuide.LogoCyan : undefined}
          ></Path>
          <Path
            d="M45,18.5 C45,20.4333875 43.4319659,22 41.5,22 C39.5666125,22 38,20.4319659 38,18.5 C38,16.5666125 39.5666125,15 41.5,15 C43.4319659,15 45,16.5666125 45,18.5"
            id="Fill-17"
            fill={color ? Color.styleGuide.LogoCyan : undefined}
          ></Path>
          <Path
            d="M7,18.5 C7,20.4333875 5.43196588,22 3.5,22 C1.56661251,22 0,20.4319659 0,18.5 C0,16.5666125 1.56661251,15 3.5,15 C5.43338749,15 7,16.5666125 7,18.5"
            id="Fill-19"
            fill={color ? Color.styleGuide.LogoCyan : undefined}
          ></Path>
        </G>
      </G>
    </G>
  </Symbol>
);

const LogoInner = ({
  color,
  width,
  height,
}: {
  color?: boolean;
  width: number | string;
  height: number | string;
}) => {
  return (
    <>
      <LogoSymbol color={color} />
      <Use href="#logo" x="0" y="0" width={width} height={height} />
    </>
  );
};

export function Logo({
  color,
  width,
  height,
}: {
  color?: boolean;
  height: number | string;
  width: number | string;
}) {
  const a11yProps = {
    accessibilityRole: 'image' as const,
    [Platform.OS === 'web'
      ? ('aria-label' as string as 'accessibilityLabel')
      : 'accessibilityLabel']: `${manifest.name} Logo`,
  };
  return (
    <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} accessible {...a11yProps}>
      <LogoInner width={width} height={height} color={color} />
    </Svg>
  );
}

export const SvgWithLogoAndBezierCurve = ({
  showLogo,
  ...props
}: ComponentProps<typeof BezierCurve> & { showLogo?: boolean }) => {
  const a11yProps = showLogo
    ? {
        accessible: true,
        accessibilityRole: 'image' as const,
        [Platform.OS === 'web'
          ? ('aria-label' as string as 'accessibilityLabel')
          : 'accessibilityLabel']: `${manifest.name} Logo`,
        asdf: 1,
      }
    : undefined;

  return (
    <BezierCurve {...props} {...a11yProps}>
      <LogoSymbol />
      {showLogo ? <Use href="#logo" x="10" y="0" width="30" height="25" /> : null}
    </BezierCurve>
  );
};

export function ScreenHeader(props: { color?: string; hideLogo?: boolean }) {
  const isFocused = useIsFocused();
  return (
    <View style={{ width: '100%', zIndex: 1, position: 'absolute', top: 0 }}>
      <SafeAreaView
        style={{
          backgroundColor: props.color ?? Color.styleGuide.LogoCyan,
          paddingTop: APPROX_STATUSBAR_HEIGHT + 10,
        }}
      >
        {isFocused ? (
          <StatusBar barStyle="light-content" translucent backgroundColor="#00000000" />
        ) : null}
      </SafeAreaView>
      <SvgWithLogoAndBezierCurve
        showLogo={!props.hideLogo}
        viewBoxHeight={50}
        viewBoxWidth={300}
        startPoint={[0, 40]}
        firstControlPoint={[50, 70]}
        secondControlPoint={[200, -20]}
        endPoint={[300, 10]}
        fill={props.color}
      />
    </View>
  );
}
