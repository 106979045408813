import { useCallback, useState } from 'react';
import { View } from 'react-native';

import { CrisisTimeline } from '@oui/lib/src/types/avro/crisisTimeline';
import { CrisisTimelineList } from '@oui/myplan/src/components/CrisisTimelineList';
import { RiskCurveGraph } from '@oui/myplan/src/components/RiskCurve';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import {
  PatientMyStoryMyPlanContainer,
  PatientMyStoryMyPlanContainerSection,
} from '@src/components/PatientMyStoryMyPlanContainer';
import {
  useMyStoryMyPlanCompositionSectionSubscriptionData,
  useMyStoryMyPlanCompositionSections,
} from '@src/hooks/useComposition';
import { useI18n } from '@src/lib/i18n';

function PatientRiskCurveReviewInner({ crisisTimeline }: { crisisTimeline: CrisisTimeline }) {
  const [activeItemIndex, setActiveItemIndex] = useState<number | undefined>(undefined);
  const onPressItem = useCallback((_: unknown, i: number) => setActiveItemIndex(i), []);
  const { $t } = useI18n();

  return (
    <PatientMyStoryMyPlanContainer
      heading={$t({ id: 'PatientRiskCurveReview_heading', defaultMessage: 'My risk curve' })}
      testID="PatientRiskCurveReview"
    >
      <View style={{ paddingHorizontal: 38 }}>
        <RiskCurveGraph
          {...crisisTimeline}
          activeItemIndex={activeItemIndex}
          onPressPoint={onPressItem}
          onPress={() => setActiveItemIndex(undefined)}
          numRiskFactors={0}
        />
      </View>
      <PatientMyStoryMyPlanContainerSection alternate grow>
        <CrisisTimelineList {...crisisTimeline} showRiskColor={true} onPress={onPressItem} />
      </PatientMyStoryMyPlanContainerSection>
    </PatientMyStoryMyPlanContainer>
  );
}

export function PatientRiskCurveReview() {
  const { data } = useMyStoryMyPlanCompositionSectionSubscriptionData();
  const crisisTimeline = data?.CRISIS_TIMELINE;
  if (!crisisTimeline) return <ActivityIndicator />;
  return <PatientRiskCurveReviewInner crisisTimeline={crisisTimeline} />;
}

export function PatientRiskCurveReviewStatic() {
  const { data } = useMyStoryMyPlanCompositionSections();
  const crisisTimeline = data?.CRISIS_TIMELINE;
  if (!crisisTimeline) return <ActivityIndicator />;
  return <PatientRiskCurveReviewInner crisisTimeline={crisisTimeline} />;
}
