import { REST_API_URL } from '@src/constants';

let approxLocation: { lat: number; lng: number } | undefined = undefined;
export async function getApproximateLocation() {
  if (approxLocation) return approxLocation;
  const result = await fetch(`${REST_API_URL}/geolocate`);
  const json = await result.json();
  if (json.citylatlong) {
    const [lat, lng] = json.citylatlong.split(',').map((n: string) => Number.parseFloat(n));
    approxLocation = { lat, lng };
    return approxLocation;
  }
  return undefined;
}
