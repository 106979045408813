import { Component } from 'react';
import { Image, ScrollView, TouchableOpacity, View } from 'react-native';

import { Text } from '@src/components/Text';
import {
  ChatInputBaseProps,
  ChatInputCarouselProps,
  ChatInputCarouselPropsItem as Item,
  Kind,
} from '@src/types';

type Props = ChatInputBaseProps & ChatInputCarouselProps;

export default class ChatInputCarousel extends Component<Props> {
  static defaultProps = {
    items: [
      {
        ID: '1',
        label: 'Cat 1',
        thumbnail: 'https://placekitten.com/625/225',
      },
      {
        ID: '2',
        label: 'Cat 2',
        thumbnail: 'https://placekitten.com/425/225',
      },
      {
        ID: '3',
        label: 'Cat 3',
        thumbnail: 'https://placekitten.com/425/340',
      },
    ],
  };

  onChooseItem(value: string) {
    const { onInput } = this.props;
    if (onInput) {
      onInput({ kind: Kind.InputCarousel, props: value });
    }
  }

  renderItem(item: Item) {
    return (
      <TouchableOpacity
        key={item.ID}
        style={{
          width: 250,
          height: 200,
          backgroundColor: 'white',
          borderRadius: 16,
          borderTopStartRadius: 0,
          padding: 16,
          alignItems: 'center',
          marginRight: 12,
        }}
        onPress={() => this.onChooseItem(item.ID)}
      >
        <View style={{ flex: 1, alignSelf: 'stretch', marginBottom: 16 }}>
          <Image
            style={{ width: '100%', height: '100%', borderRadius: 16 }}
            source={{ uri: item.thumbnail }}
          />
        </View>
        <Text text={item.label} color="#f46123" weight="bold" size={18} />
      </TouchableOpacity>
    );
  }

  render() {
    return (
      <View
        style={{
          alignItems: 'center',
          padding: 12,
        }}
      >
        <ScrollView horizontal={true} style={{ marginVertical: 8, minWidth: '100%' }}>
          {this.props.items.map((i) => this.renderItem(i))}
        </ScrollView>
        {this.props.allowSkip ? (
          <TouchableOpacity onPress={() => this.onChooseItem('')}>
            <Text
              text="Skip"
              color="#f46123"
              weight="bold"
              style={{ marginVertical: 8 }}
              size={18}
            />
          </TouchableOpacity>
        ) : null}
      </View>
    );
  }
}
