import { Component } from 'react';

import ChatInputMultipleChoice from '@src/components/ChatInputMultipleChoice';
import ChatInputSingleChoice from '@src/components/ChatInputSingleChoice';
import { ChatInputBaseProps, ChatInputChoiceProps } from '@src/types';

export default class ChatInputChoice extends Component<ChatInputBaseProps & ChatInputChoiceProps> {
  static defaultProps = {
    min: 1,
    max: 1,
    label: {},
  };

  render() {
    return this.props.min === 1 && this.props.max === 1 ? (
      <ChatInputSingleChoice {...this.props} legacyChoice={true} />
    ) : (
      <ChatInputMultipleChoice {...this.props} legacyChoice={true} />
    );
  }
}
