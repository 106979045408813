import { StackNavigationOptions } from '@react-navigation/stack';
import { createDraft, finishDraft } from 'immer';
import { useCallback, useEffect, useState } from 'react';
import { IntlShape } from 'react-intl';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Button } from '@oui/app-core/src/components/Button';
import { ConfirmationModal } from '@oui/app-core/src/components/ConfirmationModal';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import { RoundedSectionNavigationOptions } from '@oui/app-core/src/components/RoundedSection';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useMyStoryMyPlanCompositionSections } from '@oui/app-core/src/hooks/useComposition';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { logEvent } from '@oui/app-core/src/lib/log';
import { Color, card } from '@oui/app-core/src/styles';

import { EnvironmentSafety, PatientMyPlan } from '@src/components/PatientMyPlan';
import { useIsEnvironmentSafetyLocked } from '@src/hooks/useIsEnvironmentSafetyLocked';
import { useMaybeReplaceImageAssetKeyURIWithResumableUpload } from '@src/hooks/useMaybeReplaceImageAssetKeyURIWithResumableUpload';
import { StackScreenProps } from '@src/types/navigation';

function getHeaderOptions({
  $t,
  isEditing,
  onPressBack,
  onPressCancel,
  onPressSave,
}: {
  $t: IntlShape['$t'];
  isEditing: boolean;
  onPressBack?: () => void;
  onPressCancel?: () => void;
  onPressSave?: () => void;
}): Partial<StackNavigationOptions> {
  return isEditing
    ? {
        headerLeft: ({ tintColor }) => {
          return (
            <HeaderButtons>
              <HeaderItem
                iconName="close"
                color={tintColor}
                title=""
                onPress={onPressCancel}
                accessibilityLabel={$t({ id: 'EditMyPlan_cancelButton', defaultMessage: 'Cancel' })}
              />
            </HeaderButtons>
          );
        },
        headerRight: ({ tintColor }) => (
          <HeaderButtons>
            <Button
              testID="EditMyPlan_saveButton"
              text={$t({ id: 'EditMyPlan_saveButton', defaultMessage: 'Save' })}
              color={tintColor}
              onPress={onPressSave}
              style={{
                backgroundColor: 'transparent',
                borderColor: tintColor,
              }}
            />
          </HeaderButtons>
        ),
      }
    : {
        headerLeft: ({ tintColor }) => {
          return (
            <HeaderButtons>
              <HeaderItem
                iconName="arrow-left"
                color={tintColor}
                title=""
                onPress={onPressBack}
                accessibilityLabel={$t({ id: 'EditMyPlan_backButton', defaultMessage: 'Back' })}
              />
            </HeaderButtons>
          );
        },
        headerRight: () => null,
      };
}

export const MyPlanOptions = ({
  $t,
  onPressSave,
  defaultHeaderHeight,
}: {
  $t: IntlShape['$t'];
  onPressSave: () => Promise<unknown>;
  defaultHeaderHeight: number;
}) => ({
  ...RoundedSectionNavigationOptions({
    defaultHeaderHeight,
    tintColor: 'white',
    backgroundColor: Color.styleGuide.LogoLilac,
  }),
  ...getHeaderOptions({ $t, isEditing: false }),
});

export function EditMyPlan(props: {
  navigation: StackScreenProps<'EditMyPlan'>['navigation'] | null;
  route: StackScreenProps<'EditMyPlan'>['route'] | null;
  preview?: boolean;
}) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const {
    update: updateMyPlan,
    data,
    loading,
  } = useMyStoryMyPlanCompositionSections({
    createIfUndefined: global.e2e,
  });
  const defaultEditingSection = props.route?.params?.editingSection ?? null;
  const [editingSection, setEditingSection] = useState<keyof NonNullable<typeof data> | null>(
    defaultEditingSection,
  );
  const [unsavedData, setUnsavedData] = useState<Partial<NonNullable<typeof data>>>({});
  const setOptions = props.navigation?.setOptions;
  const goBack = props.navigation?.goBack;
  const maybeReplaceImageAssetKeyURIWithResumableUpload =
    useMaybeReplaceImageAssetKeyURIWithResumableUpload();
  const environmentSafetyLocked = useIsEnvironmentSafetyLocked();
  const { $t } = useI18n();

  const cancel = useCallback(() => {
    // if defaultEditingSection is present, that means we've navigated here from SoloMyPlan
    if (defaultEditingSection) {
      goBack?.();
    } else {
      setUnsavedData({});
      setEditingSection(null);
    }
  }, [goBack, defaultEditingSection]);

  const save = useCallback(async () => {
    logEvent('update_myplan');

    const draft = createDraft(unsavedData);
    await Promise.all([
      ...(draft.SOCIAL_DISTRACTIONS?.contacts ?? []).map(async (contact) => {
        return maybeReplaceImageAssetKeyURIWithResumableUpload('SOCIAL_DISTRACTIONS', contact);
      }),
      ...(draft.HELP_CONTACTS ?? []).map(async (contact) => {
        return maybeReplaceImageAssetKeyURIWithResumableUpload('HELP_CONTACTS', contact);
      }),
      ...(draft.PROFESSIONAL_HELP_CONTACTS ?? []).map(async (contact) => {
        contact.contact;
        return maybeReplaceImageAssetKeyURIWithResumableUpload(
          'PROFESSIONAL_HELP_CONTACTS',
          contact.contact,
        );
      }),
      ...(draft.ENVIRONMENT_SAFETY?.supportContacts ?? []).map(async (contact) => {
        return maybeReplaceImageAssetKeyURIWithResumableUpload('ENVIRONMENT_SAFETY', contact);
      }),
    ]);
    const unsavedDataWithCleanedAssetKeys = finishDraft(draft);

    return updateMyPlan(unsavedDataWithCleanedAssetKeys).then(() => {
      if (defaultEditingSection) {
        goBack?.();
      } else {
        setEditingSection(null);
      }
    });
  }, [
    unsavedData,
    updateMyPlan,
    goBack,
    defaultEditingSection,
    maybeReplaceImageAssetKeyURIWithResumableUpload,
  ]);

  useEffect(() => {
    if (setOptions) {
      const isDirty = Object.keys(unsavedData).length > 0;
      setOptions(
        getHeaderOptions({
          $t,
          isEditing: !!editingSection,
          onPressBack: () => goBack?.(),
          onPressCancel: () => {
            if (isDirty) {
              setShowConfirmationModal(true);
            } else {
              cancel();
            }
          },
          onPressSave: () => {
            return save();
          },
        }),
      );
    }
  }, [setOptions, data, unsavedData, goBack, editingSection, cancel, save, $t]);

  const onEditMyPlan = useCallback((toMerge: typeof unsavedData) => {
    setUnsavedData((curr) => ({ ...curr, ...toMerge }));
  }, []);

  return data ? (
    <ScrollView
      testID="EditMyPlan"
      style={{ flex: 1, backgroundColor: Color.grayBackground }}
      contentContainerStyle={[
        {
          alignSelf: 'stretch',
          padding: 20,
        },
      ]}
    >
      {editingSection !== 'ENVIRONMENT_SAFETY' ? (
        <View style={[card, { padding: 20, borderColor: Color.accent, borderWidth: 2 }]}>
          <View
            style={{
              paddingHorizontal: 20,
              paddingBottom: 10,
              marginBottom: 10,
              marginTop: -10,
              marginHorizontal: -20,
              borderBottomWidth: 1,
              borderColor: Color.styleGuide.Gray6,
            }}
          >
            <Text
              text={$t({ id: 'EditMyPlan_stepsHeading', defaultMessage: 'Safety steps' })}
              color={Color.styleGuide.Gray3}
              weight="semibold"
              accessibilityRole="header"
            />
          </View>
          <PatientMyPlan
            data={{ ...data, ...unsavedData }}
            onStartEditingSection={(section) => {
              setEditingSection(section);
              setUnsavedData({});
            }}
            editingSection={editingSection}
            focusedSection={defaultEditingSection}
            isEditing={true}
            onEdit={onEditMyPlan}
          />
        </View>
      ) : null}
      {!environmentSafetyLocked && (!editingSection || editingSection === 'ENVIRONMENT_SAFETY') ? (
        <View
          style={[card, { marginTop: 30, padding: 20, borderColor: Color.accent, borderWidth: 2 }]}
        >
          <EnvironmentSafety
            data={{ ...data, ...unsavedData }}
            isEditing={true}
            editingSection={editingSection}
            focusedSection={defaultEditingSection}
            onStartEditingSection={(section) => {
              setEditingSection(section);
              setUnsavedData({});
            }}
            onEdit={onEditMyPlan}
          />
        </View>
      ) : null}
      <ConfirmationModal
        onCancel={() => {
          cancel();
          setShowConfirmationModal(false);
        }}
        onConfirm={() => {
          return save().then(() => {
            setShowConfirmationModal(false);
          });
        }}
        visible={showConfirmationModal}
        confirmText={$t({
          id: 'EditMyPlan_confirmationModal_confirmButton',
          defaultMessage: 'Save',
        })}
        cancelText={$t({
          id: 'EditMyPlan_confirmationModal_cancelButton',
          defaultMessage: 'Discard',
        })}
        title={$t({ id: 'EditMyPlan_confirmationModal_title', defaultMessage: 'Save changes?' })}
        description={$t({
          id: 'EditMyPlan_confirmationModal_description',
          defaultMessage: "You've made edits to MyPlan. Would you like to save them?",
        })}
      />
    </ScrollView>
  ) : loading ? (
    <ActivityIndicator />
  ) : null;
}
