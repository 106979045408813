import { useNavigation, useRoute } from '@react-navigation/native';
import equals from 'fast-deep-equal';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { useDebounce } from 'use-debounce';

import {
  CardStack,
  Card as CardStackCard,
  CardStackRef,
} from '@oui/app-core/src/components/CardStack';
import { Icon } from '@oui/app-core/src/components/Icon';
import { RadioInput } from '@oui/app-core/src/components/RadioInput';
import { useKvRespondMutation } from '@oui/app-core/src/lib/userResponse.graphql.generated';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { Button } from '@src/components/Button';
import { ConfirmationModal } from '@src/components/ConfirmationModal';
import { DateTimeInput } from '@src/components/DateTimeInput';
import { HeaderButtons, HeaderItem } from '@src/components/HeaderButtons';
import { ScrollView } from '@src/components/ScrollView';
import { Lead, Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { SleepDiaryConfigInfluencerFrequency, useSleepDiaryConfig } from '@src/hooks/practices';
import { useForm } from '@src/hooks/useForm';
import { useI18n } from '@src/lib/i18n';
import { recordKeys } from '@src/lib/recordKeys';
import { SLEEP_INFLUENCER_ICONS, getSleepDelayItems } from '@src/screens/SleepDiaryEntry';
import { useTheme } from '@src/styles';
import { StackScreenProps } from '@src/types';

const SLEEP_INFLUENCER_LABELS = {
  caffeine: 'Drink caffeine?',
  alcohol: 'Drink alcohol?',
  tobacco: 'Smoke tobacco?',
  napping: 'Nap during the day?',
  exercise: 'Exercise 20+ min a day?',
  medicine: 'Take sleep medication?',
  deviceInBed: 'Use a device (cell, laptop, TV) in bed?',
};

function SleepInfluencerCard(props: {
  label: string;
  type: keyof typeof SLEEP_INFLUENCER_ICONS;
  value?: number;
  onChangeValue: (newValue: number) => void;
}) {
  const { Color } = useTheme();
  return (
    <View spacing={30}>
      <RadioInput
        testID={`SetupSleepDiary_influencer_${props.type}`}
        value={props.value}
        onChangeValue={props.onChangeValue}
        accessibilityLabel={props.label}
        label={() => {
          return (
            <View spacing={12} style={{ alignItems: 'center', alignSelf: 'center' }}>
              <View style={{ padding: 10, backgroundColor: '#d2dfff', borderRadius: 40 }}>
                <Icon size={25} name={SLEEP_INFLUENCER_ICONS[props.type]} color={Color.tertiary} />
              </View>
              <Lead text={props.label} style={{ marginBottom: 25 }} textAlign="center" />
            </View>
          );
        }}
        items={[
          { label: 'Regularly', value: SleepDiaryConfigInfluencerFrequency.Regularly },
          { label: 'Sometimes', value: SleepDiaryConfigInfluencerFrequency.Sometimes },
          { label: 'Rarely', value: SleepDiaryConfigInfluencerFrequency.Rarely },
          { label: 'Never', value: SleepDiaryConfigInfluencerFrequency.Never },
        ]}
      />
    </View>
  );
}

export function SetupSleepDiary() {
  const { addListener, navigate, replace, setOptions } =
    useNavigation<StackScreenProps<'SetupSleepDiary'>['navigation']>();
  const route = useRoute<StackScreenProps<'SetupSleepDiary'>['route']>();
  const { Color } = useTheme();
  const [step, setStep] = useState(0);
  const { data: persistedData, loading, isComplete } = useSleepDiaryConfig();
  const [saveSleepDiaryConfiguration] = useKvRespondMutation();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const cardStackRef = useRef<CardStackRef>(null);
  const { data, bind } = useForm<NonNullable<typeof persistedData>>({
    startHHMM: undefined,
    endHHMM: undefined,
    sleepDelay: undefined,
    caffeine: undefined,
    alcohol: undefined,
    tobacco: undefined,
    napping: undefined,
    exercise: undefined,
    medicine: undefined,
    deviceInBed: undefined,
    ...persistedData,
  });

  const { $t } = useI18n();

  useEffect(() => {
    return addListener('beforeRemove', (e) => {
      if (!isComplete && !showConfirmationModal) {
        e.preventDefault();
        setShowConfirmationModal(true);
      }
    });
  }, [isComplete, addListener, showConfirmationModal]);

  const [debouncedValue] = useDebounce(data, 500, { maxWait: 5000, equalityFn: equals });
  useEffect(() => {
    if (!loading) {
      const hasDefinedValue = !!Object.values(debouncedValue).find((v) => typeof v !== 'undefined');
      if (hasDefinedValue) {
        saveSleepDiaryConfiguration({
          variables: {
            context: 'sleepDiary',
            key: 'config',
            data: debouncedValue,
          },
        });
      }
    }
  }, [loading, saveSleepDiaryConfiguration, debouncedValue]);

  const fromSleepDiary = route.params?.fromSleepDiary;
  const goToSleepDiary = useCallback(() => {
    if (fromSleepDiary) {
      navigate('SleepDiary', {});
    } else {
      replace('SleepDiary', {});
    }
  }, [fromSleepDiary, navigate, replace]);

  useEffect(() => {
    setOptions({
      headerLeft:
        step === 0
          ? undefined
          : ({ tintColor }) => (
              <HeaderButtons>
                <HeaderItem
                  color={tintColor}
                  title=""
                  accessibilityLabel={step === 0 ? 'Close' : 'Previous'}
                  iconName={step === 0 ? 'close' : 'caret-left'}
                  onPress={() => {
                    setStep((s) => s - 1);
                  }}
                />
              </HeaderButtons>
            ),
      headerRight: () => (
        <HeaderButtons>
          <Button
            text={step === 0 ? 'Next' : 'Done'}
            testID={step === 0 ? 'SetupSleepDiary_nextButton' : 'SetupSleepDiary_saveButton'}
            onPress={() => {
              if (step === 0) {
                setStep(step + 1);
              } else {
                goToSleepDiary();
              }
            }}
          />
        </HeaderButtons>
      ),
    });
  }, [step, setOptions, goToSleepDiary]);

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: Color.grayBackground }}
      contentContainerStyle={{
        paddingHorizontal: 24,
        paddingVertical: 25,
        paddingBottom: 100,
        flexGrow: 1,
      }}
      testID="SetupSleepDiary_scrollView"
    >
      {loading && !persistedData ? (
        <ActivityIndicator />
      ) : step === 0 ? (
        <View style={{}} spacing={60}>
          <View spacing={12} style={{ marginHorizontal: 12 }}>
            <Text
              text="What time do you usually..."
              size={21}
              weight="semibold"
              accessibilityRole="header"
            />
            <View row style={{ justifyContent: 'space-between' }} spacing={20}>
              <View
                importantForAccessibility="no-hide-descendants"
                accessibilityElementsHidden
                row
                flex={2}
              >
                <Text weight="semibold" text="In bed at" style={{ flex: 1 }} />
              </View>
              <DateTimeInput
                accessibilityLabel="In bed at"
                mode="time"
                minuteInterval={Platform.select({ default: 15, android: 5 })}
                {...bind('startHHMM', { accessibilityLabel: 'Get into bed' })}
                style={{ flex: 3 }}
              />
            </View>
            <View row style={{ justifyContent: 'space-between' }} spacing={20}>
              <View
                importantForAccessibility="no-hide-descendants"
                accessibilityElementsHidden
                row
                flex={2}
              >
                <Text weight="semibold" text="Out of bed at" style={{ flex: 1 }} />
              </View>
              <DateTimeInput
                accessibilityLabel="Out of bed at"
                mode="time"
                minuteInterval={Platform.select({ default: 15, android: 5 })}
                {...bind('endHHMM', { accessibilityLabel: 'Get out of bed' })}
                style={{ flex: 3 }}
              />
            </View>
          </View>
          <View>
            <RadioInput
              label=""
              {...bind('sleepDelay', {
                label: 'How long does it normally take you to fall asleep?',
              })}
              labelSize="large"
              items={getSleepDelayItems($t)}
            />
          </View>
          <View>
            <RadioInput
              label=""
              {...bind('wakeupCount', {
                label: 'How often do you typically wake up during the night?',
              })}
              labelSize="large"
              items={[
                { label: 'Never', value: 0 },
                { label: '1-3 times', value: 1 },
                { label: '4-6 times', value: 4 },
                { label: 'More than 7 times', value: 7 },
              ]}
            />
          </View>
        </View>
      ) : step >= 1 && step <= 7 ? (
        <>
          <Text
            text="How often do you..."
            size={21}
            weight="semibold"
            color={Color.tertiary}
            textAlign="center"
            style={{ marginBottom: 30 }}
            accessibilityRole="header"
          />
          <CardStack hideNextButtonIndexes={[0, 1, 2, 3, 4, 5, 6]} ref={cardStackRef}>
            {recordKeys(SLEEP_INFLUENCER_ICONS).map((influencer) => {
              const { onChangeValue, ...rest } = bind(influencer, {
                label: SLEEP_INFLUENCER_LABELS[influencer],
              });
              return (
                <CardStackCard key={influencer}>
                  <SleepInfluencerCard
                    {...rest}
                    onChangeValue={(newValue) => {
                      onChangeValue(newValue);
                      setTimeout(() => {
                        cardStackRef.current?.next();
                      }, 200);
                    }}
                    label={SLEEP_INFLUENCER_LABELS[influencer]}
                    type={influencer}
                  />
                </CardStackCard>
              );
            })}
          </CardStack>
        </>
      ) : null}
      <ConfirmationModal
        onCancel={() => {
          setShowConfirmationModal(false);
          goToSleepDiary();
        }}
        onConfirm={() => {
          setShowConfirmationModal(false);
        }}
        visible={showConfirmationModal}
        confirmText="Yes, finish"
        cancelText="Do it later"
        title="Finish setup?"
        description="You're almost done setting up your sleep diary. Would you like to finish?"
      />
    </ScrollView>
  );
}
