import type { ApolloClient } from '@apollo/client';

import { transitionBot as _transitionBot } from '@oui/lib/src/bot';

import { logEvent } from '@src/lib/log';

export const transitionBot = async (
  apolloClient: ApolloClient<unknown>,
  configID: Parameters<typeof _transitionBot>[0],
  currentStateHash: Parameters<typeof _transitionBot>[1],
  action: Parameters<typeof _transitionBot>[2],
  { forceLegacyStaticContent }: { forceLegacyStaticContent?: boolean } = {},
) => {
  return _transitionBot(configID, currentStateHash, action, {
    forceLegacyStaticContent: forceLegacyStaticContent ?? false,
    apolloClient,
    logEvent,
  });
};
