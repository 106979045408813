// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentUserIdQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserIdQuery = { readonly currentUser: { readonly ID: CustomScalars.GQLUserID } };


export const CurrentUserIdDocument = gql`
    query currentUserId {
  currentUser {
    ID
  }
}
    `;

/**
 * __useCurrentUserIdQuery__
 *
 * To run a query within a React component, call `useCurrentUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserIdQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserIdQuery, CurrentUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserIdQuery, CurrentUserIdQueryVariables>(CurrentUserIdDocument, options);
      }
export function useCurrentUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserIdQuery, CurrentUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserIdQuery, CurrentUserIdQueryVariables>(CurrentUserIdDocument, options);
        }
export type CurrentUserIdQueryHookResult = ReturnType<typeof useCurrentUserIdQuery>;
export type CurrentUserIdLazyQueryHookResult = ReturnType<typeof useCurrentUserIdLazyQuery>;

export function queryCurrentUserId(client: Apollo.ApolloClient<unknown>, variables?: CurrentUserIdQueryVariables | undefined, options?: Omit<Apollo.QueryOptions<CurrentUserIdQuery, CurrentUserIdQueryVariables>, "query" | 'variables'>) {
  return client.query<CurrentUserIdQuery, CurrentUserIdQueryVariables>({
    query: CurrentUserIdDocument,
    variables,
    ...options,
  });
}
