import { ApolloClient, useApolloClient } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  BottomTabNavigationOptions,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import { HeaderBackButton } from '@react-navigation/elements';
import { StackNavigationOptions } from '@react-navigation/stack';
import { AsyncStorageWrapper, CachePersistor, MMKVWrapper } from 'apollo3-cache-persist';
import { ComponentProps, ComponentType, forwardRef, useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';
import { Platform, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { AppContainer } from '@oui/app-core/src/components/AppContainer';
import { RoundedSectionNavigationOptions } from '@oui/app-core/src/components/RoundedSection';
import { usePersistedState } from '@oui/app-core/src/hooks/usePersistedState';
import { shouldShowWebBlocker } from '@oui/app-core/src/lib/initApp';
import { getMmkv, initMmkv } from '@oui/app-core/src/lib/mmkv';
import { getConfigString, initLastConfig } from '@oui/app-core/src/lib/remoteConfig';
import Auth from '@oui/app-core/src/screens/Auth';
import { CreateTestUser } from '@oui/app-core/src/screens/CreateTestUser';
import LocalAuthenticationPrompt from '@oui/app-core/src/screens/LocalAuthenticationPrompt';
import { QuizSet } from '@oui/app-core/src/screens/QuizSet';
import { TermsAndPrivacy } from '@oui/app-core/src/screens/TermsAndPrivacy';
import { TestIntl } from '@oui/app-core/src/screens/TestIntl';
import { AuthParamList, DeeplinkConfigShape } from '@oui/app-core/src/types/navigation';
import { StaticTabNavigator } from '@oui/app-static/src/StaticTabNavigator';
import { StaticReview } from '@oui/app-static/src/screens/StaticReview';
import { StaticSession } from '@oui/app-static/src/screens/StaticSession';
import { StaticTabParamList } from '@oui/app-static/src/types/navigation';
import { ProductVariant } from '@oui/lib/src/types/graphql.generated';
import { EditMyPlan } from '@oui/myplan/src/screens/EditMyPlan';
import { MyPlanRounded, MyPlanRoundedOptions } from '@oui/myplan/src/screens/MyPlanRounded';

import FullLogo from '@src/assets/Full_logo.svg';
import { HeaderButtons, HeaderItem } from '@src/components/HeaderButtons';
import { Icon } from '@src/components/Icon';
import { Text } from '@src/components/Text';
import { DEFAULT_HEADER_MODE, FLAGS, IS_PRODUCTION, SESSION_TIMEOUT } from '@src/constants';
import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { useHopeKitName } from '@src/hooks/useHopeKitName';
import { createApolloCache, createApolloClient } from '@src/lib/apolloClient';
import { createSessionTimeoutStackNavigator } from '@src/lib/createSessionTimeoutNavigator';
import { useDefaultHeaderHeight } from '@src/lib/getDefaultHeaderHeight';
import { useI18n } from '@src/lib/i18n';
import { setOrganizationUserProperties } from '@src/lib/setOrganizationUserProperties';
import AccountSettings from '@src/screens/AccountSettings';
import { ActivityDiary } from '@src/screens/ActivityDiary';
import { ActivityPractice } from '@src/screens/ActivityPractice';
import { AddHopeKit } from '@src/screens/AddHopeKit';
import { CMSConversationPreview } from '@src/screens/CMSConversationPreview';
import CRP from '@src/screens/CRP';
import { Confidentiality } from '@src/screens/Confidentiality';
import ContactsPicker from '@src/screens/ContactsPicker';
import {
  ClinicianControlledMyStoryMyPlanPreview,
  PatientControlledMyStoryMyPlan,
} from '@src/screens/ControlledMyStoryMyPlan';
import { Conversation } from '@src/screens/Conversation';
import { CopingCards } from '@src/screens/CopingCards';
import { EditActivityEvent } from '@src/screens/EditActivityEvent';
import { EditActivityPractice } from '@src/screens/EditActivityPractice';
import { EditCopingCards } from '@src/screens/EditCopingCards';
import { EditLessonsLearned } from '@src/screens/EditLessonsLearned';
import { EditSleepDiaryEntry } from '@src/screens/EditSleepDiaryEntry';
import { EditSuicideMode } from '@src/screens/EditSuicideMode';
import { EditThoughtDiaryEntry } from '@src/screens/EditThoughtDiaryEntry';
import { FinishPatientRegistration } from '@src/screens/FinishPatientRegistration';
import { HomeV2 } from '@src/screens/HomeV2';
import { HopeKit } from '@src/screens/HopeKit';
import { Learn } from '@src/screens/Learn';
import { PlacesPicker } from '@src/screens/LocationPicker';
import MediaPicker from '@src/screens/MediaPicker';
import { MyPlanReview } from '@src/screens/MyPlanReview';
import { PatientMyStoryMyPlan } from '@src/screens/PatientMyStoryMyPlan';
import { Practice } from '@src/screens/Practice';
import { Relaxation } from '@src/screens/Relax';
import { SetupSleepDiary } from '@src/screens/SetupSleepDiary';
import { SleepDiary } from '@src/screens/SleepDiary';
import { SleepDiaryEntry } from '@src/screens/SleepDiaryEntry';
import { SoloMyPlan } from '@src/screens/SoloMyPlan';
import { SoloRiskCurve } from '@src/screens/SoloRiskCurve';
import { SoloSuicideMode } from '@src/screens/SoloSuicideMode';
import { TestArtifactResult } from '@src/screens/TestArtifactResult';
import { TestWidget } from '@src/screens/TestWidget';
import { ThoughtDiary } from '@src/screens/ThoughtDiary';
import { ThoughtDiaryEntry } from '@src/screens/ThoughtDiaryEntry';
import UserProfile from '@src/screens/UserProfile';
import WebBlocker from '@src/screens/WebBlocker';
import Welcome from '@src/screens/Welcome';
import Sentry from '@src/sentry';
import { useTheme } from '@src/styles';
import { MyStoryMyPlanStackParamList, RootStackParamList, Tab2ParamList } from '@src/types';

const GoToAidIcon = ({ onPress, color: iconColor }: { onPress: () => void; color?: string }) => {
  const { $t } = useI18n();
  const { Color } = useTheme();
  return (
    <HeaderButtons>
      <HeaderItem
        iconName="my-plan"
        color={iconColor || Color.accent}
        title=""
        onPress={onPress}
        accessibilityLabel={$t({ id: 'GoToAidIcon_button', defaultMessage: 'View MyPlan' })}
        testID="GoToAidIcon"
      />
    </HeaderButtons>
  );
};

function TabLabel(props: { focused: boolean; color: string; text: string }) {
  return (
    <View
      style={{
        alignItems: 'center',
        paddingVertical: 2,
        marginTop: -4,
        width: '100%',
      }}
    >
      <Text
        text={props.text}
        color={props.color}
        size={13}
        weight="semibold"
        numberOfLines={1}
        textAlign="center"
      />
      <View
        style={{
          backgroundColor: props.focused ? props.color : 'transparent',
          height: 2,
          width: '70%',
        }}
      ></View>
    </View>
  );
}

const tabLabels = defineMessages({
  home: { id: 'Tabs_homeTab', defaultMessage: 'Home' },
  learn: { id: 'Tabs_learnTab', defaultMessage: 'Learn' },
  practice: { id: 'Tabs_practiceTab', defaultMessage: 'Practice' },
  myPlan: { id: 'Tabs_myPlanTab', defaultMessage: 'MyPlan' },
  profile: { id: 'Tabs_profileTab', defaultMessage: 'Profile' },
});

const Tab2 = createBottomTabNavigator<Tab2ParamList>();
const Tab2Navigator = () => {
  const insets = useSafeAreaInsets();
  const { Color } = useTheme();
  const { $t } = useI18n();

  const tabAccessibilityLabel = (values: { tabName: string; count: number }) => {
    return $t(
      { id: 'Tabs_tabAccessibilityLabel', defaultMessage: '{tabName}, {count} of {total}' },
      { ...values, total: 5 },
    ) as string;
  };

  return (
    <Tab2.Navigator
      screenOptions={({ route }) => {
        const options: BottomTabNavigationOptions = {
          headerShown: false,
          tabBarStyle: { height: 60 + insets.bottom },
          tabBarActiveTintColor: Color.accent,
          tabBarInactiveTintColor: Color.styleGuide.Gray5,
          // Adapted from https://github.com/react-navigation/react-navigation/blob/a484c1d7834f195ac0cfccda6a9f905218bc2274/packages/bottom-tabs/src/views/BottomTabItem.tsx#L156-L164
          // customized accessibilityRole
          tabBarButton: ({ children, style, onPress, to, accessibilityRole, ...rest }) => (
            <TouchableWithoutFeedback {...rest} accessibilityRole="tab" onPress={onPress}>
              <View style={style}>{children}</View>
            </TouchableWithoutFeedback>
          ),
          tabBarIcon: ({ color: tintColor }) => {
            const routeName = route.name;

            let icon = null;
            if (routeName === 'Home') {
              icon = <Icon color={tintColor} name="home" />;
            } else if (routeName === 'Aid') {
              icon = <Icon color={tintColor} name="my-plan" />;
            } else if (routeName === 'Learn') {
              icon = <Icon color={tintColor} name="sessions" />;
            } else if (routeName === 'Practice') {
              icon = <Icon color={tintColor} name="practice" />;
            } else if (routeName === 'Profile') {
              icon = <Icon color={tintColor} name="person" />;
            }

            return <View style={{ paddingTop: 4 }}>{icon}</View>;
          },
        };
        return options;
      }}
      initialRouteName="Home"
    >
      <Tab2.Screen
        name="Home"
        component={HomeV2}
        options={{
          tabBarLabel: (p) => <TabLabel {...p} text={$t(tabLabels.home)} />,
          tabBarTestID: 'NAV_Home',
          tabBarAccessibilityLabel: tabAccessibilityLabel({
            tabName: $t(tabLabels.home),
            count: 1,
          }),
        }}
      />
      <Tab2.Screen
        name="Learn"
        component={Learn}
        options={{
          title: $t(tabLabels.learn),
          tabBarLabel: (p) => <TabLabel {...p} text={$t(tabLabels.learn)} />,
          tabBarTestID: 'NAV_Learn',
          tabBarAccessibilityLabel: tabAccessibilityLabel({
            tabName: $t(tabLabels.learn),
            count: 2,
          }),
        }}
      />
      <Tab2.Screen
        name="Practice"
        component={Practice}
        options={{
          title: $t(tabLabels.practice),
          tabBarLabel: (p) => <TabLabel {...p} text={$t(tabLabels.practice)} />,
          tabBarTestID: 'NAV_Practice',
          tabBarAccessibilityLabel: tabAccessibilityLabel({
            tabName: $t(tabLabels.practice),
            count: 3,
          }),
        }}
      />
      <Tab2.Screen
        name="Aid"
        component={CRP}
        options={{
          title: $t(tabLabels.myPlan),
          tabBarLabel: (p) => <TabLabel {...p} text={$t(tabLabels.myPlan)} />,
          tabBarTestID: 'NAV_Aid',
          tabBarAccessibilityLabel: tabAccessibilityLabel({
            tabName: $t(tabLabels.myPlan),
            count: 4,
          }),
        }}
      />
      <Tab2.Screen
        name="Profile"
        component={UserProfile}
        options={{
          title: $t(tabLabels.profile),
          tabBarLabel: (p) => <TabLabel {...p} text={$t(tabLabels.profile)} />,
          tabBarTestID: 'NAV_Profile',
          tabBarAccessibilityLabel: tabAccessibilityLabel({
            tabName: $t(tabLabels.profile),
            count: 5,
          }),
        }}
      />
    </Tab2.Navigator>
  );
};

const Stack = createSessionTimeoutStackNavigator<RootStackParamList>(SESSION_TIMEOUT);
const MainNavigator = () => {
  const [hasSeenLocalAuthenticationPrompt] = usePersistedState(
    'SeenLocalAuthenticationPrompt',
    false,
  );
  const { scheme: theme, Color } = useTheme();
  const apollo = useApolloClient();
  const { data: user, isLoggedIn } = useCurrentUser();
  const defaultHeaderHeight = useDefaultHeaderHeight();
  const { $t } = useI18n();
  const hopeKitName = useHopeKitName();

  let homePath: 'home' | 'homeStatic' = 'home';
  if (user?.currentUser?.user?.__typename === 'Patient') {
    if (
      user?.currentUser.user.productVariant === ProductVariant.AVIVA_STATIC || // deprecated
      user?.currentUser.user.productStatic
    ) {
      homePath = 'homeStatic';
    }
  }
  useEffect(() => {
    if (isLoggedIn) {
      setOrganizationUserProperties(apollo);
    }
  }, [isLoggedIn, apollo]);

  useEffect(() => {
    if (isLoggedIn && !user?.currentUser?.user) {
      Sentry.captureException('App loaded without user');
    }
  }, [isLoggedIn, user?.currentUser?.user]);

  return (
    <Stack.Navigator
      {...Platform.select({ web: { cardStyle: { flex: 1 } } })} // So web screens grow properly
      screenOptions={({ navigation }) => {
        const options: StackNavigationOptions = {
          headerStyle: {
            height: defaultHeaderHeight,
          },
          headerBackTestID: 'header-back',
          headerTruncatedBackTitle: $t({ id: 'App_backButtonLabel', defaultMessage: 'Back' }),
          headerTintColor: Color.accent,
          headerTitleStyle: { color: Color.heading, fontFamily: 'OpenSansSemiBold' },
          headerRight: () => (
            <GoToAidIcon
              onPress={() => navigation.navigate(homePath === 'homeStatic' ? 'Aid' : 'MyPlan')}
            />
          ),
          headerLeft:
            navigation.canGoBack() || !isLoggedIn
              ? undefined
              : ({ tintColor }) => (
                  <HeaderBackButton
                    onPress={() => {
                      navigation.replace(homePath);
                    }}
                    tintColor={tintColor}
                  />
                ),
          headerMode: DEFAULT_HEADER_MODE,
        };
        return options;
      }}
    >
      {isLoggedIn ? (
        shouldShowWebBlocker() ? (
          <Stack.Screen name="WebBlocker" component={WebBlocker} options={{ headerShown: false }} />
        ) : (
          <>
            {hasSeenLocalAuthenticationPrompt ? null : (
              <Stack.Screen
                name="LocalAuthenticationPrompt"
                component={LocalAuthenticationPrompt}
                options={{ headerShown: false }}
              />
            )}
            {homePath === 'home' ? (
              <Stack.Screen
                name="home"
                component={Tab2Navigator}
                options={{ headerShown: false, title: '' }}
              />
            ) : (
              <Stack.Screen name="homeStatic" options={{ headerShown: false, title: '' }}>
                {() => <StaticTabNavigator AccountSettings={AccountSettings as ComponentType} />}
              </Stack.Screen>
            )}
            <Stack.Screen
              name="StaticSession"
              component={StaticSession}
              options={({ route }) => ({
                headerTitle: route.params.title,
                headerBackTitleVisible: false,
              })}
            />
            <Stack.Screen
              name="StaticReview"
              component={StaticReview}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                headerTintColor: Color.text,
                tintColor: Color.accent,
                backgroundColor: 'white',
              })}
            />
            <Stack.Screen
              // initialParams={{ ID: 'TEST::convo' }}
              name="Conversation"
              component={Conversation}
              options={({ route }) => ({
                headerBackTitleVisible: false,
                title:
                  route.params?.title ??
                  route.params?.ID ??
                  $t({ id: 'Conversation_title', defaultMessage: 'Conversation' }),
              })}
            />

            <Stack.Screen
              name="MyPlan"
              component={MyPlanRounded}
              options={({ navigation }) =>
                MyPlanRoundedOptions({ defaultHeaderHeight, navigation })
              }
            />
            <Stack.Screen
              name="EditMyPlan"
              component={EditMyPlan}
              options={{ title: $t({ id: 'EditMyPlan_title', defaultMessage: 'Edit MyPlan' }) }}
            />
            <Stack.Screen
              name="MyPlanReview"
              component={MyPlanReview}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                headerTintColor: Color.text,
                tintColor: Color.accent,
                backgroundColor: 'white',
              })}
            />

            <Stack.Screen
              name="ActivityPractice"
              component={ActivityPractice}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: 'white',
                backgroundColor: '#1b9fa0',
              })}
            />
            <Stack.Screen
              name="EditActivityPractice"
              component={EditActivityPractice}
              options={{
                title: $t({ id: 'EditActivityPractice_title', defaultMessage: 'Rate activity' }),
              }}
            />

            <Stack.Screen
              name="CopingCards"
              component={CopingCards}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: Color.accent,
                backgroundColor: 'white',
              })}
            />
            <Stack.Screen
              name="EditCopingCards"
              component={EditCopingCards}
              options={(props) => {
                return props.route.params?.copingCardIndex
                  ? {
                      title: $t({
                        id: 'EditCopingCards_editCardTitle',
                        defaultMessage: 'Edit card',
                      }),
                      headerRight: () => null,
                    }
                  : {
                      title: $t({
                        id: 'EditCopingCards_title',
                        defaultMessage: 'Add to cards',
                      }),
                      headerRight: () => null,
                    };
              }}
            />
            <Stack.Screen
              name="EditLessonsLearned"
              component={EditLessonsLearned}
              options={{
                title: $t({
                  id: 'EditLessonsLearned_title',
                  defaultMessage: 'Edit Lessons learned',
                }),
              }}
            />
            <Stack.Screen
              name="PlacesPicker"
              component={PlacesPicker}
              options={() => ({
                title: $t({ id: 'PlacesPicker_title', defaultMessage: 'Places' }),
                headerLeft: () => <HeaderBackButton onPress={() => {}} />,
                headerRight: undefined,
              })}
            />
            <Stack.Screen
              name="ContactsPicker"
              component={ContactsPicker}
              options={() => ({
                title: $t({ id: 'ContactsPicker_title', defaultMessage: 'Contacts' }),
                headerLeft: () => <HeaderBackButton onPress={() => {}} />,
                headerRight: undefined,
              })}
            />
            <Stack.Screen name="EditActivityEvent" component={EditActivityEvent} />
            <Stack.Screen
              name="MediaPicker"
              component={MediaPicker}
              options={{ headerShown: false }}
            />
            <Stack.Screen name="AccountSettings" component={AccountSettings} />
            <Stack.Screen
              name="TermsAndPrivacy"
              component={TermsAndPrivacy}
              options={{
                // https://github.com/react-native-community/react-native-webview/issues/575#issuecomment-587267906
                animationEnabled: false,
                title: $t({ id: 'TermsAndPrivacy_title', defaultMessage: 'Terms and Privacy' }),
              }}
            />
            <Stack.Screen
              name="MyStoryMyPlan"
              component={PatientMyStoryMyPlan}
              options={{
                title: $t({ id: 'MyStoryMyPlan_title', defaultMessage: 'MyStory & MyPlan' }),
              }}
            />
            <Stack.Screen
              name="ControlledMyStoryMyPlan"
              component={PatientControlledMyStoryMyPlan}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="FinishPatientRegistration"
              component={FinishPatientRegistration}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="RiskCurve"
              component={SoloRiskCurve}
              initialParams={{ step: 'review' }}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: 'white',
                backgroundColor: Color.styleGuide.LogoLilac,
              })}
            />
            <Stack.Screen
              name="SoloRiskCurve"
              component={SoloRiskCurve}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: 'white',
                backgroundColor: Color.styleGuide.LogoLilac,
              })}
            />
            <Stack.Screen
              name="SuicideMode"
              component={SoloSuicideMode}
              initialParams={{ step: 'review' }}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: 'white',
                backgroundColor: Color.styleGuide.LogoLilac,
              })}
            />
            <Stack.Screen
              name="EditSuicideMode"
              component={EditSuicideMode}
              options={{
                title: $t({ id: 'EditSuicideMode_title', defaultMessage: 'Edit Suicide Mode' }),
              }}
            />
            <Stack.Screen
              name="SoloSuicideMode"
              component={SoloSuicideMode}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: 'white',
                backgroundColor: Color.styleGuide.LogoLilac,
              })}
            />
            <Stack.Screen
              name="SoloMyPlan"
              component={SoloMyPlan}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: 'white',
                backgroundColor: Color.styleGuide.LogoLilac,
              })}
            />
            <Stack.Screen
              name="SleepDiary"
              component={SleepDiary}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: 'white',
                backgroundColor: Color.tertiary,
              })}
            />
            <Stack.Screen
              name="SetupSleepDiary"
              component={SetupSleepDiary}
              options={{
                title: $t({ id: 'SetupSleepDiary_title', defaultMessage: 'Setup sleep diary' }),
              }}
            />
            <Stack.Screen
              name="ThoughtDiary"
              component={ThoughtDiary}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: Color.text,
                backgroundColor: '#c1e3c9',
              })}
            />
            <Stack.Screen
              name="ThoughtDiaryEntry"
              component={ThoughtDiaryEntry}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: Color.text,
                backgroundColor: '#c1e3c9',
              })}
            />
            <Stack.Screen
              name="EditThoughtDiaryEntry"
              component={EditThoughtDiaryEntry}
              options={{
                title: $t({ id: 'EditThoughtDiaryEntry_title', defaultMessage: 'Edit thought' }),
              }}
            />
            <Stack.Screen
              name="ActivityDiary"
              component={ActivityDiary}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: 'white',
                backgroundColor: Color.styleGuide.LogoCyan,
              })}
            />
            <Stack.Screen
              name="EditSleepDiaryEntry"
              component={EditSleepDiaryEntry}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: 'white',
                backgroundColor: Color.tertiary,
              })}
            />
            <Stack.Screen
              name="SleepDiaryEntry"
              component={SleepDiaryEntry}
              options={({ navigation, route }) => ({
                ...RoundedSectionNavigationOptions({
                  defaultHeaderHeight,
                  tintColor: 'white',
                  backgroundColor: Color.tertiary,
                }),
                headerRight: ({ tintColor }) => (
                  <HeaderButtons>
                    <HeaderItem
                      title=""
                      accessibilityLabel="Edit"
                      iconName="edit"
                      color={tintColor}
                      onPress={() =>
                        navigation.navigate('EditSleepDiaryEntry', {
                          date: route.params.date,
                          step: 'morning',
                        })
                      }
                    />
                  </HeaderButtons>
                ),
              })}
            />
            <Stack.Screen
              name="AddHopeKit"
              component={AddHopeKit}
              options={{
                title: $t(
                  { id: 'AddHopeKit_title', defaultMessage: 'Add to {hopeKitName}' },
                  { hopeKitName },
                ),
              }}
            />
            <Stack.Screen
              name="HopeKit"
              component={HopeKit}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: Color.accent,
                backgroundColor: 'white',
              })}
            />
            <Stack.Screen
              name="Relaxation"
              component={Relaxation}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: Color.text,
                backgroundColor: '#ebf1ed',
              })}
            />
            <Stack.Screen
              name="QuizSet"
              component={QuizSet}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: 'white',
                backgroundColor: 'black',
              })}
              initialParams={{}}
            />
            <Stack.Screen
              name="Confidentiality"
              component={Confidentiality}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen name="TestWidget" component={TestWidget} />
            <Stack.Screen name="TestIntl" component={TestIntl} />
            <Stack.Screen
              name="TestArtifactResult"
              component={TestArtifactResult}
              // initialParams={{ artifactName: 'SoloMyPlan' }}
            />
          </>
        )
      ) : (
        <>
          {Auth({ $t, Screen: Stack.Screen, theme, Color })}
          <Stack.Screen name="Welcome" component={Welcome} options={{ headerShown: false }} />
          <Stack.Screen
            name="ClinicianControlledMyStoryMyPlanPreview"
            component={ClinicianControlledMyStoryMyPlanPreview}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="CMSConversationPreview"
            component={CMSConversationPreview}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="CreateTestUser"
            component={CreateTestUser}
            options={{ headerShown: false }}
          />
        </>
      )}
    </Stack.Navigator>
  );
};

const homeConfig: DeeplinkConfigShape<keyof Tab2ParamList> = {
  Home: 'home',
  Learn: 'learn',
  Practice: 'practice',
  Aid: 'aid',
  Profile: 'profile',
};
const homeStaticConfig: DeeplinkConfigShape<keyof StaticTabParamList> = {
  Learn: 'static/learn',
  Practice: 'static/practice',
  Aid: 'static/aid',
  Profile: 'static/account',
};
const mystorymyplanConfig: DeeplinkConfigShape<keyof MyStoryMyPlanStackParamList> = {
  MyStoryMyPlanOverview: 'MyStoryMyPlanOverview',
  MyStoryIntroduction: 'MyStoryIntroduction',
  MyStoryTimelineFinal: 'MyStoryTimelineFinal',
  MyStoryRiskCurveIntroduction: 'MyStoryRiskCurveIntroduction',
  MyStoryRiskCurveReview: 'MyStoryRiskCurveReview',
  MyStoryMyPlanIntroduction: 'MyStoryMyPlanIntroduction',
  MyStoryMyPlanReview: 'MyStoryMyPlanReview',
  MyStoryMyPlanComplete: 'MyStoryMyPlanComplete',
};

const DEEPLINK_CONFIG: DeeplinkConfigShape<Exclude<keyof RootStackParamList, keyof AuthParamList>> =
  {
    CreateTestUser: 'auth/create-test-user',
    home: { screens: homeConfig },
    homeStatic: { screens: homeStaticConfig },
    StaticSession: 'static/session',
    StaticReview: 'static/review',
    Conversation: 'Conversation',
    MyPlan: 'MyPlan',
    MyPlanReview: 'MyPlanReview',
    EditMyPlan: 'EditMyPlan',
    CopingCards: 'CopingCards',
    EditCopingCards: 'EditCopingCards',
    ContactsPicker: 'ContactsPicker',
    PlacesPicker: 'PlacesPicker',
    MediaPicker: 'MediaPicker',
    AccountSettings: 'AccountSettings',
    TermsAndPrivacy: 'TermsAndPrivacy',
    Welcome: 'Welcome',
    WebBlocker: 'WebBlocker',
    LocalAuthenticationPrompt: 'LocalAuthenticationPrompt',
    FinishPatientRegistration: 'FinishPatientRegistration',
    Confidentiality: 'Confidentiality',
    MyStoryMyPlan: { screens: mystorymyplanConfig },
    SuicideMode: 'SuicideMode',
    EditSuicideMode: 'EditSuicideMode',
    SoloSuicideMode: 'SoloSuicideMode',
    SoloRiskCurve: 'SoloRiskCurve',
    SoloMyPlan: 'SoloMyPlan',
    RiskCurve: 'RiskCurve',
    ControlledMyStoryMyPlan: 'ControlledMyStoryMyPlan',
    SleepDiary: 'SleepDiary',
    SetupSleepDiary: 'SetupSleepDiary',
    EditSleepDiaryEntry: 'EditSleepDiaryEntry',
    SleepDiaryEntry: 'SleepDiaryEntry',
    AddHopeKit: 'AddHopeKit',
    HopeKit: 'HopeKit',
    ActivityDiary: 'ActivityDiary',
    ActivityPractice: 'ActivityPractice',
    EditActivityPractice: 'EditActivityPractice',
    EditActivityEvent: 'EditActivityEvent',
    ThoughtDiary: 'ThoughtDiary',
    ThoughtDiaryEntry: 'ThoughtDiaryEntry',
    EditThoughtDiaryEntry: 'EditThoughtDiaryEntry',
    Relaxation: 'Relaxation',
    QuizSet: 'QuizSet',
    TestWidget: 'TestWidget',
    TestIntl: 'TestIntl',
    TestArtifactResult: 'TestArtifactResult',
    EditLessonsLearned: 'EditLessonsLearned',
    // iframeable screens used by Oui Platform features
    ClinicianControlledMyStoryMyPlanPreview: 'ClinicianControlledMyStoryMyPlanPreview',
    CMSConversationPreview: 'CMSConversationPreview',
  };

const getMessages: ComponentProps<typeof AppContainer>['getMessages'] = ({ lang }) => {
  switch (lang) {
    case 'en': {
      return require('@src/messages/compiled/en.json');
    }
    default:
      return {};
  }
};

type Props = {};
export default forwardRef<AppContainer, Props>(function App({}: Props, ref) {
  const [apollo, setApollo] = useState<ApolloClient<unknown>>();

  useEffect(() => {
    // This will only run in development when fast-refresh kicks in
    let cleanupRef = { current: () => {} };

    async function initApollo() {
      const cache = createApolloCache();

      // TODO move to mountApp?
      await initMmkv();

      try {
        const options =
          Platform.OS === 'web'
            ? IS_PRODUCTION
              ? undefined
              : {
                  cache,
                  trigger: 'write' as const,
                  storage: new AsyncStorageWrapper(AsyncStorage),
                }
            : {
                cache,
                trigger: 'background' as const,
                storage: new MMKVWrapper(getMmkv('apollo')),
              };

        if (options) {
          const persistor = new CachePersistor(options);
          await persistor.restore();
          cleanupRef.current = () => persistor.remove();
        }
      } catch (e) {
        Sentry.captureException(e);
      }

      await initLastConfig();
      const client = createApolloClient(getConfigString('apiUrl'), {
        subscriptionUri: getConfigString('subscriptionUri'),
        cache,
        connectToDevTools: true,
      });
      setApollo(client);
      const start = Date.now();
      const removedKeys = cache.gc();
      const duration = Date.now() - start;
      Sentry.addBreadcrumb({
        category: 'apollo',
        message: 'cache.gc',
        data: { removedKeys, duration },
      });
    }
    initApollo();

    return () => cleanupRef.current();
  }, []);

  if (Platform.OS === 'web' && IS_PRODUCTION) {
    return <WebBlocker />;
  }

  return apollo ? (
    <AppContainer
      Logo={FullLogo}
      flags={FLAGS}
      app={() => <MainNavigator />}
      apollo={apollo}
      ref={ref}
      initialPath={({ ouiUser }) => {
        if (!ouiUser) return 'Welcome';
        if (ouiUser.user?.__typename === 'Patient') {
          if (
            ouiUser.user.productVariant === ProductVariant.AVIVA_STATIC || // deprecated
            ouiUser.user.productStatic
          ) {
            return 'static/learn';
          }
        }
        return 'home';
      }}
      deeplinkConfig={{ screens: DEEPLINK_CONFIG }}
      getMessages={getMessages}
    />
  ) : null;
});
