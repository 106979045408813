import { useApolloClient } from '@apollo/client';
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import endOfWeek from 'date-fns/endOfWeek';
import parseISO from 'date-fns/parseISO';
import startOfWeek from 'date-fns/startOfWeek';
import { LinearGradient } from 'expo-linear-gradient';
import * as Localization from 'expo-localization';
import * as Notifications from 'expo-notifications';
import * as ScreenOrientation from 'expo-screen-orientation';
import hexToRgba from 'hex-to-rgba';
import noop from 'lodash/noop';
import { ComponentProps, memo, useCallback, useEffect, useState } from 'react';
import {
  Platform,
  RefreshControl,
  SafeAreaView,
  StatusBar,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';

import { SegmentedProgressBar } from '@oui/app-core/src/components/SegmentedProgressBar';
import { SessionCard, SessionCardBlank } from '@oui/app-core/src/components/SessionCard';
import { useFeed } from '@oui/app-core/src/hooks/useFeed';
import { setDeviceInfo } from '@oui/app-core/src/lib/setDeviceInfo';
import { getGQLDate } from '@oui/lib/src/getGQLDate';
import { ProductVariant } from '@oui/lib/src/types/graphql.generated';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { AnimatedProgressCircle } from '@src/components/AnimatedProgressCircle';
import { Button } from '@src/components/Button';
import { Divider } from '@src/components/Divider';
import { Icon } from '@src/components/Icon';
import { NewPatientSupporterNotification } from '@src/components/NewPatientSupporterNotification';
import { RequiredClinicianUpgrade } from '@src/components/RequiredClinicianUpgrade';
import { ScrollView } from '@src/components/ScrollView';
import { SuicideLifelineCard } from '@src/components/SuicideLifelineCard';
import { TabHeader } from '@src/components/TabHeader';
import { Heading, Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { Environment, environment } from '@src/constants';
import { useCurrentPatient, useProgressByContent } from '@src/hooks/useCurrentUser';
import { useHopeKitName } from '@src/hooks/useHopeKitName';
import { useI18n } from '@src/lib/i18n';
import { logEvent } from '@src/lib/log';
import {
  HomeFeedV2Query,
  useHomeFeedV2Query,
  useSetLocaleAndTimezoneMutation,
} from '@src/screens/HomeV2.graphql.generated';
import Sentry from '@src/sentry';
import { Color, card } from '@src/styles';
import { ContentType, TabScreenProps } from '@src/types';

type Props = TabScreenProps<'Home'>;

const AllDoneRibbon = memo(() => {
  const { $t } = useI18n();
  return (
    <LinearGradient
      colors={['rgba(236, 191, 45, 0)', 'rgba(236, 191, 45, 0.3)']}
      style={[{ marginRight: 30, height: 40, justifyContent: 'center', alignItems: 'center' }]}
      start={[0, 0]}
      end={[1, 0]}
    >
      <Text
        text={$t({ id: 'AllDoneRibbon', defaultMessage: 'All done for today!' })}
        testID="AllDoneRibbon"
        weight="semibold"
        color={Color.styleGuide.Gray3}
      />
      <View
        style={{
          backgroundColor: 'white',
          position: 'absolute',
          transform: [{ rotateZ: '45deg' }],
          width: 40,
          height: 40,
          right: -30,
        }}
      />
    </LinearGradient>
  );
});

const FinishSession1Ribbon = memo(() => {
  const { $t } = useI18n();
  return (
    <LinearGradient
      colors={['rgba(239, 239, 244, 0.5)', 'rgba(29, 103, 208, 0.10)', 'rgba(239, 239, 244, 0.5)']}
      style={[{ height: 40, justifyContent: 'center', alignItems: 'center' }]}
      locations={[0, 0.5, 1]}
      start={[0, 0]}
      end={[1, 0]}
    >
      <Text
        text={$t({
          id: 'FinishSession1Ribbon',
          defaultMessage: 'Finish session 1 to start practicing',
        })}
        testID="FinishSession1Ribbon"
        weight="semibold"
        color={Color.styleGuide.Gray3}
      />
    </LinearGradient>
  );
});

function ActionTodoItem(props: {
  item:
    | HomeFeedV2Query['actionTodos']['todo'][number]
    | HomeFeedV2Query['actionTodos']['completed'][number];
}) {
  const { navigate } = useNavigation<TabScreenProps<'Home'>['navigation']>();
  const complete = !props.item.__typename.startsWith('Pending');
  const item = props.item;
  const { $t, formatDate } = useI18n();
  const hopeKitName = useHopeKitName();

  switch (item.__typename) {
    case 'PendingMyPlanReviewAction':
    case 'MyPlanReviewAction':
      return (
        <PracticeItem
          complete={complete}
          icon="my-plan"
          text={$t({ id: 'ActionTodoItem_myPlanReview', defaultMessage: 'Review using MyPlan' })}
          color={Color.styleGuide.LogoLilac}
          onPress={() => navigate('MyPlanReview', {})}
        />
      );
    case 'PendingSleepDiaryEntryNightAction':
    case 'SleepDiaryEntryNightAction':
      return (
        <PracticeItem
          complete={complete}
          icon="moon"
          text={$t({
            id: 'ActionTodoItem_sleepDiaryNight',
            defaultMessage: 'Night sleep check-in',
          })}
          color="#2c6ec9"
          onPress={
            item.__typename === 'PendingSleepDiaryEntryNightAction'
              ? () => {
                  navigate('SleepDiary', {});
                  navigate('EditSleepDiaryEntry', { step: 'night', date: item.date });
                }
              : noop
          }
        />
      );
    case 'PendingSleepDiaryEntryMorningAction':
    case 'SleepDiaryEntryMorningAction':
      return (
        <PracticeItem
          complete={complete}
          icon="sun"
          text={$t({
            id: 'ActionTodoItem_sleepDiaryMorning',
            defaultMessage: 'Morning sleep check-in',
          })}
          color="#e8b613"
          onPress={
            item.__typename === 'PendingSleepDiaryEntryMorningAction'
              ? () => {
                  navigate('SleepDiary', {});
                  navigate('EditSleepDiaryEntry', { step: 'morning', date: item.date });
                }
              : noop
          }
        />
      );
    case 'PendingRelaxAction':
    case 'RelaxAction':
      return (
        <PracticeItem
          complete={complete}
          icon="relax"
          text={$t({ id: 'ActionTodoItem_relax', defaultMessage: 'Try a relaxation exercise' })}
          color="#104f51"
          onPress={() => navigate('Relaxation', {})}
        />
      );
    case 'PendingThoughtDiaryEntrySwitchAction':
    case 'ThoughtDiaryEntrySwitchAction':
      return (
        <PracticeItem
          complete={complete}
          icon="spot-it"
          text={$t(
            {
              id: 'ActionTodoItem_thoughtDiarySwitch',
              defaultMessage: 'Switch your thought from {date}',
            },
            {
              date: formatDate(parseISO(item.thoughtDiaryEntryPractice.practiceValues.date), {
                month: 'short',
                day: 'numeric',
                weekday: 'short',
              }),
            },
          )}
          color="#104f51"
          onPress={
            item.__typename === 'PendingThoughtDiaryEntrySwitchAction'
              ? () => {
                  navigate('ThoughtDiary', {});
                  navigate('ThoughtDiaryEntry', {
                    practiceID: item.thoughtDiaryEntryPractice.practiceID,
                  });
                }
              : noop
          }
        />
      );
    case 'PendingThoughtDiaryEntrySpotAction':
    case 'ThoughtDiaryEntrySpotAction':
      return (
        <PracticeItem
          complete={complete}
          icon="spot-it"
          text={$t({
            id: 'ActionTodoItem_thoughtDiarySpot',
            defaultMessage: 'Spot a negative thought',
          })}
          color="#104f51"
          onPress={() => {
            navigate('ThoughtDiary', {});
            navigate('EditThoughtDiaryEntry', { step: 'spot', cardStack: true });
          }}
        />
      );
    case 'PendingThoughtDiaryEntryTestAction':
    case 'ThoughtDiaryEntryTestAction':
      return (
        <PracticeItem
          complete={complete}
          icon="spot-it"
          text={$t(
            {
              id: 'ActionTodoItem_thoughtDiaryTest',
              defaultMessage: 'Test your thought from {date}',
            },
            {
              date: formatDate(parseISO(item.thoughtDiaryEntryPractice.practiceValues.date), {
                month: 'short',
                day: 'numeric',
                weekday: 'short',
              }),
            },
          )}
          color="#104f51"
          onPress={
            item.__typename === 'PendingThoughtDiaryEntryTestAction'
              ? () => {
                  navigate('ThoughtDiary', {});
                  navigate('ThoughtDiaryEntry', {
                    practiceID: item.thoughtDiaryEntryPractice.practiceID,
                  });
                }
              : noop
          }
        />
      );
    case 'PendingHopeKitAddAction':
    case 'HopeKitAddAction':
      return (
        <PracticeItem
          complete={complete}
          icon="kit"
          text={$t(
            {
              id: 'ActionTodoItem_hopeKitAdd',
              defaultMessage: 'Add to your {hopeKitName}',
            },
            { hopeKitName },
          )}
          color="#754178"
          onPress={() => {
            navigate('HopeKit', {});
            navigate('AddHopeKit', {});
          }}
        />
      );
    case 'PendingHopeKitReviewAction':
    case 'HopeKitReviewAction':
      return (
        <PracticeItem
          complete={complete}
          icon="kit"
          text={$t(
            {
              id: 'ActionTodoItem_hopeKitReview',
              defaultMessage: 'Review your {hopeKitName}',
            },
            { hopeKitName },
          )}
          color="#754178"
          onPress={() => navigate('HopeKit', {})}
        />
      );
    case 'PendingCopingCardAddAction':
    case 'CopingCardAddAction':
      return (
        <PracticeItem
          complete={complete}
          icon="cards"
          text={$t({
            id: 'ActionTodoItem_copingCardAdd',
            defaultMessage: 'Add to your Coping cards',
          })}
          color="#2461c3"
          onPress={() => {
            navigate('CopingCards', {});
            navigate('EditCopingCards', {});
          }}
        />
      );
    case 'PendingCopingCardReviewAction':
    case 'CopingCardReviewAction':
      return (
        <PracticeItem
          complete={complete}
          icon="cards"
          text={$t({
            id: 'ActionTodoItem_copingCardReview',
            defaultMessage: 'Review your Coping cards',
          })}
          color="#2461c3"
          onPress={() => navigate('CopingCards', {})}
        />
      );
    case 'PendingActivityRateAction':
    case 'ActivityRateAction':
      return (
        <PracticeItem
          complete={complete}
          icon="calendar"
          text={$t(
            {
              id: 'ActionTodoItem_activityRate',
              defaultMessage: 'Rate {title}',
            },
            { title: item.activityPractice.activity.title },
          )}
          color="#1b9fa0"
          onPress={
            item.__typename === 'PendingActivityRateAction'
              ? () => {
                  navigate('ActivityDiary', {});
                  navigate('ActivityPractice', {
                    practiceID: item.activityPractice.practiceID,
                  });
                  navigate('EditActivityPractice', {
                    practiceID: item.activityPractice.practiceID,
                  });
                }
              : noop
          }
        />
      );
    case 'PendingActivityAddAction':
    case 'ActivityAddAction':
      return (
        <PracticeItem
          complete={complete}
          icon="calendar"
          text={$t({
            id: 'ActionTodoItem_activityAdd',
            defaultMessage: 'Add an activity',
          })}
          color="#1b9fa0"
          onPress={() => {
            navigate('ActivityDiary', {});
            navigate('EditActivityEvent', {});
          }}
        />
      );
    case 'StaticAction':
      return null;
  }
}

function PracticeItem(props: {
  icon: ComponentProps<typeof Icon>['name'];
  color: string;
  text: string;
  onPress: () => void;
  complete?: boolean;
}) {
  const color = props.complete ? '#008689' : props.color;
  return (
    <TouchableOpacity
      accessibilityLabel={`${props.text}. ${props.complete ? 'completed' : ''}`}
      accessibilityRole="button"
      onPress={props.onPress}
      disabled={props.complete}
    >
      <View row spacing={12}>
        <View
          style={{
            width: 35,
            height: 35,
            backgroundColor: hexToRgba(color ?? '#FF0000', 0.1),
            borderRadius: 10,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon name={props.complete ? 'check' : props.icon} size={18} color={color} />
        </View>
        <Text
          text={props.text}
          style={[
            { flex: 1, marginRight: 12 },
            props.complete ? { opacity: 0.7, textDecorationLine: 'line-through' } : null,
          ]}
          color={props.complete ? '#035759' : undefined}
          size={17}
          weight="semibold"
        />
        {props.complete ? null : (
          <Icon name="caret-right" color={props.complete ? Color.styleGuide.Gray5 : Color.accent} />
        )}
      </View>
    </TouchableOpacity>
  );
}

function WeeklyProgress({ progress }: { progress?: HomeFeedV2Query['actionProgresses'] }) {
  const progressValues = progress?.length
    ? progress.map((p) => (p.total === 0 ? 0 : p.completed / p.total))
    : [0, 0, 0, 0, 0, 0, 0];
  const { formatDate } = useI18n();

  // TODO startOfWeek should be based on locale
  const dates = [
    parseISO('2022-03-20'),
    parseISO('2022-03-21'),
    parseISO('2022-03-22'),
    parseISO('2022-03-23'),
    parseISO('2022-03-24'),
    parseISO('2022-03-25'),
    parseISO('2022-03-26'),
  ];

  return (
    <View row style={{ justifyContent: 'space-between' }} testID="WeeklyProgress">
      {dates.map((date, i) => {
        const dateName = formatDate(date, { weekday: 'long' });
        return (
          <AnimatedProgressCircle
            key={dateName}
            testID={`WeeklyProgress_${i}`}
            text={dateName[0]}
            progress={progressValues[i]}
            accessibilityLabel={dateName}
          />
        );
      })}
    </View>
  );
}

function QuickActions() {
  const { fontScale } = useWindowDimensions();
  const { navigate } = useNavigation<TabScreenProps<'Home'>['navigation']>();
  const { data: progress } = useProgressByContent();
  const [today] = useState(getGQLDate);
  const { $t } = useI18n();
  const { user } = useCurrentPatient();

  const isActivityPlanningLocked = !progress.ACTIVITY_PLANNING?.completed;
  const isSleepDiaryLocked = !progress.SLEEP?.completed;
  const isSpotItLocked = !progress.SPOT_IT?.completed;

  if (user?.productVariant === ProductVariant.AVIVA_INPATIENT) {
    return null;
  }

  return (
    <>
      <Heading
        text={$t({ id: 'Home_quickActionsHeading', defaultMessage: 'Add to diaries' })}
        testID="Home_quickActionsHeading"
        style={{ marginTop: 68, marginBottom: 12 }}
        level={2}
      />
      <View
        style={[card, { paddingHorizontal: 15, paddingVertical: 20 }]}
        testID="Home_quickActions"
      >
        <View row={fontScale <= 1} style={{ paddingHorizontal: 10 }} childFlex={1}>
          <View>
            <Button
              variant="text"
              text={$t({
                id: 'Home_quickActionActivityEntryButton',
                defaultMessage: 'Add activity',
              })}
              icon={isActivityPlanningLocked ? 'lock' : 'plus'}
              disabled={isActivityPlanningLocked}
              color={isActivityPlanningLocked ? Color.text : undefined}
              onPress={() => {
                navigate('ActivityDiary', {});
                navigate('EditActivityEvent', {});
              }}
              style={{
                justifyContent: 'flex-start',
              }}
              testID={
                isActivityPlanningLocked
                  ? 'Home_quickActionActivityEntryLocked'
                  : 'Home_quickActionActivityEntry'
              }
            />
          </View>
          <View>
            <Button
              variant="text"
              text={$t({
                id: 'Home_quickActionMorningSleepEntryButton',
                defaultMessage: 'Morning',
              })}
              onPress={() => {
                navigate('SleepDiary', {});
                navigate('EditSleepDiaryEntry', { step: 'morning', date: today });
              }}
              icon={isSleepDiaryLocked ? 'lock' : 'sun'}
              disabled={isSleepDiaryLocked}
              color={isSleepDiaryLocked ? Color.text : undefined}
              style={{}}
              testID={
                isSleepDiaryLocked
                  ? 'Home_quickActionMorningSleepEntryLocked'
                  : 'Home_quickActionMorningSleepEntry'
              }
            />
          </View>
        </View>
        <View row={fontScale <= 1} style={{ paddingHorizontal: 10 }} childFlex={1}>
          <View>
            <Button
              variant="text"
              text={$t({
                id: 'Home_quickActionThoughtEntryButton',
                defaultMessage: 'Add thought',
              })}
              icon={isSpotItLocked ? 'lock' : 'plus'}
              disabled={isSpotItLocked}
              color={isSpotItLocked ? Color.text : undefined}
              onPress={() => {
                navigate('ThoughtDiary', {});
                navigate('EditThoughtDiaryEntry', { step: 'spot', cardStack: true });
              }}
              style={{
                justifyContent: 'flex-start',
                flexBasis: '40%',
              }}
              testID={
                isSpotItLocked
                  ? 'Home_quickActionThoughtEntryLocked'
                  : 'Home_quickActionThoughtEntry'
              }
            />
          </View>
          <View>
            <Button
              variant="text"
              text={$t({
                id: 'Home_quickActionNightSleepEntryButton',
                defaultMessage: 'Night',
              })}
              icon={isSleepDiaryLocked ? 'lock' : 'moon'}
              disabled={isSleepDiaryLocked}
              color={isSleepDiaryLocked ? Color.text : undefined}
              onPress={() => {
                navigate('SleepDiary', {});
                navigate('EditSleepDiaryEntry', { step: 'night', date: today });
              }}
              style={{
                justifyContent: 'flex-start',
                flexBasis: '40%',
              }}
              testID={
                isSleepDiaryLocked
                  ? 'Home_quickActionNightSleepEntryLocked'
                  : 'Home_quickActionNightSleepEntry'
              }
            />
          </View>
        </View>
      </View>
    </>
  );
}

export function HomeV2(props: Props) {
  const progressAfter = getGQLDate(startOfWeek(new Date()));
  const progressBefore = getGQLDate(endOfWeek(new Date()));
  const {
    data: progress,
    loading: progressLoading,
    refetch: refetchProgress,
  } = useProgressByContent();
  const apollo = useApolloClient();
  const { user } = useCurrentPatient();
  const { $t } = useI18n();

  const { data, refetch, loading } = useHomeFeedV2Query({
    variables: {
      progressAfter,
      progressBefore,
    },
  });

  const [setLocaleAndTimezone] = useSetLocaleAndTimezoneMutation();
  useEffect(() => {
    setLocaleAndTimezone({
      variables: { locale: Localization.locale, timezone: Localization.timezone },
    });
  }, [setLocaleAndTimezone]);

  useEffect(() => {
    async function checkPermission() {
      const currentPermissionStatus = await Notifications.getPermissionsAsync();
      if (currentPermissionStatus.granted) return;
      if (currentPermissionStatus.canAskAgain) {
        logEvent('push_permissions_request');
        const newPermissionStatus = await Notifications.requestPermissionsAsync();
        if (newPermissionStatus.granted) {
          logEvent('push_permissions_granted');
          setDeviceInfo(apollo);
        } else {
          logEvent('push_permissions_denied');
        }
      } else {
        logEvent('push_permissions_not_enabled');
        Sentry.captureMessage('Push permissions not granted and cannot ask again', {
          extra: { currentPermissionStatus },
        });
      }
    }
    if (progress.MYPLAN?.completed && Platform.OS !== 'web') {
      checkPermission();
    }
  }, [progress.MYPLAN?.completed, apollo]);

  const isFocused = useIsFocused();

  useFocusEffect(
    useCallback(() => {
      try {
        refetch();
        refetchProgress();
      } catch (e) {
        // HMR error in dev
        if (environment !== Environment.DEVELOPMENT) {
          Sentry.captureException(e);
        }
      }
    }, [refetch, refetchProgress]),
  );

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
      }
      return () => {
        if (Platform.OS !== 'web') {
          ScreenOrientation.unlockAsync();
        }
      };
    }, []),
  );

  const myStoryMyPlanCompleteLoaded = !progressLoading;

  const { activeItem: item, isCollaborativeOnboarding } = useFeed(data?.treatmentV2);

  if (!myStoryMyPlanCompleteLoaded) {
    return (
      <SafeAreaView>
        <StatusBar backgroundColor={Color.styleGuide.LogoCyan} barStyle="light-content" />
        <View style={{ padding: 50 }}>
          <ActivityIndicator size="large" />
        </View>
      </SafeAreaView>
    );
  }

  let allComplete: boolean = false;
  if (user?.productVariant === ProductVariant.AVIVA_INPATIENT) {
    allComplete = !!progress.COPING_CARDS?.completed;
  } else {
    allComplete = !!progress.POST_AVIVA?.completed;
  }

  return (
    <RequiredClinicianUpgrade>
      <View style={{ flex: 1 }}>
        {isFocused ? <StatusBar backgroundColor="white" barStyle="dark-content" /> : null}
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={!!(data?.treatmentV2 && (loading || progressLoading))}
              onRefresh={() => {
                refetch();
                refetchProgress();
              }}
              title={$t({ id: 'Home_refreshIndicator', defaultMessage: 'Update' })}
              progressViewOffset={150}
            />
          }
          testID="Home_scrollView"
          style={{ flex: 1 }}
          contentContainerStyle={{
            paddingBottom: 20,
            flexGrow: 1,
          }}
          bottomOverflowColor={Color.grayBackground}
        >
          <TabHeader heading={$t({ id: 'Home_heading', defaultMessage: 'Home' })} />
          <View
            style={{
              borderTopWidth: 1,
              borderTopColor: Color.styleGuide.Gray6,
              backgroundColor: Color.grayBackground,
              padding: 20,
              flexGrow: 1,
            }}
          >
            <View
              style={{
                marginBottom: 12,
              }}
            >
              <NewPatientSupporterNotification
                style={{
                  marginBottom: 12,
                }}
              />
              <SuicideLifelineCard />
            </View>
            <View spacing={12}>
              {allComplete ? null : (
                <Heading
                  text={$t({ defaultMessage: 'Next session', id: 'Home_nextSessionHeading' })}
                  testID="Home_nextSessionHeading"
                  level={2}
                />
              )}
              {item ? (
                <View spacing={10}>
                  <SessionCard
                    collapsed
                    testID={`SessionCard_${item.slug}`}
                    state={item.state}
                    session={item.session}
                    onPressBottom={() => {}}
                    onPressTop={() => {
                      if (isCollaborativeOnboarding && item.slug === ContentType.MYPLAN) {
                        props.navigation.navigate('ControlledMyStoryMyPlan');
                      } else {
                        props.navigation.navigate('Conversation', {
                          num: item.session.num.toString(),
                          title: item.session.title,
                          ID: item.slug || 'TEST::convo',
                          completed:
                            item.state === 'session-complete'
                              ? ('true' as const)
                              : ('false' as const),
                        });
                      }
                    }}
                  />
                  <View
                    row
                    spacing={10}
                    style={{ marginLeft: 6 }}
                    accessible
                    accessibilityLabel={$t(
                      {
                        id: 'Home_sessionsDoneAccessibilityLabel',
                        defaultMessage:
                          'Session progress: {completedCount} of {totalCount} complete',
                      },
                      {
                        completedCount: item.session.num - 1,
                        totalCount: data?.treatmentV2?.length ?? 12,
                      },
                    )}
                  >
                    <Text
                      text={$t({ id: 'Home_sessionsDone', defaultMessage: 'Sessions done' })}
                      weight="semibold"
                    />
                    <Text
                      text={$t(
                        {
                          id: 'Home_sessionProgress',
                          defaultMessage: `{completedCount}/{totalCount}`,
                        },
                        {
                          completedCount: item.session.num - 1,
                          totalCount: data?.treatmentV2?.length ?? 12,
                        },
                      )}
                      testID="Home_sessionProgress"
                    />
                    <SegmentedProgressBar
                      length={data?.treatmentV2?.length ?? 12}
                      progress={item.session.num - 1}
                    />
                  </View>
                </View>
              ) : allComplete ? null : (
                <SessionCardBlank />
              )}
              <View>
                <Heading
                  text={$t({ id: 'Home_dailyPracticeHeading', defaultMessage: 'Daily practice' })}
                  testID="Home_dailyPracticeHeading"
                  level={2}
                  style={{ marginTop: allComplete ? 12 : 68, marginBottom: 12 }}
                />
                <View style={[card, { paddingHorizontal: 15, paddingVertical: 20 }]}>
                  <Text
                    text={$t({ id: 'Home_todoListHeading', defaultMessage: 'Up next' })}
                    testID="Home_todoListHeading"
                    color={Color.styleGuide.Gray3}
                    weight="semibold"
                    accessibilityRole="header"
                    style={{ marginBottom: 20 }}
                  />
                  {!progress.MYPLAN?.completed ? (
                    <View style={{ marginHorizontal: -15 }}>
                      <FinishSession1Ribbon />
                    </View>
                  ) : data?.actionTodos.todo.length === 0 ? (
                    <AllDoneRibbon />
                  ) : (
                    <View testID="Home_dailyPracticeList">
                      {data?.actionTodos.todo.map((todo, i, arr) => {
                        return (
                          <View
                            key={todo.pendingActionID}
                            testID={`Home_dailyPracticeList_item_${i}`}
                          >
                            <ActionTodoItem item={todo} />
                            {i === arr.length - 1 ? null : <Divider />}
                          </View>
                        );
                      })}
                    </View>
                  )}
                  {data?.actionTodos.completed.length ? (
                    <Text
                      text="Done"
                      color={Color.styleGuide.Gray3}
                      weight="semibold"
                      accessibilityRole="header"
                      style={{ marginTop: 40, marginBottom: 20 }}
                    />
                  ) : null}
                  {data?.actionTodos.completed.map((todo, i, arr) => {
                    return (
                      <View key={todo.actionID}>
                        <ActionTodoItem item={todo} />
                        {i === arr.length - 1 ? null : <Divider />}
                      </View>
                    );
                  })}
                </View>
                <View style={{ marginTop: 30 }}>
                  <Text
                    text={$t({
                      id: 'Home_weeklyProgressHeading',
                      defaultMessage: 'Weekly progress',
                    })}
                    testID="Home_weeklyProgressHeading"
                    color={Color.styleGuide.Gray3}
                    weight="semibold"
                    accessibilityRole="header"
                    style={{ marginBottom: 20 }}
                  />
                  <WeeklyProgress progress={data?.actionProgresses} />
                </View>
                <QuickActions />
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </RequiredClinicianUpgrade>
  );
}
